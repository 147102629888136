import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-billing-period-status-label',
  templateUrl: './billing-period-status-label.component.html'
})
export class BillingPeriodStatusLabelComponent implements OnInit {

  @Input() status: any;

  constructor() { }

  ngOnInit() {
  }

}
