export class PaginationData {

  public count: number;
  public pageSize: number;
  public pageNumber: number;
  public totalAmount: number;

  constructor() {
    this.pageSize = 30;
    this.pageNumber = 1;
  }

}
