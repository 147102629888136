<app-po-detail [vm]="vm" [useSimplePOTemplate]="false">

  <div po-detail-content>
    <div class="row">

      <div class="col-8">

        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white" *ngIf="vm.isNew()">{{ 'base.create.po.user' | translate }}</h4>
            <h4 class="m-b-0 text-white" *ngIf="!vm.isNew()">{{ 'base.general.info' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="margin-auto max-w-md">
              <div class="row" *ngIf="vm.form">

                <div class="col-6">
                  <div class="form-group">
                    <label> {{ 'base.status' | translate }}:&nbsp;</label>
                    <app-active-status-label [po]="vm.record"></app-active-status-label>
                  </div>
                </div>

                <div class="col-6 text-right" *ngIf="!vm.isNew()">
                  <button class="btn btn-dark m-b"
                          (click)="vm.enable()"
                          [disabled]="vm.isCanSave() || isPoRole"
                          *ngIf="vm.isCanEnable()">
                    <span class="fas fa-check"></span>
                    {{ 'base.enable' | translate }}
                  </button>

                  <button class="btn btn-danger m-b"
                          (click)="vm.deactivate()"
                          [disabled]="vm.isCanSave() || isPoRole"
                          *ngIf="vm.isCanDeactivate()">
                    <span class="fas fa-ban"></span>
                    {{ 'base.deactivate' | translate }}
                  </button>

                  <button class="btn btn-dark m-b"
                          (click)="vm.activate()"
                          [disabled]="vm.isCanSave() || isPoRole"
                          *ngIf="vm.isCanActivate()">
                    <span class="fas fa-check"></span>
                    {{ 'base.activate' | translate }}
                  </button>
                </div>

                <div class="col-12">
                  <app-form-group-text [form]="vm.form"
                                       [fieldKey]="'fullname'"
                                       [label]="'base.fullname'"
                                       [showError]="vm.showFieldError"
                                       (valueChange)="vm.onFieldChanged($event)"
                                       [fieldReadonly]="vm.isInactive() || isPoRole"></app-form-group-text>
                </div>

                <div class="col-12" *ngIf="!vm.isNew()">
                  <app-form-group-text [form]="vm.form"
                                       [fieldKey]="'role'"
                                       [label]="'base.role.label'"
                                       [showError]="vm.showFieldError"
                                       (valueChange)="vm.onFieldChanged($event)"
                                       [fieldReadonly]="vm.isDraft() || !vm.isNew() || isPoRole"></app-form-group-text>
                </div>

                <div class="col-12">
                  <app-form-group-text [form]="vm.form"
                                       [fieldKey]="'username'"
                                       [label]="'base.username'"
                                       [showError]="vm.showFieldError"
                                       (valueChange)="vm.onFieldChanged($event)"
                                       [fieldReadonly]="!vm.isDraft() || isPoRole"
                                       [errorMessage]="'base.username.error.message'"></app-form-group-text>
                </div>

                <div class="col-12">
                  <div class="row small-gutters">
                    <div class="col-auto" *ngIf="vm.isCanDelete()">
                      <button class="btn btn-danger m-b" (click)="vm.delete()" [disabled]="isPoRole">
                        <span>{{ 'base.delete' | translate }}</span>
                      </button>
                    </div>

                    <div class="col-auto" *ngIf="!vm.isDraft()">
                      <button class="btn btn-default m-b" (click)="vm.resetPassword()" [disabled]="isPoRole">
                        <span>{{ 'feature.change-password.title' | translate }}</span>
                      </button>
                    </div>

                    <div class="col">
                      <div class="row small-gutters">
                        <div class="col"><!--EMPTY--></div>

                        <div class="col-auto" *ngIf="vm.isCanBack()">
                          <button class="btn btn-dark m-b" (click)="vm.back()">
                            <span *ngIf="!vm.isCanSave()">{{ 'base.btn.back' | translate }}</span>
                            <span *ngIf="vm.isCanSave()">{{ 'base.btn.cancel' | translate }}</span>
                          </button>
                        </div>

                        <div class="col-auto">
                          <button class="btn btn-success m-b" (click)="vm.save()" [disabled]="!vm.isCanSave()">
                            <span>{{ 'base.btn.save' | translate }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-12 col-md-6">

        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.sub-admin.detail.feature-list' | translate }}</h4>
          </div>
          <div class="card-body no-padder">

            <div class="table-responsive table-basic no-margin-bottom border-bottom">
              <table class="table">
                <tbody>
                <tr *ngFor="let feature of vm.features"> -->
                  <!-- <td>
                    <div [ngClass]="{'font-bold': feature.level == 0}"
                         [ngStyle]="{'padding-left': feature.level * 15 + 'px'}">
                      {{ feature.name | translate }}
                    </div>
                  </td>
                  <td class="text-center w-3x">
                    <app-checkbox [(ngModel)]="feature.selected"
                                  (ngModelChange)="vm.onFieldChanged($event)"
                                  *ngIf="feature.selected != null"
                                  [disabled]="vm.isInactive()"></app-checkbox>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div> -->
    </div>
  </div>

</app-po-detail>
