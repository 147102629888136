<div class="form-group" [ngClass]="{ 'has-danger': isAddDangerClass() }" *ngIf="getField() != null">

  <label class="form-control-label" *ngIf="!checkEmptyText(label)">
    {{ label | translate }}<span *ngIf="getValidator().required">*</span>
  </label>

  <input [type]="password ? 'password' : 'text'"
         #inputElement
         class="form-control"
         [(ngModel)]="getField().value"
         (ngModelChange)="onTextChanged($event)"
         [disabled]="fieldDisabled"
         [readonly]="fieldReadonly"
         (keydown.enter)="onEnter($event)"
         (blur)="onBlurMethod()"
         [placeholder]="placeholder | translate"
         [ngClass]="{uppercase: uppercase}"/>

  <div class="form-control-feedback" *ngIf="isShowTextError()">

    <span *ngIf="useDefaultErrorMessage() && isExactMaxLength()">
      <span
        *ngIf="getValidator().required">{{ 'form.error.message.text-required-exact' | translate:getValidator().params }}</span>
      <span
        *ngIf="!getValidator().required">{{ 'form.error.message.text-exact' | translate:getValidator().params }}</span>
    </span>

    <span *ngIf="useDefaultErrorMessage() && !isExactMaxLength()">
      <span
        *ngIf="getValidator().required">{{ 'form.error.message.text-required' | translate:getValidator().params }}</span>
      <span *ngIf="!getValidator().required">{{ 'form.error.message.text' | translate:getValidator().params }}</span>
    </span>

    <span *ngIf="!useDefaultErrorMessage()">
      {{ errorMessage | translate:getValidator().params }}
    </span>

  </div>

</div>
