import {Component, Injector} from '@angular/core';
import {POListAbstractComponent} from '../../../../../core/components/abstract/po-list.abstract.component';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {FaskForm, FaskFormBuilder, FaskValidatorFactory} from '../../../../../core/form/fask.form';

@Component({
  selector: 'app-payment-transaction-type-list',
  templateUrl: './payment-transaction-type-list.component.html'
})
export class PaymentTransactionTypeListComponent extends POListAbstractComponent {

  vm = this;
  form: FaskForm;

  constructor(injector: Injector) {
    super(injector,
      'feature.payment-transaction-type.title',
      '/api/admin/payment-transaction-type/search',
      'payment-transaction-type-detail'
    );
  }

  loadMerchants = (text: string): Observable<any>  => {
    const url = this.getApiUrl('/api/admin/merchant/search');
    return this.httpClient
      .post(url, {
        search: text
      })
      .pipe(
        map((result: any) => {
          _.forEach(result.list, (record) => {
            record.displayValue = record.name + ' (' + record.code + ')';
          });
          return result.list;
        })
      );
  }

  onSearchMerchant(event: { fieldKey: string; value: any }) {
    const merchant = this.form.fields['merchant'].value;
    if (merchant) {
      this.params['merchant'] = {
        id: merchant.id,
        name: merchant.name,
        code: merchant.code
      };
    } else {
      this.params['merchant'] = null;
    }
    this.reloadState();
  }

  protected componentInit() {
    super.componentInit();
    this.createForm();
  }

  protected createForm() {
    this.form = FaskFormBuilder.buildForm({
      merchant: { value: this.params['merchant'] ? this.params['merchant'] : null, validator: FaskValidatorFactory.noRequired() },
    });
  }

  protected addMoreBodyParams(body: any): any {
    body.merchantId = this.params['merchant'] ? this.params['merchant'].id : '';
    return body;
  }

}
