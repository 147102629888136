<app-po-detail [useSimplePOTemplate]="false" [vm]="vm">
  <div po-detail-content>
    <div *ngIf="vm.record.id" class="row">

      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.auto-debit.registration-details' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="">
              <div class="row">

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.auto-debit.merchant-code' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.merchantCode }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.auto-debit.code' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.id }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.auto-debit.cif' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.cif }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.allow-card' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ (record.allowCard ? 'base.yes' : 'base.no') | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.auto-debit.frequency-unit' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>{{ record.frequencyUnit | timeUnit }}</div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'Thời gian thanh toán tự động' }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.dayOfAutoDebit }}
                    </div>
                  </div>
                </div>

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.auto-debit.nb-payments' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.nbPayments }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.auto-debit.start-time' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.startTime | isoDateTime }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'base.status' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ ('base.auto-debit-status.' + record.status) | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.auto-debit.amount' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.amount | myInteger }}
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.auto-debit.description' | translate }}</span>
                    </label>
                    <textarea class="form-control" readonly>{{ record.description }}</textarea>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'base.created-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.createdTime | isoDateTime }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.auto-debit.verified-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.verifiedTime | isoDateTime }}
                    </div>
                  </div>
                </div>

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.auto-debit.reported-time' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.reportedTime | isoDateTime }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.auto-debit.confirmed-time' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.confirmedTime | isoDateTime }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.auto-debit.cancel-time' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.cancelTime | isoDateTime }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.auto-debit.mb-confirm-channel' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.mbConfirmChannel }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.auto-debit.funding-source' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.sourcePayment }}
                    </div>
                  </div>
                </div>

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.auto-debit.funding-account' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.mbFundingAccount }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.auto-debit.card-type' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.mbCardType }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.auto-debit.card-no1' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.mbCardNo }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.auto-debit.register' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="table-responsive table-basic no-margin-bottom border-bottom">
                <table class="table" table-content>
                  <thead>
                  <tr>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.auto-debit-id" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.created-time" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.merchant-code" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.cif" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.auto-debit-type" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.request-id" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.amount" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.trans-type" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.contract-code" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.auto-debit.auto-debit-rg-his.status" | translate }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let autoDebitRequestHistory of record.autoDebitRequestHistories;"
                  >
                    <td class="text-left">{{ autoDebitRequestHistory.autoDebitId }}</td>
                    <td>{{ autoDebitRequestHistory.createdTime | isoDateTime }}</td>
                    <td class="text-left">{{ autoDebitRequestHistory.merchantCode }}</td>
                    <td class="text-left">{{ autoDebitRequestHistory.cif }}</td>
                    <td class="text-left">{{ autoDebitRequestHistory.autoDebitType }}</td>
                    <td class="text-left">{{ autoDebitRequestHistory.id }}</td>
                    <td class="text-left">{{ autoDebitRequestHistory.amount | myInteger }}</td>
                    <td class="text-left">{{ autoDebitRequestHistory.requestType }}</td>
                    <td class="text-left">{{ autoDebitRequestHistory.contractCode }}</td>
                    <td class="text-left">{{ autoDebitRequestHistory.status }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.auto-debit.billing-periods' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="table-responsive table-basic no-margin-bottom border-bottom">
                <table class="table" table-content>
                  <thead>
                    <tr>
                      <th class="no-wrap">
                        {{ "feature.auto-debit.billing-period.billing-seq" | translate }}
                      </th>
                      <th class="no-wrap">
                        {{ "feature.auto-debit.billing-period.billing-time" | translate }}
                      </th>
                      <th class="no-wrap text-right">
                        {{ "feature.auto-debit.billing-period.amount" | translate }}
                      </th>
                      <th class="no-wrap">
                        {{ "base.status" | translate }}
                      </th>
                      <th class="no-wrap">
                        {{ "feature.auto-debit.billing-period.cancel-reason" | translate }}
                      </th>
                      <th class="no-wrap">
                        {{ "feature.auto-debit.billing-period.last-payment-transaction-id" | translate }}
                      </th>
                      <th class="no-wrap">
                        {{ "feature.auto-debit.billing-period.paid-time" | translate }}
                      </th>
                      <th class="no-wrap">
                        {{ "base.updated-time" | translate }}
                      </th>
                      <th class="no-wrap">
                        {{ "base.created-time" | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let billingPeriod of record.billingPeriods;"
                    >
                      <td class="text-center">{{ billingPeriod.billingSeq }}</td>
                      <td>{{ billingPeriod.billingTime | isoDateTime }}</td>
                      <td class="text-right">{{ billingPeriod.amount | myInteger }}</td>
                      <td><app-billing-period-status-label [status]="billingPeriod.status"></app-billing-period-status-label></td>
                      <td>{{ billingPeriod.cancelReason }}</td>
                      <td>{{ billingPeriod.lastPaymentTransactionId }}</td>
                      <td>{{ billingPeriod.paidTime | isoDateTime }}</td>
                      <td>{{ billingPeriod.updatedTime | isoDateTime }}</td>
                      <td>{{ billingPeriod.createdTime | isoDateTime }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-po-detail>
