import * as _ from 'lodash';
import * as moment from 'moment';

export default class Utils {

  // BASE 64
  public static isEmptyString(object: any) {
    if (object == null) {
      return true;
    }

    if (typeof object === 'string' || object instanceof String) {
      return object.toString().trim().length === 0;
    }

    return false;
  }

  public static objectToB64(object) {
    if (object != null) {
      let b64 = JSON.stringify(object);
      b64 = window.btoa(unescape(encodeURIComponent(b64)));
      b64 = b64.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
      return b64;
    }

    return null;
  }

  public static b64ToObject(b64) {
    if (b64 != null && b64.length > 0) {
      if ((b64.length % 4) === 3) {
        b64 = b64 + '=';
      } else if ((b64.length % 4) === 2) {
        b64 = b64 + '==';
      } else if ((b64.length % 4) === 1) {
        b64 = b64 + '===';
      }

      b64 = b64.replace(/-/g, '+').replace(/_/g, '/');

      b64 = decodeURIComponent(escape(window.atob(b64)));

      return JSON.parse(b64);
    }

    return {};
  }

  // PO STATUS
  public static isPODraft(po: any): boolean {
    return po == null || po['activeStatus'] == null || (po['activeStatus'] !== 'ACTIVE' && po['activeStatus'] !== 'INACTIVE');
  }

  public static isPOActive(po: any): boolean {
    return po != null && po['activeStatus'] != null && po['activeStatus'] === 'ACTIVE';
  }

  public static isPOInactive(po: any): boolean {
    return po != null && po['activeStatus'] != null && po['activeStatus'] === 'INACTIVE';
  }

  // FILE
  public static readFile(inputFileChangeEvent: any, onRead: (file: any, fileName: string, fileSize: number, fileType: string) => void) {

    if (!_.isEmpty(inputFileChangeEvent.target.files)) {
      for (const file of inputFileChangeEvent.target.files) {
        const reader = new FileReader();
        reader.onload = (loadEvent) => {
          onRead(loadEvent.target['result'], file.name, file.size, file.type);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  public static dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString = null;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    let i = 0;
    while (i < byteString.length) {
      ia[i] = byteString.charCodeAt(i);
      i++;
    }

    return new Blob([ia], {type: mimeString});
  }

  public static dataURItoFile(dataURI, filename) {
    const arr = dataURI.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  // DATE
  public static parseEpochSecond(epochSecond: number) {
    if (epochSecond != null) {
      return new Date(epochSecond * 1000);
    }

    return null;
  }

  public static getEpochSecond(date) {
    if (date != null) {
      return moment(date).unix();
    }

    return null;
  }

  public static parseIsoDate(isoDate): Date {
    if (isoDate != null) {
      if (isoDate.length === 19) {
        return moment(isoDate, 'YYYY-MM-DDTHH:mm:ss').toDate();
      } else if (isoDate.length === 10) {
        return moment(isoDate, 'YYYY-MM-DD').toDate();
      }
    }

    return null;
  }

  public static parseIsoTime(isoTime): Date {
    if (isoTime != null) {
      if (isoTime.length === 10) {
        return moment(isoTime, 'YYYY-MM-DD').toDate();
      } else if (isoTime.length === 19) {
        return moment(isoTime, 'YYYY-MM-DDTHH:mm:ss').toDate();
      }
    }

    return null;
  }

  public static createIsoDate(date) {
    if (date != null) {
      return moment(date).format('YYYY-MM-DD');
    }

    return null;
  }

  public static createIsoTime(date, time?) {
    if (date != null) {
      if (time != null) {
        return moment(date).format('YYYY-MM-DD') + 'T' + moment(time).format('HH:mm:ss');
      } else {
        return moment(date).format('YYYY-MM-DDTHH:mm:ss');
      }
    }

    return null;
  }

  public static fromNow(epochTime: number) {
    return moment(epochTime).fromNow();
  }

  public static compareDate(date1, date2, ignoreTime?: boolean): number {
    ignoreTime = ignoreTime == null ? true : false;

    if (date1 == null || date2 == null) {
      return null;
    }

    if (ignoreTime) {
      date1 = moment(date1).startOf('day');
      date2 = moment(date2).startOf('day');
    } else {
      date1 = moment(date1);
      date2 = moment(date2);
    }

    if (date1.isAfter(date2)) {
      return 1;
    }

    if (date1.isBefore(date2)) {
      return -1;
    }

    return 0;
  }

  public static isValidIsoDatetime(isoDatetime): boolean {
    if (isoDatetime) {
      return moment(isoDatetime, moment.ISO_8601).isValid();
    }
    return false;
  }

  // ARRAY
  public static arrayMove(array: any, moveIndex: number, toIndex: number) {
    if (_.isEmpty(array)) {
      return;
    }

    const length = array.length;

    if (moveIndex < 0 || moveIndex > length - 1) {
      return;
    }

    if (toIndex < 0 || toIndex > length - 1) {
      return;
    }

    const item = array[moveIndex];
    const diff = moveIndex - toIndex;

    if (diff > 0) {
      // move left
      return [
        ...array.slice(0, toIndex),
        item,
        ...array.slice(toIndex, moveIndex),
        ...array.slice(moveIndex + 1, length)
      ];
    } else if (diff < 0) {
      // move right
      const targetIndex = toIndex + 1;
      return [
        ...array.slice(0, moveIndex),
        ...array.slice(moveIndex + 1, targetIndex),
        item,
        ...array.slice(targetIndex, length)
      ];
    }

    return array;
  }

  public static getArrayFromResponse(response): any[] {
    if (response != null) {
      if (Array.isArray(response)) {
        return response;
      } else if (Array.isArray(Utils.getFieldSafe(response, 'result'))) {
        return response.result;
      } else if (Utils.getFieldSafe(response, 'updated', 'result') != null) {
        if (Array.isArray(response.updated.result)) {
          return response.updated.result;
        } else if (Array.isArray(Utils.getFieldSafe(response, 'updated', 'result', 'content'))) {
          return response.updated.result.content;
        }
      }
    }

    return [];
  }

  // COLOR
  public static hexToRGB(hex) {
    if (hex == null) {
      return null;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  public static isDarkColor(hex) {
    const rgb = Utils.hexToRGB(hex);

    if (rgb == null) {
      return false;
    }

    const brightness = Math.round(((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000);
    return brightness <= 125;
  }

  // YOUTUBE
  public static getYoutubeId(url: string) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return null;
    }
  }

  // STRING
  public static isStringEmpty(str: string): boolean {
    return str == null || str.trim().length === 0;
  }

  public static stringToASCII(str: string): string {
    if (this.isStringEmpty(str)) {
      return '';
    }

    try {
      return str.replace(/[àáảãạâầấẩẫậăằắẳẵặ]/g, 'a')
        .replace(/[èéẻẽẹêềếểễệ]/g, 'e')
        .replace(/[đ]/g, 'd')
        .replace(/[ìíỉĩị]/g, 'i')
        .replace(/[òóỏõọôồốổỗộơờớởỡợ]/g, 'o')
        .replace(/[ùúủũụưừứửữự]/g, 'u')
        .replace(/[ỳýỷỹỵ]/g, 'y');
    } catch {
      return '';
    }
  }

  public static searchASCII(source: string, search: string): boolean {
    if (this.isStringEmpty(source) && this.isEmptyString(search)) {
      return true;
    }

    if (this.isStringEmpty(source) || this.isEmptyString(search)) {
      return false;
    }

    const sourceToASCII = this.stringToASCII(source.toLowerCase());
    const searchToASCII = this.stringToASCII(search.toLowerCase());
    return sourceToASCII.includes(searchToASCII);
  }

  // REGEX
  public static validateLink(link): boolean {
    const regex = /^https?:\/\/[a-z0-9]+([\-.][a-z0-9]+)*(:[0-9]{1,5})?(\/.*)?$/;
    return regex.test(String(link).toLowerCase());
  }

  public static validateEmail(email): boolean {
    // tslint:disable-next-line:max-line-length
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  public static validatePhone(phone): boolean {
    const regex = /^(0)[0-9]{9}$/;
    return regex.test(String(phone).toLowerCase());
  }

  public static validateUsername(username): boolean {
    const regex = /^[0-9a-z]{3,30}$/;
    return regex.test(String(username).toLowerCase());
  }

  public static validateCode(code): boolean {
    const regex = /^[0-9a-z_]{3,100}$/;
    return regex.test(String(code).toLowerCase());
  }

  public static validateOtp(otp): boolean {
    const regex = /^[0-9]{6}$/;
    return regex.test(String(otp).toLowerCase());
  }

  public static validateCidr(cidr): boolean {
    const regex = /^((25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])\.){3}((25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9]))(\/(0|8|16|24|32))$/;
    return regex.test(String(cidr).toLowerCase());
  }

  public static validateCidrList(cidrList): boolean {
    const regex = /^(((25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])\.){3}((25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9]))(\/(0|8|16|24|32)))(,(((25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])\.){3}((25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9]))(\/(0|8|16|24|32))))*$/;
    return regex.test(String(cidrList).toLowerCase());
  }

  public static validateStringList(stringList) : boolean {
    const regex =/^[A-Za-z0-9]{1,15}(,[A-Za-z0-9]{1,15})*$/;
    return regex.test(String(stringList).toLowerCase());
  }
  public static removeAccents(str: string) {
    if (str == null) {
      return null;
    }

    if (this.isEmptyString(str)) {
      return '';
    }

    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  // OBJECT
  public static getFieldSafe(data, field1: string, field2?: string, field3?: string, field4?: string, field5?: string) {
    if (data != null) {
      if (field1 == null) {
        return data;
      }

      if (data[field1] == null) {
        return null;
      }

      if (field2 == null) {
        return data[field1];
      }

      return this.getFieldSafe(data[field1], field2, field3, field4, field5);
    }
    return null;
  }

  // STRONG PASSWORD
  public static generateStrongPassword() {
    const specials = '!@#$%^&*()';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    const all = specials + lowercase + uppercase + numbers;

    let part1 = this.pickRandomCharToString(lowercase, 2);
    part1 += this.pickRandomCharToString(uppercase, 2);
    part1 += this.pickRandomCharToString(numbers, 2);
    part1 = this.shuffleString(part1);

    let part2 = this.pickRandomCharToString(lowercase, 2);
    part2 += this.pickRandomCharToString(uppercase, 2);
    part2 += this.pickRandomCharToString(numbers, 2);
    part2 = this.shuffleString(part2);

    const special = this.pickRandomCharToString(specials, 1);

    return this.shuffleString(part1 + special + part2);
  }

  private static pickRandomCharToString(text: string, min: number, max?: number): string {
    let n, chars = '';

    if (typeof max === 'undefined') {
      n = min;
    } else {
      n = min + Math.floor(Math.random() * (max - min + 1));
    }

    for (let i = 0; i < n; i++) {
      chars += text.charAt(Math.floor(Math.random() * text.length));
    }

    return chars;
  }

  private static shuffleString(text: string): string {
    const array = text.split('');
    let tmp, current, top = array.length;

    if (top) {
      while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
      }
    }

    return array.join('');
  }

}
