<span class="label label-warning" *ngIf="status === 'NEW'">
  {{ 'base.billing-period-status.NEW' | translate }}
</span>
<span class="label label-info" *ngIf="status === 'PROCESSING'">
  {{ 'base.billing-period-status.PROCESSING' | translate }}
</span>
<span class="label label-info" *ngIf="status === 'RETRY'">
  {{ 'base.billing-period-status.RETRY' | translate }}
</span>
<span class="label label-success" *ngIf="status === 'PAID'">
  {{ 'base.billing-period-status.PAID' | translate }}
</span>
<span class="label label-danger" *ngIf="status === 'CANCELLED'">
  {{ 'base.billing-period-status.CANCELLED' | translate }}
</span>
<span class="label label-primary" *ngIf="status === 'CHARGING'">
  {{ 'base.billing-period-status.CHARGING' | translate }}
</span>
<span class="label label-danger" *ngIf="status === 'UNKNOWN'">
  {{ 'base.billing-period-status.UNKNOWN' | translate }}
</span>
