import {Component, Injector} from '@angular/core';
import {POListAbstractComponent} from '../../../../../core/components/abstract/po-list.abstract.component';

@Component({
  selector: 'app-sandbox-apk-list',
  templateUrl: './sandbox-apk-list.component.html'
})
export class SandboxApkListComponent extends POListAbstractComponent {

  vm = this;
  activeStatusItemList = [];

  constructor(injector: Injector) {
    super(injector, 'feature.sandbox-apk.title', '/api/admin/sandbox-apk/search', 'sandbox-apk-detail');
  }

  protected componentInit() {
    this.loadActiveStatusItemList();

    if (this.params['activeStatus'] == null) {
      this.params['activeStatus'] = this.optionAllId;
    }

    super.componentInit();
  }

  protected addMoreBodyParams(body: any): any {
    if (!this.checkEmptyText(this.params['searchText'])) {
      body.search = this.params['searchText'];
    }

    if (this.params['activeStatus'] != null && this.params['activeStatus'] !== this.optionAllId) {
      body.activeStatus = this.params['activeStatus'];
    }

    return super.addMoreBodyParams(body);
  }

  // PRIVATE
  private loadActiveStatusItemList() {
    this.activeStatusItemList = [
      this.getOptionAll(),
      {id: 'DRAFT', name: 'base.active-status.DRAFT'},
      {id: 'ACTIVE', name: 'base.active-status.ACTIVE'},
      {id: 'INACTIVE', name: 'base.active-status.INACTIVE'}
    ];
  }

}
