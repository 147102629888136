import {Component, EventEmitter, Injector, OnInit, Output, TemplateRef, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import {AbstractSimpleComponent} from '../../abstract/abstract.simple.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent extends AbstractSimpleComponent implements OnInit {

  @Output() openSidebarEventEmitter = new EventEmitter<any>();
  userinfoModalRef: BsModalRef;
  userLanguageModalRef: BsModalRef;

  constructor(protected injector: Injector,
              private router: Router) {
    super(injector);
  }

  ngOnInit() {
    moment.locale('vi');
  }

  getUserInfo() {
    return this.utilityService.getUserInfo() == null ? {} : this.utilityService.getUserInfo();
  }

  getCurrentLanguage() {
    return this.utilityService.getCurrentLanguage();
  }

  openSidebar() {
    this.openSidebarEventEmitter.emit(null);
  }

  openUserInfoModal(template: TemplateRef<any>) {
    this.userinfoModalRef = this.modalService.show(template, {class: 'modal-sm modal-margin-top-20vh userinfo-modal-dialog'});
  }

  changePassword() {
    this.userinfoModalRef.hide();
    this.router.navigate(['/change-password']);
  }

  logout() {
    this.userinfoModalRef.hide();

    this.httpPostOrPut(
      this.getApiUrl('/authentication/logout'),
      {},
      true,
      'logout',
      {},
      () => {
        this.utilityService.clearSessionData();
        this.utilityService.clearTokenInfo();
        this.router.navigate(['/login']);
      },
      () => {
        this.utilityService.clearSessionData();
        this.utilityService.clearTokenInfo();
        this.router.navigate(['/login']);
      },
      false
    );
  }

  openUserLanguageModal(template: TemplateRef<any>) {
    this.userLanguageModalRef = this.modalService.show(template, {class: 'modal-sm modal-margin-top-20vh userinfo-modal-dialog'});
  }

  changeLanguage(lang: string) {
    this.userLanguageModalRef.hide();
    this.utilityService.changeLanguage(lang);
  }

}
