import {Component, Injector} from '@angular/core';
import {PODetailAbstractComponent} from '../../../../../core/components/abstract/po-detail.abstract.component';
import {
  FaskField,
  FaskForm,
  FaskFormBuilder,
  FaskValidator,
  FaskValidatorFactory
} from '../../../../../core/form/fask.form';
import * as _ from 'lodash';
import {PaymentTransactionTypeModalComponent} from '../payment-transaction-type-modal/payment-transaction-type-modal.component';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-merchant-detail',
  templateUrl: './merchant-detail.component.html',
  styleUrls: ['./merchant-detail.component.scss'],
})
export class MerchantDetailComponent extends PODetailAbstractComponent {

  vm = this;
  paymentTransactionTypeForms: FaskForm[];
  shareLoginFields: { name: string, select: boolean, readonly: boolean }[] = [];
  instantPaymentForm: FaskForm;
  autoDebitForm: FaskForm;
  isPoRole: boolean;
  deleteSelectedAll: boolean;
  deleteSelectedPT: number;
  ids: string[] = [];

  constructor(injector: Injector, private clipboard: Clipboard) {
    super(injector, 'feature.merchant.title', '/api/admin/merchant', 'merchant-list');
    this.isPoRole = this.utilityService.getTokenInfo().user_role.poRole;
  }

  protected componentInit() {
    super.componentInit();
    this.deleteSelectedAll = false;
    this.deleteSelectedPT = 0;
  }

  copyMerchantSecret() {
    this.clipboard.copy(this.record.secret);
    this.utilityService.showInfoToast('feature.merchant.merchant-secret-copied');
  }

  deactivate() {
    const isUseAutoDebit = this.record.useAutoDebit === true;
    if (isUseAutoDebit) {
      this.utilityService.showConfirmModal('feature.merchant.cancel-all-auto-debit-confirm', () => {
        super.deactivate();
      });
      return;
    }
    super.deactivate();
  }

  deletePaymentTransactionType() {
    this.utilityService.showConfirmModal('base.deletePTTConfirm', () => {
      this.callDeletePTT();
    });
  }

  callDeletePTT() {
    const url = this.getApiUrl('/api/admin/payment-transaction-type/delete');
    _.forEach(this.paymentTransactionTypeForms, (form) => {
      if (form.fields['toDeleted'].value === true) {
        this.ids.push(form.fields['id'].value);
      }
    });
    console.log(this.ids);
    const body = {
      ids: this.ids
    };
    this.httpPostOrPut(
      url,
      null,
      true,
      'save', body, (data) => {
        this.reloadState();
      });
  }

  save(enable?: boolean) {
    const isUseAutoDebit = this.record.useAutoDebit === true;
    if (isUseAutoDebit && this.form.fields['useAutoDebit'].value !== true) {
      this.utilityService.showConfirmModal('feature.merchant.cancel-all-auto-debit-confirm', () => {
        super.save(enable);
      });
      return;
    }
    super.save(enable);
  }

  regenerateMerchantSecret() {
    if (!this.isNew()) {
      this.utilityService.showConfirmModal('feature.merchant.regenerate-secret-confirm', () => {
        this.httpPostOrPut(
          this.getApiUrl('/api/admin/merchant/' + this.record.id + '/regenerate-merchant-secret'),
          {},
          true,
          'regenerate-merchant-secret',
          {},
          () => {
            this.reloadData();
          }
        );
      });
    }
  }

  addPaymentTransactionType() {
    const initialState = {
      title: 'base.add-new',
      acceptBtnName: 'base.add-new',
      cancelBtnName: 'base.cancel',
      message: 'feature.level1-post-approval-test.message.fail-confirm',
      acceptFn: (paymentTransactionType: any) => {
        const code = paymentTransactionType['code'].toUpperCase();
        const name = paymentTransactionType['name'];

        if (!this.validate(name, code, null)) {
          return false;
        }

        this.paymentTransactionTypeForms.push(FaskFormBuilder.buildForm({
          id: {value: null, validator: FaskValidatorFactory.noValidator()},
          name: {value: name, validator: FaskValidatorFactory.text(true, 1, 256)},
          code: {value: code.toUpperCase(), validator: FaskValidatorFactory.code(true)},
          allowCard: {value: paymentTransactionType['allowCard'], validator: FaskValidatorFactory.required(false)},
          allowBNPL: {value: paymentTransactionType['allowBNPL'], validator: FaskValidatorFactory.required(false)},
          allowVoucher: {value: paymentTransactionType['allowVoucher'], validator: FaskValidatorFactory.noValidator()},
          allowLoyalty: {value: paymentTransactionType['allowLoyalty'], validator: FaskValidatorFactory.noValidator()},
          autoDebit: {value: paymentTransactionType['autoDebit'], validator: FaskValidatorFactory.required(false)},
          autoDebitType: {value: paymentTransactionType['autoDebitType'], validator: FaskValidatorFactory.noValidator()},
          retryDay: {value: paymentTransactionType['retryDay'], validator: FaskValidatorFactory.noValidator()},
          active: {value: true, validator: FaskValidatorFactory.required(false)},
          defaultType: {
            value: this.paymentTransactionTypeForms.length === 0,
            validator: FaskValidatorFactory.required(false)
          },
          toDeleted: {value: paymentTransactionType['toDeleted'], validator: FaskValidatorFactory.noValidator()},
        }));

        this.utilityService.setHasUnsavedData();

        return true;
      },
      cancelFn: null,
      paymentTransactionType: {
        useAutoDebitFlag: this.form.fields['useAutoDebit'].value,
        recordStatus: this.record.activeStatus
      }
    };
    this.modalService.show(PaymentTransactionTypeModalComponent, {
      initialState: initialState,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  onDeleteSelectedAll(selected) {
    if (selected) {
      this.deleteSelectedPT = 1;
      _.forEach(this.paymentTransactionTypeForms, (form) => {
        form.fields['toDeleted'].value = true;
      });
    } else {
      this.deleteSelectedPT = 0;
      _.forEach(this.paymentTransactionTypeForms, (form) => {
        form.fields['toDeleted'].value = false;
      });
    }
  }

  onPaymentTransactionTypeDeletedChanged(selected, transactionTypeIndex) {
    if (selected) {
      ++this.deleteSelectedPT;
    } else {
      --this.deleteSelectedPT;
    }
  }

  onPaymentTransactionTypeActiveChanged() {
    this.utilityService.setHasUnsavedData();
  }

  onPaymentTransactionTypeDefaultChanged(selected, transactionTypeIndex) {
    this.utilityService.setHasUnsavedData();
    if (selected) {
      _.forEach(this.paymentTransactionTypeForms, (form, index) => {
        form.fields['defaultType'].value = index === transactionTypeIndex;
      });
    } else {
      setTimeout(() => {
        this.paymentTransactionTypeForms[transactionTypeIndex].fields['defaultType'].value = true;
      }, 10);
    }
  }

  editPaymentTransactionType(transactionTypeIndex) {
    const fields = this.paymentTransactionTypeForms[transactionTypeIndex].fields;
    const initialState = {
      title: 'base.edit',
      acceptBtnName: 'base.save',
      cancelBtnName: 'base.cancel',
      message: 'feature.level1-post-approval-test.message.fail-confirm',
      acceptFn: (paymentTransactionType: any, paymentTransactionTypeIndex?: number) => {
        const code = paymentTransactionType['code'].toUpperCase();
        const name = paymentTransactionType['name'];

        if (!this.validate(name, code, paymentTransactionTypeIndex)) {
          return false;
        }

        const _fields = this.paymentTransactionTypeForms[paymentTransactionTypeIndex].fields;
        _fields['code'].value = code;
        _fields['name'].value = name;
        _fields['allowCard'].value = paymentTransactionType['allowCard'];
        _fields['allowBNPL'].value = paymentTransactionType['allowBNPL'];
        _fields['allowVoucher'].value = paymentTransactionType['allowVoucher'];
        _fields['allowLoyalty'].value = paymentTransactionType['allowLoyalty'];
        _fields['autoDebit'].value = paymentTransactionType['autoDebit'];
        _fields['autoDebitType'].value = paymentTransactionType['autoDebitType'];
        _fields['retryDay'].value = paymentTransactionType['retryDay'];

        this.utilityService.setHasUnsavedData();

        return true;
      },
      cancelFn: null,
      paymentTransactionTypeIndex: transactionTypeIndex,
      paymentTransactionType: {
        id: fields['id'].value,
        code: fields['code'].value,
        name: fields['name'].value,
        allowCard: fields['allowCard'].value,
        allowBNPL: fields['allowBNPL'].value,
        allowVoucher: fields['allowVoucher'].value,
        allowLoyalty: fields['allowLoyalty'].value,
        autoDebit: fields['autoDebit'].value,
        useAutoDebitFlag: this.form.fields['useAutoDebit'].value,
        recordStatus: this.record.activeStatus,
        autoDebitType: fields['autoDebitType'].value,
        retryDay: fields['retryDay'].value
      },
    };
    this.modalService.show(PaymentTransactionTypeModalComponent, {
      initialState: initialState,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  protected createForm(): void {
    if (this.record.shareLoginSession == null) {
      this.record.shareLoginSession = true;
    }
    if (this.record.useInstantPayment == null) {
      this.record.useInstantPayment = false;
    }
    if (this.record.allowMultiSourcePayment == null) {
      this.record.allowMultiSourcePayment = false;
    }
    if (this.record.useAutoDebit == null) {
      this.record.useAutoDebit = false;
    }
    if (this.record.master == null) {
      this.record.master = false;
    }
    if (this.record.useChecksumSha256 == null) {
      this.record.useChecksumSha256 = false;
    }

    let callbackHeaderKey1 = null;
    let callbackHeaderValue1 = null;
    let callbackHeaderKey2 = null;
    let callbackHeaderValue2 = null;
    if (this.record.callbackHeaderConfig != null && this.record.callbackHeaderConfig.items != null) {
      if (this.record.callbackHeaderConfig.items.length > 0) {
        callbackHeaderKey1 = this.record.callbackHeaderConfig.items[0].key;
        callbackHeaderValue1 = this.record.callbackHeaderConfig.items[0].value;
      }
      if (this.record.callbackHeaderConfig.items.length > 1) {
        callbackHeaderKey2 = this.record.callbackHeaderConfig.items[1].key;
        callbackHeaderValue2 = this.record.callbackHeaderConfig.items[1].value;
      }
    }

    let ipWhitelist = '';
    if (!this.checkEmptyList(this.record.ipWhitelist)) {
      ipWhitelist = _.join(this.record.ipWhitelist, ',');
    }

    let merchantWhitelist = '';
    if (!this.checkEmptyList(this.record.merchantWhitelist)) {
      merchantWhitelist = _.join(this.record.merchantWhitelist)
    }

    this.form = FaskFormBuilder.buildForm({
      name: {value: this.record.name, validator: FaskValidatorFactory.text(true, 1, 256)},
      code: {value: this.record.code, validator: FaskValidatorFactory.code(true)},
      channel: {value: this.record.channel, validator: FaskValidatorFactory.text(false, 1, 10)},
      master: {value: this.record.master, validator: FaskValidatorFactory.required()},
      merchantWhitelist: {value: merchantWhitelist, validator: FaskValidatorFactory.stringList(false)},
      ipWhitelist: {value: ipWhitelist, validator: FaskValidatorFactory.cidrList(false)},
      useInstantPayment: {value: this.record.useInstantPayment, validator: FaskValidatorFactory.required()},
      allowMultiSourcePayment: {value: this.record.allowMultiSourcePayment, validator: FaskValidatorFactory.noValidator()},
      callbackCheckSumSecret: {
        value: this.record.callbackCheckSumSecret,
        validator: FaskValidatorFactory.text(false, 1, 100)
      },
      callbackHeaderKey1: {value: callbackHeaderKey1, validator: FaskValidatorFactory.text(false, 1, 100)},
      callbackHeaderValue1: {value: callbackHeaderValue1, validator: FaskValidatorFactory.text(false, 1, 100)},
      callbackHeaderKey2: {value: callbackHeaderKey2, validator: FaskValidatorFactory.text(false, 1, 100)},
      callbackHeaderValue2: {value: callbackHeaderValue2, validator: FaskValidatorFactory.text(false, 1, 100)},
      shareLoginSession: {value: this.record.shareLoginSession || false, validator: FaskValidatorFactory.required()},
      useAutoDebit: {value: this.record.useAutoDebit, validator: FaskValidatorFactory.required()},
      useChecksumSha256: {value: this.record.useChecksumSha256, validator: FaskValidatorFactory.required()},
    });

    // PAYMENT TYPE
    this.paymentTransactionTypeForms = _.map(this.record.paymentTransactionTypes, (type): FaskForm => {
      return FaskFormBuilder.buildForm({
        id: {value: type.id, validator: FaskValidatorFactory.noValidator()},
        name: {value: type.name, validator: FaskValidatorFactory.text(true, 1, 256)},
        code: {value: type.code, validator: FaskValidatorFactory.code(true)},
        autoDebitType: {value: type.autoDebitType, validator: FaskValidatorFactory.noValidator()},
        retryDay: {value: type.retryDay, validator: FaskValidatorFactory.noValidator()},
        allowCard: {value: type.allowCard, validator: FaskValidatorFactory.required(false)},
        allowBNPL: {value: type.allowBNPL, validator: FaskValidatorFactory.required(false)},
        allowVoucher: {value: type.allowVoucher, validator: FaskValidatorFactory.noValidator()},
        allowLoyalty: {value: type.allowLoyalty, validator: FaskValidatorFactory.noValidator()},
        autoDebit: {value: type.autoDebit, validator: FaskValidatorFactory.required(false)},
        active: {value: type.active, validator: FaskValidatorFactory.required(false)},
        defaultType: {value: type.defaultType, validator: FaskValidatorFactory.required(false)},
        toDeleted: {value: false, validator: FaskValidatorFactory.required(false)},
      });
    });

    // SHARE LOGIN
    this.initShareLoginFields();

    // PAYMENT
    if (this.record.instantPaymentConfig == null) {
      this.record.instantPaymentConfig = {};
    }
    const instantPaymentFormConfig = {};
    this.addFormApiConfig(instantPaymentFormConfig, this.record.instantPaymentConfig.callbackAfterPay, 'callbackAfterPay');
    this.addFormApiConfig(instantPaymentFormConfig, this.record.instantPaymentConfig.callbackAfterPayV2, 'callbackAfterPayV2');
    this.addFormApiConfig(instantPaymentFormConfig, this.record.instantPaymentConfig.callbackAfterRefund, 'callbackAfterRefund');
    this.instantPaymentForm = FaskFormBuilder.buildForm(instantPaymentFormConfig);

    // AUTO DEBIT
    if (this.record.autoDebitConfig == null) {
      this.record.autoDebitConfig = {};
    }
    const autoDebitFormConfig = {};
    this.addFormApiConfig(autoDebitFormConfig, this.record.autoDebitConfig.callbackAfterRegister, 'callbackAfterRegister');
    this.addFormApiConfig(autoDebitFormConfig, this.record.autoDebitConfig.callbackAfterPayPeriod, 'callbackAfterPayPeriod');
    this.addFormApiConfig(autoDebitFormConfig, this.record.autoDebitConfig.getMerchantBill, 'getMerchantBill');
    this.autoDebitForm = FaskFormBuilder.buildForm(autoDebitFormConfig);
  }

  protected isValidForSave(): boolean {
    if (!super.isValidForSave()) {
      return false;
    }

    if (this.form.fields['useInstantPayment'].value && this.paymentTransactionTypeForms.length === 0) {
      this.utilityService.showErrorToast('feature.merchant.transaction-type-required');
      return false;
    }

    if (this.instantPaymentForm != null && !this.instantPaymentForm.isValid()) {
      this.showFieldError = true;
      return false;
    }

    if (this.autoDebitForm != null && !this.autoDebitForm.isValid()) {
      this.showFieldError = true;
      return false;
    }

    return true;
  }

  protected getBodyToSave(): any {
    const fields: { [key: string]: FaskField } = this.form.fields;

    const ipWhitelistText = fields.ipWhitelist.value;
    let ipWhitelist = null;
    if (!this.checkEmptyText(ipWhitelistText)) {
      ipWhitelist = ipWhitelistText.split(',');
    }

    const merchantWhitelistText = fields.merchantWhitelist.value;
    let merchantWhitelist = null;
    if (!this.checkEmptyText(merchantWhitelistText)) {
      merchantWhitelist = merchantWhitelistText.split(',');
    }


    const _body = {
      name: fields.name.value,
      code: fields.code.value,
      channel: fields.channel.value,
      master: fields.master.value,
      merchantWhitelist: merchantWhitelist,
      ipWhitelist: ipWhitelist,
      callbackCheckSumSecret: fields.callbackCheckSumSecret.value,
      callbackHeaderConfig: null,
      shareLoginSession: fields.shareLoginSession.value,
      useInstantPayment: fields.useInstantPayment.value,
      allowMultiSourcePayment: fields.allowMultiSourcePayment.value,
      instantPaymentConfig: null,
      useAutoDebit: fields.useAutoDebit.value,
      autoDebitConfig: null,
      useChecksumSha256: fields.useChecksumSha256.value,
    };

    _body['paymentTransactionTypes'] = _.map(this.paymentTransactionTypeForms, (transactionTypeForms) => {
      const _fields = transactionTypeForms.fields;
      return {
        id: _fields['id'].value,
        code: _fields['code'].value,
        name: _fields['name'].value,
        allowCard: _fields['allowCard'].value,
        allowBNPL: _fields['allowBNPL'].value,
        allowVoucher: _fields['allowVoucher'].value,
        allowLoyalty: _fields['allowLoyalty'].value,
        autoDebit: _fields['autoDebit'].value,
        autoDebitType: _fields['autoDebitType'].value,
        retryDay: _fields['retryDay'].value,
        active: _fields['active'].value,
        defaultType: _fields['defaultType'].value,
      };
    });

    if (!this.checkEmptyText(fields.callbackHeaderKey1.value) && !this.checkEmptyText(fields.callbackHeaderValue1.value)) {
      _body.callbackHeaderConfig = {
        items: [{
          key: fields.callbackHeaderKey1.value,
          value: fields.callbackHeaderValue1.value
        }]
      };
    }
    if (!this.checkEmptyText(fields.callbackHeaderKey2.value) && !this.checkEmptyText(fields.callbackHeaderValue2.value)) {
      if (_body.callbackHeaderConfig == null) {
        _body.callbackHeaderConfig = {
          items: []
        };
      }
      _body.callbackHeaderConfig.items.push({
        key: fields.callbackHeaderKey2.value,
        value: fields.callbackHeaderValue2.value
      });
    }

    const _shareLoginFields = [];
    for (const field of this.shareLoginFields) {
      if (field.select) {
        _shareLoginFields.push(field.name);
      }
    }
    _body['shareLoginFields'] = _shareLoginFields;

    _body.instantPaymentConfig = {
      callbackAfterPay: this.getApiConfigBody(this.instantPaymentForm, 'callbackAfterPay'),
      callbackAfterPayV2: this.getApiConfigBody(this.instantPaymentForm, 'callbackAfterPayV2'),
      callbackAfterRefund: this.getApiConfigBody(this.instantPaymentForm, 'callbackAfterRefund'),
    };

    _body.autoDebitConfig = {
      callbackAfterRegister: this.getApiConfigBody(this.autoDebitForm, 'callbackAfterRegister'),
      callbackAfterPayPeriod: this.getApiConfigBody(this.autoDebitForm, 'callbackAfterPayPeriod'),
      getMerchantBill: this.getApiConfigBody(this.autoDebitForm, 'getMerchantBill')
    };

    return _body;
  }

  private validate(_name: string, _code: string, currentIndex?: number): boolean {
    const name = _name.toUpperCase();
    const code = _code.toUpperCase();
    // Validate
    const duplicate = _.find(this.paymentTransactionTypeForms, (transactionTypeForm, index) => {
      if (currentIndex != null && index === currentIndex) {
        return false;
      }
      const fields = transactionTypeForm.fields;
      if (fields['code'].value.toUpperCase() === code) {
        this.utilityService.showErrorToast('feature.merchant.transaction-type-code-used');
        return true;
      }
      // if (fields['name'].value.toUpperCase() === name) {
      //   this.utilityService.showErrorToast('feature.merchant.transaction-type-name-used');
      //   return true;
      // }
      return false;
    });
    return duplicate == null;
  }

  private initShareLoginFields() {
    this.shareLoginFields = [
      {name: 'cif', select: true, readonly: true},
      {name: 'fullname', select: true, readonly: true},
      {name: 'fullnameVn', select: false, readonly: false},
      {name: 'idCardNo', select: false, readonly: false},
      {name: 'idCardType', select: false, readonly: false},
      {name: 'idCardIssuedDate', select: false, readonly: false},
      {name: 'idCardIssuedPlace', select: false, readonly: false},
      {name: 'idCardExpiryDate', select: false, readonly: false},
      {name: 'dob', select: false, readonly: false},
      {name: 'gender', select: false, readonly: false},
      {name: 'mobile', select: false, readonly: false},
      {name: 'email', select: false, readonly: false},
      {name: 'address', select: false, readonly: false},
      {name: 'permanentAddress', select: false, readonly: false},
      {name: 'maritalStatus', select: false, readonly: false},
      {name: 'nationality', select: false, readonly: false},
      {name: 'accountList', select: false, readonly: false},
      {name: 'accountDefault', select: false, readonly: false},
      // {name: 'deviceTokenList', select: false, readonly: false},
      // {name: 'kycLevel', select: false, readonly: false},
      {name: 'sector', select: false, readonly: false},
      // {name: 'deviceId', select: false, readonly: false},
      // {name: 'appSessionId', select: false, readonly: false}
    ];

    for (const field of this.shareLoginFields) {
      if (!field.select) {
        field.select = _.includes(this.record.shareLoginFields, field.name);
      }
    }
  }

  private addFormApiConfig(formConfig: {
    [key: string]: {
      value: any,
      validator: FaskValidator,
      relatedKeys?: string[],
      requiredDependingKey?: string,
      noRequiredValue?: any
    }
  }, apiConfig: any, prefix: string): {
    [key: string]: {
      value: any,
      validator: FaskValidator,
      relatedKeys?: string[],
      requiredDependingKey?: string,
      noRequiredValue?: any
    }
  } {
    apiConfig = apiConfig == null ? {} : apiConfig;

    let headerKey1 = null;
    let headerValue1 = null;
    let headerKey2 = null;
    let headerValue2 = null;
    if (apiConfig.headerConfig != null && apiConfig.headerConfig.items != null) {
      if (apiConfig.headerConfig.items.length > 0) {
        headerKey1 = apiConfig.headerConfig.items[0].key;
        headerValue1 = apiConfig.headerConfig.items[0].value;
      }
      if (apiConfig.headerConfig.items.length > 1) {
        headerKey2 = apiConfig.headerConfig.items[1].key;
        headerValue2 = apiConfig.headerConfig.items[1].value;
      }
    }

    formConfig[prefix + 'Url'] = {
      value: apiConfig.url,
      validator: FaskValidatorFactory.link(false)
    };
    formConfig[prefix + 'ChecksumKey'] = {
      value: apiConfig.checksumKey,
      validator: FaskValidatorFactory.text(false, 1, 100)
    };
    formConfig[prefix + 'HeaderKey1'] = {value: headerKey1, validator: FaskValidatorFactory.text(false, 1, 100)};
    formConfig[prefix + 'HeaderValue1'] = {
      value: headerValue1,
      validator: FaskValidatorFactory.text(false, 1, 100)
    };
    formConfig[prefix + 'HeaderKey2'] = {value: headerKey2, validator: FaskValidatorFactory.text(false, 1, 100)};
    formConfig[prefix + 'HeaderValue2'] = {
      value: headerValue2,
      validator: FaskValidatorFactory.text(false, 1, 100)
    };

    return formConfig;
  }

  private getApiConfigBody(form: FaskForm, prefix: string) {
    const body = {
      url: form.fields[prefix + 'Url'].value,
      checksumKey: form.fields[prefix + 'ChecksumKey'].value,
      headerConfig: null
    };

    if (!this.checkEmptyText(form.fields[prefix + 'HeaderKey1'].value) && !this.checkEmptyText(form.fields[prefix + 'HeaderValue1'].value)) {
      body.headerConfig = {
        items: [{
          key: form.fields[prefix + 'HeaderKey1'].value,
          value: form.fields[prefix + 'HeaderValue1'].value
        }]
      };
    }
    if (!this.checkEmptyText(form.fields[prefix + 'HeaderKey2'].value) && !this.checkEmptyText(form.fields[prefix + 'HeaderValue2'].value)) {
      if (body.headerConfig == null) {
        body.headerConfig = {
          items: []
        };
      }
      body.headerConfig.items.push({
        key: form.fields[prefix + 'HeaderKey2'].value,
        value: form.fields[prefix + 'HeaderValue2'].value
      });
    }

    return body;
  }

}
