import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImportViewAbstractComponent} from '../../abstract/import-view.abstract.component';
import {FileModel} from '../input-file/input-file.component';

@Component({
  selector: 'app-import-view',
  templateUrl: './import-view.component.html'
})
export class ImportViewComponent implements OnInit {

  @Input() vm: ImportViewAbstractComponent;
  @Output() excelSelect = new EventEmitter<FileModel>();

  constructor() {
  }

  ngOnInit() {
  }

  onExcelSelected(fileModel: FileModel) {
    if (this.excelSelect != null) {
      this.excelSelect.emit(fileModel);
    }
  }

}
