import {Pipe, PipeTransform} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Pipe({name: 'paymentType'})
export class PaymentTypePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    if (_.isEmpty(value)) {
      return this.translateService.instant('base.payment-transaction-type.IMMEDIATELY');
    }

    return this.translateService.instant('base.payment-transaction-type.AUTO');
  }

}
