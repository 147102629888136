<div class="header-container">

  <div class="row no-gutters">

    <div class="col-auto">
      <button class="open-sidebar-btn" (click)="openSidebar()">
        <i class="fas fa-bars"></i>
      </button>
    </div>

    <div class="col-auto">
      <button class="user-info-btn" (click)="openUserInfoModal(userinfoModalTemplate)">
        <i class="far fa-user"></i>
        <span>&nbsp;&nbsp;&nbsp;{{ getUserInfo().fullname }}</span>
      </button>
    </div>

<!--    <div class="col-auto">-->
<!--      <button class="user-language-btn" (click)="openUserLanguageModal(userLanguagesModalTemplate)">-->
<!--        <img src="../assets/images/languages/vi.svg" alt="vi" *ngIf="getCurrentLanguage() == 'vi'">-->
<!--        <img src="../assets/images/languages/en.svg" alt="en" *ngIf="getCurrentLanguage() == 'en'">-->
<!--      </button>-->
<!--    </div>-->

  </div>

</div>

<ng-template #userinfoModalTemplate>

  <div class="userinfo-modal-header">
    <img src="../assets/images/userinfo-icon.svg" alt="user">

    <div>
      <h4 class="fullname">{{ getUserInfo().fullname }}</h4>
      <h6 class="username">
        <span class="label label-success">
          {{ 'base.role.' + (getUserInfo().poRole == true? 'PO': getUserInfo().role) | translate }}
        </span>
       <br> <span>
          &nbsp;{{ getUserInfo().username }}
        </span>
      </h6>
    </div>

  </div>

  <div class="userinfo-modal-body">
    <button class="btn-no-outline" (click)="changePassword()">
      {{ 'base.userinfo-modal.change-password' | translate }}
    </button>
    <button class="btn-no-outline text-danger" (click)="logout()">
      {{ 'base.userinfo-modal.logout' | translate }}
    </button>
  </div>

</ng-template>

<ng-template #userLanguagesModalTemplate>

  <div class="user-languages-modal-body">
    <div class="row no-gutters" [ngClass]="{ 'active': getCurrentLanguage() == 'vi' }" (click)="changeLanguage('vi')">
      <div class="col-auto">
        <div class="flag">
          <img src="../assets/images/languages/vi.svg" alt="vi">
        </div>
      </div>

      <div class="col">
        <div class="language">
          Tiếng Việt
        </div>
      </div>

      <div class="col-auto">
        <div class="selected-tag">
          <i class="fas fa-check"></i>
        </div>
      </div>
    </div>

    <div class="row no-gutters" [ngClass]="{ 'active': getCurrentLanguage() == 'en' }" (click)="changeLanguage('en')">
      <div class="col-auto">
        <div class="flag">
          <img src="../assets/images/languages/en.svg" alt="en">
        </div>
      </div>

      <div class="col">
        <div class="language">
          English
        </div>
      </div>

      <div class="col-auto">
        <div class="selected-tag">
          <i class="fas fa-check"></i>
        </div>
      </div>
    </div>

  </div>

</ng-template>
