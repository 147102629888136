import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';
import Utils from '../../utils/utils';

@Component({
  selector: 'app-form-group-color',
  templateUrl: './form-group-color.component.html',
  styleUrls: ['./form-group-color.component.scss']
})
export class FormGroupColorComponent extends FormGroupAbstractComponent implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onColorChanged(value): void {
    this.valueChange.emit({fieldKey: this.fieldKey, value: value});
  }

  isDarkColor() {
    return Utils.isDarkColor(this.getField().value);
  }

}
