import {Component, Injector} from '@angular/core';
import {PODetailAbstractComponent} from '../../../../../core/components/abstract/po-detail.abstract.component';
import {FaskFormBuilder, FaskValidatorFactory} from '../../../../../core/form/fask.form';
import {FileModel} from '../../../../../core/components/utility/input-file/input-file.component';

@Component({
  selector: 'app-sandbox-apk-detail',
  templateUrl: './sandbox-apk-detail.component.html'
})
export class SandboxApkDetailComponent extends PODetailAbstractComponent {

  vm = this;
  fileApk = null;

  constructor(injector: Injector) {
    super(injector, 'feature.sandbox-apk.title', '/api/admin/sandbox-apk', 'sandbox-apk-list');
  }

  protected componentInit() {
    super.componentInit();
  }

  onFileSelected(fileModel: FileModel) {
    this.fileApk = fileModel;
  }

  protected isValidForSave(): boolean {
    if (this.isNew()) {
      if (this.fileApk == null) {
        this.utilityService.showErrorToast('Vui lòng chọn file apk');
      }
    }

    return super.isValidForSave();
  }

  protected getBodyToSave() {
    const body = {
      version: this.form.fields['version'].value,
      fileBase64: this.fileApk != null ? this.fileApk.file : null
    };

    return body;
  }

  protected createForm() {
    this.form = FaskFormBuilder.buildForm({
      version: {
        value: this.record['version'],
        validator: FaskValidatorFactory.text(true, 5, 5, /^[0-9]+.[0-9]+.[0-9]+$/)
      },
    });
  }

}
