import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-radiobox',
  templateUrl: './radiobox.component.html',
  styleUrls: ['./radiobox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioboxComponent),
      multi: true
    }
  ]
})
export class RadioboxComponent {

  @Input() label = null;
  @Input() radioGroupModal: RadioGroupModal = null;
  @Input() radioGroupModalValue: string = null;
  @Output() valueChange = new EventEmitter<{ value: any }>();

  private ngChange = ((value: boolean) => {
  });

  private ngTouched = (() => {
  });

  constructor() {
  }

  checkboxToggle() {
    if (this.radioGroupModal != null && this.radioGroupModalValue != null) {
      this.radioGroupModal.setValueSelected(this.radioGroupModalValue);
      this.valueChange.emit({value: this.radioGroupModalValue});
    }
  }

  isChecked() {
    if (this.radioGroupModal != null && this.radioGroupModalValue != null) {
      return this.radioGroupModal.isValueSelected(this.radioGroupModalValue);
    }
    return false;
  }

}

export class RadioGroupModal {

  private selectedMap = {};

  constructor(public valueList: string[], valueSelected: string) {
    for (const value of this.valueList) {
      this.selectedMap[value] = value === valueSelected;
    }
  }

  public isValueSelected(valueSelected: string) {
    return this.selectedMap[valueSelected] === true;
  }

  public getValueSelected(): string {
    for (const value of this.valueList) {
        if (this.selectedMap[value]) {
          return value;
        }
    }

    return null;
  }

  public setValueSelected(valueSelected: string) {
    for (const value of this.valueList) {
      this.selectedMap[value] = value === valueSelected;
    }
  }

}
