import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';

@Pipe({name: 'epochDate'})
export class EpochDatePipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }

    return moment(value * 1000).format('DD/MM/YYYY');
  }
}
