<div class="form-group" [ngClass]="{ 'has-danger': isAddDangerClass() }" *ngIf="getField() != null">

  <label class="form-control-label">
    {{ label | translate }}<span *ngIf="getValidator().required">*</span>
  </label>

  <div class="input-group">
    <div class="input-group-prepend" *ngIf="!checkEmptyText(unitLeft)">
      <span class="input-group-text">{{ unitLeft }}</span>
    </div>

    <ngx-numeric-textbox [decimals]="myDecimals"
                         [format]="myFormat"
                         [disabled]="fieldDisabled || fieldReadonly"
                         [(ngModel)]="getField().value"
                         (ngModelChange)="onNumberChanged($event)"></ngx-numeric-textbox>

    <div class="input-group-append" *ngIf="!checkEmptyText(unitRight)">
      <span class="input-group-text">{{ unitRight }}</span>
    </div>
  </div>

  <div class="form-control-feedback" *ngIf="isShowTextError()">

    <span *ngIf="useDefaultErrorMessage()">
      <span *ngIf="getValidator().required">{{ 'form.error.message.number-required' | translate }}</span>
      <span *ngIf="!getValidator().required">{{ 'form.error.message.number' | translate }}</span>
      <span *ngIf="hasMinMaxConstraint()">&nbsp;({{getMinMaxConstraintErrorMessage()}})</span>
    </span>

    <span *ngIf="!useDefaultErrorMessage()">
      {{ errorMessage | translate:getValidator().params }}
    </span>

  </div>

</div>
