import * as _ from 'lodash';
import Utils from '../../../utils/utils';

export class SidebarMenu {
  public opened: boolean;
  public queryParams = null;

  constructor(public name: string,
              public icon: string,
              public state: string,
              public subMenus?: SidebarMenu[],
              public roles?: string[],
              public feature?: string,
              public childStates?: string[]) {
    this.opened = false;
  }

  public hasChild(): boolean {
    return this.subMenus != null && this.subMenus.length > 0;
  }

  public isConfigMenu(): boolean {
    for(let i = 0; i < this.roles.length; i++) {
      if (this.roles[i] == "PO") return true;
    }
    return false;
  }

  public isValidUserInfo(userInfo) {
    if (userInfo == null || userInfo.role == null) {
      return false;
    }

    if (!this.isValidRole(userInfo.role)) {
      return false;
    }

    if (userInfo.needCheckFeature) {
      if (!this.isValidFeature(userInfo.features)) {
        return false;
      }
    }

    return true;
  }

  public isValidRole(role): boolean {
    if (this.roles == null) {
      return true;
    }

    return _.includes(this.roles, role);
  }

  public isValidFeature(features): boolean {
    if (this.feature == null) {
      return true;
    }

    return _.includes(features, this.feature);
  }

  public addQueryParams(id?: any, params?: any): SidebarMenu {
    this.queryParams = {
      id: id,
      q: Utils.objectToB64(params)
    };

    return this;
  }

  public clone(subMenus: SidebarMenu[]): SidebarMenu {
    const newSidebarMenu = new SidebarMenu(this.name, this.icon, this.state, subMenus, this.roles, this.feature, this.childStates);
    newSidebarMenu.queryParams = this.queryParams;
    return newSidebarMenu;
  }

}

export class SidebarMenuGroup {
  constructor(public name: string, public menus: SidebarMenu[], public roles?: string[], public feature?: string) {

  }

  public isValidUserInfo(userInfo: any): boolean {
    if (userInfo == null || userInfo.role == null) {
      return false;
    }

    if (!this.isValidRole(userInfo.role)) {
      return false;
    }

    if (userInfo.needCheckFeature) {
      if (!this.isValidFeature(userInfo.features)) {
        return false;
      }
    }

    return true;
  }

  public isConfigMenu(): boolean {
    return _.includes(this.roles, 'PO');
  }

  public isValidRole(role: string): boolean {
    if (this.roles == null) {
      return true;
    }

    return _.includes(this.roles, role);
  }

  public isValidFeature(features): boolean {
    if (this.feature == null) {
      return true;
    }

    return _.includes(features, this.feature);
  }

  public clone(menus: SidebarMenu[]): SidebarMenuGroup {
    return new SidebarMenuGroup(this.name, menus, this.roles);
  }

}
