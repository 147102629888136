<app-loading [loadingStatus]="vm.loadingStatus">
  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ title | translate }}</h2>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="margin-auto max-w-md">
              <div *ngIf="vm.form" class="row">

                <div class="col-12">
                  <app-form-group-select (valueChange)="onFieldChanged($event)"
                                         [fieldKey]="'merchant'"
                                         [form]="vm.form"
                                         [itemDisplayField]="'displayValue'"
                                         [label]="'feature.payment-transaction.merchant'"
                                         [loadData]="loadMerchants"
                                         [placeholder]="'base.option.all' | translate"
                                         [showError]="showFieldError"
                                         [useTypeahead]="true">
                  </app-form-group-select>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <i>*&nbsp;{{ 'feature.incoming-request-export.description' | translate }}</i>
                  </div>
                </div>

                <div class="col-12">
                  <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'useCustomTime'"
                                           [form]="vm.form"
                                           [label]="'feature.incoming-request-export.custom-time'"
                                           [showError]="showFieldError"></app-form-group-checkbox>
                </div>

                <div class="col-12" *ngIf="form.fields.useCustomTime.value">
                  <app-form-group-date (valueChange)="onFieldChanged($event)"
                                       [fieldKey]="'toDate'"
                                       [form]="vm.form"
                                       [label]="'base.date'"
                                       [placement]="'top'"
                                       [showError]="showFieldError">
                  </app-form-group-date>
                </div>

                <div class="col-6" *ngIf="form.fields.useCustomTime.value">
                  <app-form-group-combobox (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'hour'"
                                           [form]="vm.form"
                                           [label]="'base.hour'"
                                           [showError]="showFieldError"
                                           [itemList]="hours">
                  </app-form-group-combobox>
                </div>

                <div class="col-6" *ngIf="form.fields.useCustomTime.value">
                  <app-form-group-combobox (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'minute'"
                                           [form]="vm.form"
                                           [label]="'base.minute'"
                                           [showError]="showFieldError"
                                           [itemList]="minutes">
                  </app-form-group-combobox>
                </div>

                <div class="col-12">
                  <button (click)="export()" class="btn btn-info w-full">
                    {{ 'base.export-excel' | translate }}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</app-loading>
