import {Component, Injector} from '@angular/core';
import {AbstractComponent} from '../../../../../core/components/abstract/abstract.component';
import {FaskField, FaskForm, FaskFormBuilder, FaskValidator} from '../../../../../core/form/fask.form';
import * as moment from 'moment';
import Utils from '../../../../../core/utils/utils';

@Component({
  selector: 'app-payment-transaction-log-export',
  templateUrl: './payment-transaction-log-export.component.html'
})
export class PaymentTransactionLogExportComponent extends AbstractComponent {

  vm = this;
  form: FaskForm;
  showFieldError = false;

  constructor(injector: Injector) {
    super(injector);
  }

  export() {
    if (!this.checkBeforeAction()) {
      return;
    }

    this.httpDownloadFile(
      this.getApiUrl('/api/admin/payment-transaction-log/export'),
      {
        fromDate: Utils.createIsoDate(this.form.fields['fromDate'].value),
        toDate: Utils.createIsoDate(this.form.fields['toDate'].value)
      },
      'export'
    );
  }

  protected componentInit() {
    this.showFieldError = false;
    this.createForm();
  }

  protected createForm() {
    const fromDateValidator = new FaskValidator('invalid-date', (field: FaskField) => {
      return moment(field.value).isValid();
    }, true);

    const toDateValidator = new FaskValidator('invalid-date', (field: FaskField, form: FaskForm) => {
      const toDateField = field;
      const fromDateField = form.fields['fromDate'];
      if (fromDateField == null || !toDateField.value) {
        return false;
      }
      if (moment(fromDateField.value).isAfter(toDateField.value)) {
        return false;
      }
      return moment(fromDateField.value).add('3', 'months').isAfter(toDateField.value);
    }, true);

    const fromDate = moment(new Date()).add(-1, 'days').startOf('day').toDate();
    const toDate = moment(new Date()).startOf('day').toDate();

    this.form = FaskFormBuilder.buildForm({
      fromDate: {value: fromDate, validator: fromDateValidator},
      toDate: {value: toDate, validator: toDateValidator, relatedKeys: ['fromDate']}
    });
  }

  protected checkBeforeAction() {
    if (this.form != null && !this.form.isValid()) {
      this.showFieldError = true;
      return false;
    }

    return true;
  }

}
