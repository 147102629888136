import {Component, Injector} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AbstractComponent} from '../../abstract/abstract.component';
import {FaskField, FaskForm, FaskFormBuilder, FaskValidator, FaskValidatorFactory} from '../../../form/fask.form';
import Utils from '../../../utils/utils';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html'
})
export class ResetPasswordModalComponent extends AbstractComponent {

  form: FaskForm;
  showFieldError: boolean;
  onResetPassword: (newPassword: string) => void;
  passwordGenerated: boolean;

  constructor(injector: Injector, private bsModalRef: BsModalRef) {
    super(injector);
  }

  protected componentInit() {
    this.showFieldError = false;
    this.passwordGenerated = false;
    this.createForm();
  }

  save() {
    if (!this.form.isValid()) {
      this.showFieldError = true;
      return;
    }

    this.bsModalRef.hide();
    if (this.onResetPassword != null) {
      this.onResetPassword(this.form.fields['newPassword1'].value);
    }
  }

  close() {
    this.bsModalRef.hide();
  }

  generateStrongPassword() {
    this.passwordGenerated = true;
    this.form.fields.newPassword1.value = Utils.generateStrongPassword();
    this.form.fields.newPassword2.value = this.form.fields.newPassword1.value;
  }

  // PRIVATE
  private createForm() {
    this.form = FaskFormBuilder.buildForm({
      newPassword1: {value: '', validator: FaskValidatorFactory.password(true)},
      newPassword2: {
        value: '',
        validator: new FaskValidator('text', (field: FaskField, form: FaskForm) => {
          const newPassword1Field = form.fields['newPassword1'];
          if (newPassword1Field == null || Utils.isEmptyString(newPassword1Field.value)) {
            return false;
          }

          const newPassword1 = newPassword1Field.value.trim();

          if (field != null && !Utils.isEmptyString(field.value)) {
            const newPassword2 = field.value.toString().trim();

            return newPassword1 === newPassword2;
          }

          return false;
        }, true),
        relatedKeys: ['newPassword1']
      }
    });
  }

}
