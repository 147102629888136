import {Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, AbstractControl, ValidationErrors} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor, Validator, OnChanges {

  @Input() label = null;
  @Input() disabled = false;
  checked = false;

  private ngChange = ((value: boolean) => {
  });

  private ngTouched = (() => {
  });

  constructor() {
  }

  checkboxToggle() {
    if (this.disabled) {
      return;
    }

    this.checked = !this.checked;
    this.ngChange(this.checked);
    this.ngTouched();
  }

  writeValue(obj: any) {
    this.checked = obj;
  }

  registerOnChange(fn: any) {
    this.ngChange = fn;
  }

  registerOnTouched(fn: any) {
    this.ngTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return null;
  }

  ngOnChanges(changes: SimpleChanges) {
    // DO NOTHING
  }

}
