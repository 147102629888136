<div class="input-group active-status-control">
  <div class="form-control" readonly>
    <span *ngIf="active">
      <i class="far fa-check text-success"></i>
      <span>&nbsp;&nbsp;{{ activeLabel | translate }}</span>
    </span>

    <span *ngIf="!active">
      <i class="far fa-ban text-danger"></i>
      <span>&nbsp;&nbsp;{{ inactiveLabel | translate }}</span>
    </span>
  </div>

  <div class="input-group-append" *ngIf="!readonly">
    <button class="btn btn-info" type="button" *ngIf="!active" (click)="onActivateBtnClicked()">
      <span>{{ activateBtnLabel | translate }}</span>
    </button>
    <button class="btn btn-warning" type="button" *ngIf="active" (click)="onDeactivateBtnClicked()">
      <span>{{ deactivateBtnLabel | translate }}</span>
    </button>
  </div>
</div>
