<app-po-detail [vm]="vm" [useSimplePOTemplate]="false">

  <div po-detail-content>
    <div class="row">

      <div class="col-12">

        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'base.general.info' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="margin-auto max-w-md">
              <div class="row" *ngIf="vm.form">

                <div class="col-6">
                  <div class="form-group">
                    <label> {{ 'base.status' | translate }}:&nbsp;</label>
                    <app-active-status-label [po]="vm.record"></app-active-status-label>
                  </div>
                </div>

                <div class="col-6 text-right" *ngIf="!vm.isNew()">
                  <button class="btn btn-dark m-b"
                          (click)="vm.enable()"
                          [disabled]="vm.isCanSave()"
                          *ngIf="vm.isCanEnable()">
                    <span class="fas fa-check"></span>
                    {{ 'base.enable' | translate }}
                  </button>

                  <button class="btn btn-danger m-b"
                          (click)="vm.deactivate()"
                          [disabled]="vm.isCanSave()"
                          *ngIf="vm.isCanDeactivate()">
                    <span class="fas fa-ban"></span>
                    {{ 'base.deactivate' | translate }}
                  </button>

                  <button class="btn btn-dark m-b"
                          (click)="vm.activate()"
                          [disabled]="vm.isCanSave()"
                          *ngIf="vm.isCanActivate()">
                    <span class="fas fa-check"></span>
                    {{ 'base.activate' | translate }}
                  </button>
                </div>

                <div class="col-12">
                  <app-form-group-text [form]="vm.form"
                                       [fieldKey]="'version'"
                                       [label]="'Version'"
                                       [showError]="vm.showFieldError"
                                       (valueChange)="vm.onFieldChanged($event)"
                                       [fieldReadonly]="vm.isInactive()"
                                       [errorMessage]="'Version sai định dạng (VD: 2.0.0)'"></app-form-group-text>
                </div>

                <div class="col-12" *ngIf="!isNew()">
                  <div class="m-b">
                    <a [href]="record.fileUrl" target="_blank">Download apk</a>
                  </div>
                </div>

                <div class="col-12">
                  <app-input-file [buttonClass]="'btn m-b w-full ' + (fileApk == null ? 'btn-default' : 'btn-info')"
                                  (fileSelect)="onFileSelected($event)"
                                  [accept]="'.apk'">
                    <span *ngIf="fileApk == null && isNew()"><i
                      class="fab fa-android"></i>&nbsp;{{ 'feature.sandbox-apk.choose-apk' | translate }}</span>
                    <span *ngIf="fileApk == null && !isNew()"><i
                      class="fab fa-android"></i>&nbsp;{{ 'feature.sandbox-apk.change-apk' | translate }}</span>
                    <span *ngIf="fileApk != null"><i
                      class="fab fa-android"></i>&nbsp;{{ fileApk.fileName }}</span>
                  </app-input-file>
                </div>

                <div class="col-12">
                  <div class="row small-gutters">
                    <div class="col-auto" *ngIf="vm.isCanDelete()">
                      <button class="btn btn-danger m-b" (click)="vm.delete()">
                        <span>{{ 'base.delete' | translate }}</span>
                      </button>
                    </div>

                    <div class="col">
                      <div class="row small-gutters">
                        <div class="col"><!--EMPTY--></div>

                        <div class="col-auto" *ngIf="vm.isCanBack()">
                          <button class="btn btn-dark m-b" (click)="vm.back()">
                            <span *ngIf="!vm.isCanSave()">{{ 'base.btn.back' | translate }}</span>
                            <span *ngIf="vm.isCanSave()">{{ 'base.btn.cancel' | translate }}</span>
                          </button>
                        </div>

                        <div class="col-auto">
                          <button class="btn btn-success m-b" (click)="vm.save()" [disabled]="!vm.isCanSave()">
                            <span>{{ 'base.btn.save' | translate }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</app-po-detail>
