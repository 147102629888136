import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FaskForm, FaskFormBuilder, FaskValidatorFactory} from '../../../form/fask.form';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {

  title: string;
  message: string;
  additionalMessageList: [];
  showAcceptBtn = true;
  acceptBtnName: string;
  showCancelBtn = true;
  cancelBtnName: string;
  reverseBtnColor = false;
  useTextArea = false;
  textAreaLabel = 'base.note';
  textAreaRequired = false;
  textAreaLengthMin = 1;
  textAreaLengthMax = 512;
  acceptFn: (textAreaValue?: string) => void;
  cancelFn: () => void;

  public form: FaskForm;
  public showFieldError: boolean;

  constructor(private bsModalRef: BsModalRef) {
  }

  ngOnInit() {
    this.form = FaskFormBuilder.buildForm({
      text: {
        value: '',
        validator: FaskValidatorFactory.text(this.textAreaRequired, this.textAreaLengthMin, this.textAreaLengthMax)
      }
    });
    this.showFieldError = false;
  }

  accept() {
    if (this.useTextArea && this.form.isInvalid()) {
      this.showFieldError = true;
      return;
    }
    this.bsModalRef.hide();
    if (this.acceptFn != null) {
      this.acceptFn(this.form.fields.text.value);
    }
  }

  cancel() {
    this.bsModalRef.hide();
    if (this.cancelFn != null) {
      this.cancelFn();
    }
  }

}
