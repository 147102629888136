import {Component, Injector, Input, OnInit} from '@angular/core';
import * as numeral from 'numeral';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';

@Component({
  selector: 'app-form-group-number',
  templateUrl: './form-group-number.component.html'
})
export class FormGroupNumberComponent extends FormGroupAbstractComponent implements OnInit {

  @Input() set decimals(decimals: number) {
    this.myDecimals = decimals;

    const delimiterDecimal = '.';
    const delimiterThousands = ',';

    this.myFormat = '0' + delimiterThousands + '0';
    if (decimals > 0) {
      this.myFormat = this.myFormat + delimiterDecimal;
      for (let i = 0; i < decimals; i++) {
        this.myFormat = this.myFormat + '0';
      }
    }
  }

  get decimals(): number {
    return this.myDecimals;
  }

  @Input() unitLeft: string = null;
  @Input() unitRight: string = null;

  myDecimals = 0;
  myFormat = '0.0';

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onNumberChanged(value): void {
    this.valueChange.emit({fieldKey: this.fieldKey, value: value});
  }

  hasMinMaxConstraint() {
    return this.getValidator().params.min != null || this.getValidator().params.max != null;
  }

  getMinMaxConstraintErrorMessage() {
    let errorMessage = '';

    if (this.getValidator().params.min != null) {
      if (this.getValidator().params.excludeMin) {
        errorMessage = errorMessage + '> ' + numeral(this.getValidator().params.min).format(this.myFormat);
      } else {
        errorMessage = errorMessage + '>= ' + numeral(this.getValidator().params.min).format(this.myFormat);
      }
    }

    if (this.getValidator().params.max != null) {
      if (errorMessage.length > 0) {
        errorMessage = errorMessage + ', ';
      }

      if (this.getValidator().params.excludeMax) {
        errorMessage = errorMessage + '< ' + numeral(this.getValidator().params.max).format(this.myFormat);
      } else {
        errorMessage = errorMessage + '<= ' + numeral(this.getValidator().params.max).format(this.myFormat);
      }
    }

    return errorMessage;
  }

}
