<app-loading [loadingStatus]="vm.loadingStatus">
  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ 'feature.auto-debit-log-export.title' | translate }}</h2>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="margin-auto max-w-md">
              <div *ngIf="vm.form" class="row">

                <div class="col-12">
                  <div class="row">

                    <div class="col-6">
                      <app-form-group-date [fieldKey]="'fromDate'"
                                           [form]="vm.form"
                                           [label]="'base.from-updated-time'"
                                           [showError]="showFieldError">
                      </app-form-group-date>
                    </div>

                    <div class="col-6">
                      <app-form-group-date
                        [errorMessage]="'feature.payment-transaction.to-date-cannot-before-from-date'"
                        [fieldKey]="'toDate'"
                        [form]="vm.form"
                        [label]="'base.to-updated-time'"
                        [showError]="showFieldError">
                      </app-form-group-date>
                    </div>

                  </div>
                </div>

                <div class="col-12">
                  <button (click)="export()" class="btn btn-info w-full">
                    {{ 'base.export-excel' | translate }}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</app-loading>
