import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-editor-section-youtube-modal',
  templateUrl: './editor-section-youtube-modal.component.html',
  styleUrls: ['./editor-section-youtube-modal.component.scss']
})
export class EditorSectionYoutubeModalComponent implements OnInit {

  youtubeUrl: string;
  caption: string;
  okFn: (youtubeUrl: string, youtubeCaption?: string) => void;

  constructor(private bsModalRef: BsModalRef) {
  }

  ngOnInit() {
  }

  ok() {
    this.bsModalRef.hide();
    if (this.okFn != null) {
      this.okFn(this.youtubeUrl, this.caption);
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }


}
