<div class="input-group search-field" [ngClass]="{ 'show-clear-btn': isShowClearBtn() }">
  <input type="text" class="form-control" [placeholder]="'base.search.placeholder' | translate" [(ngModel)]="mySearchText" (keyup)="onEnter($event)">
  <div class="input-group-append">
    <button class="btn btn-info" type="button" *ngIf="!isShowClearBtn()" (click)="onSearchBtnClick()">
      <i class="fas fa-search"></i>
    </button>
    <button class="btn btn-danger" type="button" *ngIf="isShowClearBtn()" (click)="onClearBtnClick()">
      <i class="fas fa-times"></i>
    </button>
  </div>
</div>
