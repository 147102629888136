import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';

@Pipe({name: 'epochFromNow'})
export class EpochFromNowPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }

    return moment(value).fromNow();
  }
}
