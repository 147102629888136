import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-auto-debit-status-label',
  templateUrl: './auto-debit-status-label.component.html'
})
export class AutoDebitStatusLabelComponent implements OnInit {

  @Input() status: any;

  constructor() { }

  ngOnInit() {
  }

}
