<div *ngIf="getField() != null" [ngClass]="{ 'has-danger': isInvalid() }" class="form-group app-form-group-select">

  <label *ngIf="!noLabel" class="form-control-label">
    {{ label | translate }}<span *ngIf="getValidator().required">*</span>
  </label>

  <ng-select #selectComponent
             [items]="internalDataSource$ | async"
             [bindLabel]="itemDisplayField"
             [loading]="loading"
             [compareWith]="compareFunction"
             [searchFn]="searchFunction"
             [typeahead]="inputSubject$"
             [disabled]="fieldDisabled || fieldReadonly"
             [(ngModel)]="getField().value"
             (ngModelChange)="onItemSelected($event)"
             [multiple]="multiple"
             [hideSelected]="hideSelected"
             [placeholder]="placeholder | translate"
             [typeToSearchText]="typeToSearchText | translate"
             [notFoundText] ="notFoundText | translate"
             [addTag]="addTag"
  >
  </ng-select>

  <div *ngIf="showError && getField().isInvalid()" class="form-control-feedback">

    <span *ngIf="useDefaultErrorMessage()">
      <span *ngIf="getValidator().required">{{ 'form.error.message.required' | translate:getValidator().params }}</span>
    </span>

    <span *ngIf="!useDefaultErrorMessage()">
      {{ errorMessage | translate:getValidator().params }}
    </span>

  </div>

</div>
