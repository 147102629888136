import {Component, Input, OnInit} from '@angular/core';
import Utils from '../../../utils/utils';

@Component({
  selector: 'app-payment-transaction-status-label',
  templateUrl: './payment-transaction-status-label.component.html',
  styleUrls: ['./payment-transaction-status-label.component.scss']
})
export class PaymentTransactionStatusLabelComponent implements OnInit {

  @Input() status: any;

  constructor() { }

  ngOnInit() {
  }

}
