import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';
import {FormGroupPhotoModel} from '../form-group-photo.model';
import {FileModel} from '../../components/utility/input-file/input-file.component';
import {CropImageModalComponent} from '../../components/utility/crop-image.modal/crop-image-modal.component';
import {Subject} from 'rxjs';
import Utils from '../../utils/utils';

@Component({
  selector: 'app-form-group-photo-crop',
  templateUrl: './form-group-photo-crop.component.html',
  styleUrls: ['./form-group-photo-crop.component.scss']
})
export class FormGroupPhotoCropComponent extends FormGroupAbstractComponent implements OnInit, OnDestroy {

  @Output() valueChange = new EventEmitter<{ fieldKey: string, value: FormGroupPhotoModel }>();
  @Input() width = null;
  @Input() height = null;
  @Input() resize: boolean = false;

  openPhotoChooserSubject: Subject<void> = new Subject<void>();

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.openPhotoChooserSubject = new Subject<void>();
  }

  ngOnDestroy() {
  }

  isHasPhoto() {
    const oldPhotoUrl = Utils.getFieldSafe(this.getField(), 'value', 'oldPhotoUrl');
    const newPhotoBase64 = Utils.getFieldSafe(this.getField(), 'value', 'newPhotoBase64');
    return oldPhotoUrl != null || newPhotoBase64 != null;
  }

  getPhotoSrc() {
    const oldPhotoUrl = Utils.getFieldSafe(this.getField(), 'value', 'oldPhotoUrl');
    const newPhotoBase64 = Utils.getFieldSafe(this.getField(), 'value', 'newPhotoBase64');
    return newPhotoBase64 != null ? newPhotoBase64 : oldPhotoUrl;
  }

  openPhotoChooser() {
    this.openPhotoChooserSubject.next();
  }

  onPhotoSelected(fileModel: FileModel) {
    console.log(this.width / this.height);

    const initialState = {
      imageBase64: fileModel.file,
      aspectRatio: this.width / this.height,
      resizeToWidth: this.resize ? this.width : 0,
      resizeToHeight: this.resize ? this.height : 0,
      onImageCropped: (imageBase64) => {
        const model: FormGroupPhotoModel = new FormGroupPhotoModel(null, imageBase64, false);
        this.getField().value = model;
        this.valueChange.emit({fieldKey: this.fieldKey, value: model});
      }
    };
    this.modalService.show(CropImageModalComponent, {initialState});
  }

  removePhoto() {
    const model: FormGroupPhotoModel = new FormGroupPhotoModel(null, null, true);
    this.getField().value = model;
    this.valueChange.emit({fieldKey: this.fieldKey, value: model});
  }

  getPaddingBottom() {
    return Math.round((this.height / this.width) * 10000) / 100;
  }


}
