import {Component, Injector} from '@angular/core';
import * as _ from 'lodash';
import {PODetailAbstractComponent} from "../../../../../core/components/abstract/po-detail.abstract.component";
import {FaskFormBuilder, FaskValidatorFactory} from "../../../../../core/form/fask.form";
import Utils from "../../../../../core/utils/utils";
import {ResetPasswordModalComponent} from "../../../../../core/components/utility/reset-password-modal/reset-password-modal.component";
import Menu from "../../../../menu";

@Component({
  selector: 'app-sub-admin-detail',
  templateUrl: './sub-admin-detail.component.html'
})
export class SubAdminDetailComponent extends PODetailAbstractComponent {

  vm = this;
  features = [];
  isPoRole: boolean;
  role: any;

  constructor(injector: Injector) {
    super(injector, 'feature.sub-admin.title', '/api/admin/sub-admin', 'sub-admin-list');
    this.isPoRole = this.utilityService.getTokenInfo().user_role.poRole;
  }

  protected componentInit() {
    super.componentInit();
  }

  protected getBodyToSave() {
    const body = {
      fullname: this.form.fields['fullname'].value,
      username: this.form.fields['username'].value,
      password: this.record.password,
      features: []
    };

    // for (const feature of this.features) {
    //   if (feature.selected != null && feature.selected) {
    //     body.features.push(feature.feature);
    //   }
    // }

    return body;
  }

  protected createForm() {
    // this.loadFeatures();

    this.form = FaskFormBuilder.buildForm({
      fullname: {value: this.record['fullname'], validator: FaskValidatorFactory.text(true, 1, 256)},
      username: {
        value: this.record['username'],
        validator: FaskValidatorFactory.text(true, 3, 20, new RegExp('^[a-zA-Z0-9_]+$'))
      },
      role: {
        value: this.getUserRole(this.record['poRole']),
        validator: FaskValidatorFactory.text(true, 1, 20, new RegExp('^[a-zA-Z0-9_ ]+$'))
      }
    });
  }

  getUserRole(isPoRole: boolean): string {
    if (isPoRole) {
      return 'Product Owner';
    }
    return 'Admin';
  }

  isCanResetPassword(): boolean {
    return !this.isNew() && !this.isCanSave();
  }

  resetPassword() {
    const initialState = {
      onResetPassword: (newPassword: string) => {

        this.httpPostOrPut(this.getApiUrl(this.poBackendPath + '/' + this.id + '/reset-password'),
          null,
          true,
          'reset-password',
          {newPassword: newPassword},
          () => {
            // DO NOTHING
          }
        );
      }
    };
    this.modalService.show(ResetPasswordModalComponent, {initialState: initialState});
  }

  enable() {
    const initialState = {
      onResetPassword: (newPassword: string) => {
        this.record.password = newPassword;
        super.save(true);
      }
    };
    this.modalService.show(ResetPasswordModalComponent, {initialState: initialState});
  }

  // PRIVATE
  // private loadFeatures() {
  //   const SUB_ADMIN_ROLE = 'SUB_ADMIN';

  //   this.features = [];
  //   for (const menuGroup of Menu.MENU_GROUPS) {
  //     if (!menuGroup.isValidRole(SUB_ADMIN_ROLE)) {
  //       continue;
  //     }

  //     if (menuGroup.feature != null) {
  //       // MENU GROUP HAS FEATURE CONFIG
  //       this.features.push({
  //         name: menuGroup.name,
  //         feature: menuGroup.feature,
  //         selected: _.includes(this.record['features'], menuGroup.feature),
  //         level: 0
  //       });
  //     } else {
  //       // MENU GROUP DON'T HAVE FEATURE CONFIG WE WILL CHECK CHILD MENU
  //       let menuFeatures = [];
  //       for (const menu of menuGroup.menus) {
  //         if (!menu.isValidRole(SUB_ADMIN_ROLE)) {
  //           continue;
  //         }

  //         if (menu.feature != null) {
  //           // MENU HAS FEATURE CONFIG
  //           menuFeatures.push({
  //             name: menu.name,
  //             feature: menu.feature,
  //             selected: _.includes(this.record['features'], menu.feature),
  //             level: 1
  //           });
  //         } else {
  //           // MENU DON'T HAVE FEATURE CONFIG WE WILL CHECK SUB MENU
  //           if (menu.hasChild()) {
  //             const subMenuFeatures = [];
  //             for (const subMenu of menu.subMenus) {
  //               if (!subMenu.isValidRole(SUB_ADMIN_ROLE)) {
  //                 continue;
  //               }

  //               if (subMenu.feature != null) {
  //                 subMenuFeatures.push({
  //                   name: subMenu.name,
  //                   feature: subMenu.feature,
  //                   selected: _.includes(this.record['features'], subMenu.feature),
  //                   level: 2
  //                 });
  //               }
  //             }

  //             if (!_.isEmpty(subMenuFeatures)) {
  //               menuFeatures = _.union(menuFeatures, [{name: menu.name, level: 1}], subMenuFeatures);
  //             }
  //           }
  //         }
  //       }

  //       if (!_.isEmpty(menuFeatures)) {
  //         this.features = _.union(this.features, [{name: menuGroup.name, level: 0}], menuFeatures);
  //       }
  //     }
  //   }
  // }

}
