<div class="login-container">

  <app-loading [loadingStatus]="loadingStatus" [keepContentDOM]="false" class="loading-component-white">

    <div class="login-block">

      <div class="logo-container">
        <img src="../assets/login/mb-logo.png" id="logo">
        <h1>{{ 'feature.login.slogan' | translate }}</h1>
      </div>

      <div class="login-box-container">
        <form name="f">
          <div class="error-block" *ngIf="loginFail">
            <p *ngIf="!captchaFail">{{ 'feature.login.message.fail' | translate }}</p>
            <p *ngIf="captchaFail">{{ 'feature.login.message.captcha-fail' | translate }}</p>
          </div>

          <div class="form-group">
            <label>{{ 'feature.login.username' | translate }}</label>
            <div class="input-icon">
              <input type="text"
                     id="username"
                     name="username"
                     class="form-control"
                     autocomplete="off"
                     [(ngModel)]="form.fields['username'].value"
                     (ngModelChange)="onValueChanged()"
                     [autofocus]="true">
              <i class="fas fa-user icon"></i>
            </div>
          </div>

          <div class="form-group">
            <label>{{ 'feature.login.password' | translate }}</label>
            <div class="input-icon">
              <input type="password"
                     id="password"
                     name="password"
                     class="form-control"
                     autocomplete="off"
                     [(ngModel)]="form.fields['password'].value"
                     (ngModelChange)="onValueChanged()"
                     (keyup)="onEnter($event)">
              <i class="fas fa-lock icon"></i>
            </div>
          </div>

          <div class="form-group" *ngIf="captchaSessionId != null">
            <label>{{ 'feature.login.captcha' | translate }}</label>
            <div>
              <div><img [src]="getCaptchaImageUrl()" alt=""/></div>
              <div class="input-icon">
                <input type="text"
                       id="captchaValue"
                       name="captchaValue"
                       class="form-control"
                       [(ngModel)]="form.fields['captchaValue'].value"
                       (ngModelChange)="onValueChanged()"
                       (keyup)="onEnter($event)">
                <i class="fas fa-qrcode icon"></i>
              </div>
            </div>
          </div>

          <input class="submit" type="submit" [value]="'feature.login.login' | translate" (click)="login()"
                 (keyup)="onEnter($event)">
        </form>
      </div>

    </div>

    <div class="login-footer">
      © 2019 MB Innovation Lab. All rights reserved.
    </div>

  </app-loading>

</div>


