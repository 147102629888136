<app-po-detail [useSimplePOTemplate]="false" [vm]="vm">
  <div po-detail-content>
    <div *ngIf="vm.record.id && !isRefund" class="row">

      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.payment-transaction.transaction-details' | translate }}</h4>
          </div>
          <div class="card-body">
            <div>
              <div class="row">

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.merchant-code' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.merchantCode }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'base.payment-transaction-type.label' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>{{ record.billingPeriodId | paymentType }}</div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.code' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.id }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.cif' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.cif }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.type' | translate }}</span>
                    </label>
                    <textarea class="form-control" readonly >{{ record.type.name + ' (' + record.type.code + ')' }}</textarea>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.allow-card' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ (record.type.allowCard ? 'base.yes' : 'base.no') | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.allow-bnpl' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ (record.type.allowBNPL ? 'base.yes' : 'base.no') | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.amount' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.amount | myInteger }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.description' | translate }}</span>
                    </label>
                    <textarea class="form-control" readonly>{{ record.description }}</textarea>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'base.status' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ ('base.payment-transaction-status.' + record.status) | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'base.billing-period-cancel-reason.label' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.billingPeriodCancelReason }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'base.created-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.createdTime | isoDateTime }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.session-id' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.sessionId }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.session-created-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.sessionCreatedTime | isoDateTime }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.payment-transaction.payment-details' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="">
              <div class="row">

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.verified-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.verifiedTime | isoDateTime }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.reported-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.reportedTime | isoDateTime }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.paid-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.paidTime | isoDateTime }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.payment-amount' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.paymentAmount | myInteger }}
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.payment-description' | translate }}</span>
                    </label>
                    <textarea class="form-control" readonly>{{ record.paymentDescription }}</textarea>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.funding-source' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.fundingSource }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.funding-account' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.fundingAccount }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.card-type' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.cardType }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.card-no' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.cardNo }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.customer-fullname' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.customerFullname }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.customer-phone' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.customerPhone }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.collection-account-no' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.collectionAccountNo }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.reference-number' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.referenceNumber }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.payment-channel' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.paymentChannel }}
                    </div>
                  </div>
                </div>

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.payment-transaction.error-code' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.errorCode }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="col-6">-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-control-label">-->
<!--                      <span>{{ 'feature.payment-transaction.error-description' | translate }}</span>-->
<!--                    </label>-->
<!--                    <div class="form-control" readonly>-->
<!--                      {{ record.errorDescription }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.payment-info.title' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="table-responsive table-basic no-margin-bottom border-bottom">
                <table class="table" table-content>
                  <thead>
                  <tr>
                    <th class="no-wrap">
                      {{ "feature.payment-info.payment-type" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.payment-info.source-number" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.payment-info.point-loyalty" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.payment-info.amount" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.payment-info.card-type" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.payment-info.card-no" | translate }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let paymentInfo of record.paymentInfos">
                    <td>{{ paymentInfo.paymentType }}</td>
                    <td>{{ paymentInfo.sourceNumber }}</td>
                    <td>{{ paymentInfo.pointLoyalty }}</td>
                    <td>{{ paymentInfo.paymentAmount }}</td>
                    <td>{{ paymentInfo.cardType }}</td>
                    <td>{{ paymentInfo.cardNo }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.payment-transaction.callback-info' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="table-responsive table-basic no-margin-bottom border-bottom">
                <table class="table" table-content>
                  <thead>
                  <tr>
                    <th class="no-wrap">
                      {{ "feature.callback-merchant.created-time" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.callback-merchant.http-status" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.callback-merchant.duration" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.callback-merchant.response" | translate }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let callBackHistory of record.callbackHistoryEmbedDtos">
                    <td>{{ callBackHistory.createdTime | isoDateTime }}</td>
                    <td>{{ callBackHistory.httpStatusCode }}</td>
                    <td>{{ callBackHistory.duration }}</td>
                    <td>{{ callBackHistory.responseContent }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.payment-transaction.tch-get-status' | translate }}</h4>
          </div>
          <div class="card-body">
            <div>
              <div class="row">

                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.tch-get-status-times' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.callbackTimes }}
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.tch-get-status-error-code' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.errorCode }}
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.tch-get-status-error-desc' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.errorDescription }}
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.tch-status' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.callBillingStatus }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.other-info.title' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="table-responsive table-basic no-margin-bottom border-bottom">
                <table class="table" table-content>
                  <thead>
                  <tr>
                    <th class="no-wrap">
                      {{ "feature.other-info.name" | translate }}
                    </th>
                    <th class="no-wrap">
                      {{ "feature.other-info.value" | translate }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let other of record.others">
                    <td>{{ other.name }}</td>
                    <td>{{ other.value }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="vm.record.id && isRefund" class="row">
      <div>
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.payment-transaction.refund-info-title' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="">
              <div class="row">

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.status' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.refundTransactionStatus }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.transactionId' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.autoDebitId }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.refund-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.refundedTime | isoDateTime }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.created-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.createdTime | isoDateTime }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.merchant-code' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.merchantCode}}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.payment-amount' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.amount | myInteger }}
                    </div>
                  </div>
                </div>



                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.payment-description' | translate }}</span>
                    </label>
                    <textarea class="form-control" readonly>{{ record.mbRefundDescription }}</textarea>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.reference-number' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.mbReferenceNumber }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.tkcc' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.mbDebitAccount }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.error-code' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.mbErrorCode }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.error-description' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.mbErrorMessage }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.customer-name' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.mbAccountName }}
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.payment-transaction.customer-account' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      {{ record.mbCreditAccount }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</app-po-detail>
