<div class="modal-header">
  <h4 class="modal-title">
    {{ 'feature.editor.section-image.modal-title' | translate }}
  </h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <app-input-file buttonClass="btn btn-dark w-full" (fileSelect)="onImageSelected($event)">
          {{ 'feature.editor.section-image.choose-image' | translate }}
        </app-input-file>
      </div>
    </div>

    <div class="col-12" *ngIf="imageFile != null">
      <div class="form-group image-container">
        <img [src]="imageFile">
      </div>
    </div>

    <div class="col-12">
      <input type="text"
             class="form-control"
             [(ngModel)]="caption"
             [placeholder]="'feature.editor.section-image.image-caption' | translate"/>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn w-xs btn-dark" (click)="ok()">
    {{ 'base.ok' | translate }}
  </button>
  <button class="btn w-xs btn-danger" (click)="cancel()">
    {{ 'base.cancel' | translate }}
  </button>
</div>
