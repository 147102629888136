<input type="text"
       class="form-control"
       [attr.placeholder]="placeholder"
       [disabled]="disabled"
       (input)="handleInput()"
       (focus)="handleFocus()"
       (blur)="handleBlur()"
       (keydown)="handleKeyDown($event)"
       #numericInput />

