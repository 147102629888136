import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';

@Component({
  selector: 'app-form-group-switch',
  templateUrl: './form-group-switch.component.html'
})
export class FormGroupSwitchComponent extends FormGroupAbstractComponent implements OnInit {

  @Input() color = 'default';

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onValueChanged(value): void {
    this.valueChange.emit({fieldKey: this.fieldKey, value: value});
  }

}
