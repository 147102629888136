import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LoadingStatus} from './loading.status.model';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingComponent implements OnInit {

  @Input() loadingStatus = new LoadingStatus();
  @Input() keepContentDOM = false;

  constructor() {
  }

  ngOnInit() {
  }

  isShowError() {
    return this.loadingStatus != null && this.loadingStatus.isError();
  }

  isShowLoading() {
    return this.loadingStatus != null && !this.loadingStatus.isError() && this.loadingStatus.isLoading();
  }

  isShowContent() {
    return !this.loadingStatus.isError() && !this.loadingStatus.isLoading();
  }

  reload() {
    location.reload();
  }

}
