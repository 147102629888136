import {AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';

@Component({
  selector: 'app-form-group-text',
  templateUrl: './form-group-text.component.html'
})
export class FormGroupTextComponent extends FormGroupAbstractComponent implements OnInit, AfterViewInit {

  @Input() password = false;
  @Input() autoFocus = false;
  @Input() placeholder = '';
  @Input() uppercase = false;
  @Output() enterDown = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();

  @ViewChild('inputElement') inputElement: any;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 100);
    }
  }

  onTextChanged(value): void {
    this.valueChange.emit({fieldKey: this.fieldKey, value: value});
  }

  onEnter(event) {
    if (this.enterDown != null) {
      this.enterDown.emit(event);
    }
  }

  isExactMaxLength() {
    const min = this.getValidator().params.min;
    const max = this.getValidator().params.max;
    if (min != null && max != null && min === max) {
      return true;
    }

    return false;
  }

  onBlurMethod() {
    if (this.blur != null) {
      this.blur.emit();
    }
  }

}
