import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {FaskForm, FaskFormBuilder, FaskValidatorFactory} from '../../../../form/fask.form';
import {BeforeLoggedInAbstractComponent} from '../before-logged-in.abstract.component';
import * as _ from 'lodash';
import Utils from '../../../../utils/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent extends BeforeLoggedInAbstractComponent {

  form: FaskForm;
  loginFail = false;
  captchaFail = false;
  captchaSessionId: string;

  constructor(injector: Injector) {
    super(injector);
  }

  protected componentInit2() {
    this.captchaSessionId = null;
    this.createForm();
  }

  onValueChanged() {
    this.loginFail = false;
    this.captchaFail = false;
  }

  getCaptchaImageUrl() {
    return this.utilityService.getApiUrl('authentication/captcha/' + this.captchaSessionId + '/image');
  }

  login() {
    if (this.form.isInvalid()) {
      this.loginFail = true;
      if (this.form.fields['captchaValue'].isInvalid()) {
        this.captchaFail = true;
      }
      return;
    }

    const username = _.toLower(this.form.fields['username'].value.trim());
    const password = this.form.fields['password'].value.trim();
    const captchaValue = this.form.fields['captchaValue'].value.trim();

    this.callLoginApi(
      username,
      password,
      this.captchaSessionId,
      captchaValue,
      (tokenInfo) => {
        this.setTokenInfoAndGoInside(tokenInfo, () => {
          this.loginFail = true;
        });
      },
      (error: any, errorStatus: number, errorCode: string, errorData: any) => {
        this.loginFail = true;
        if (errorCode === 'captcha-invalid' || errorCode === 'need-captcha') {
          this.captchaFail = true;
        }
        this.captchaSessionId = Utils.getFieldSafe(errorData, 'captchaSessionId');
        this.form.fields.captchaValue.validator.required = true;
      }
    );
  }

  onEnter(event) {
    if (event['key'] === 'Enter') {
      this.login();
    }
  }

  // PRIVATE
  private createForm() {
    this.form = FaskFormBuilder.buildForm({
      username: {
        // value: this.utilityService.getLocalStorageItem('username'),
        value: '',
        validator: FaskValidatorFactory.username(true)
      },
      password: {value: '', validator: FaskValidatorFactory.passwordLogin(true)},
      captchaValue: {value: '', validator: FaskValidatorFactory.text(false, 8, 12)}
    });
  }

}
