import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';
import Utils from '../utils/utils';

@Pipe({name: 'isoDate'})
export class IsoDatePipe implements PipeTransform {
  transform(value: string): string {
    const date = Utils.parseIsoTime(value);

    if (date == null) {
      return '';
    }

    return moment(date).format('DD/MM/YYYY');
  }
}
