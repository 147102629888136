<app-po-list [vm]="vm">

  <table class="table" table-content>
    <thead>
    <tr>
      <th>Version</th>
      <th class="w-active-status text-center">{{ 'base.status' | translate }}</th>
      <th class="w-1x"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let record of records" (click)="editRecord(record)">
      <td>{{ record.version }}</td>
      <td class="text-center">
        <app-active-status-label [po]="record"></app-active-status-label>
      </td>
      <td class="arrow-forward">
        <i class="icon ion-ios-arrow-forward"></i>
      </td>
    </tr>
    </tbody>
  </table>

</app-po-list>
