<app-loading [loadingStatus]="vm.loadingStatus">
  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ 'feature.sandbox-apk.create-link.title' | translate }}</h2>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="margin-auto max-w-md">
              <div *ngIf="vm.form" class="row">

                <div class="col-12">
                  <div class="row">

                    <div class="col-12">
                      <app-form-group-number [fieldKey]="'nbHourExpired'"
                                             [form]="vm.form"
                                             [label]="'feature.sandbox-apk.create-link.nbHourExpired'"
                                             [showError]="showFieldError"
                                             [decimals]="0"></app-form-group-number>
                    </div>

                  </div>
                </div>

                <div class="col-12">
                  <button (click)="createLink()" class="btn btn-info w-full m-b">
                    {{ 'feature.sandbox-apk.create-link.create-link' | translate }}
                  </button>
                </div>

                <div class="col-12" *ngIf="!checkEmptyText(fileUrl)">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'feature.sandbox-apk.create-link.link' | translate }}</span>
                    </label>
                    <div class="input-group">
                      <div class="form-control" readonly style="min-height: 38px; height: auto;line-height: inherit;">
                        <span>{{ fileUrl }}</span>
                      </div>
                      <div class="input-group-append">
                        <button (click)="copyLink()" class="btn btn-primary" type="button">
                          <i class="fas fa-copy"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</app-loading>
