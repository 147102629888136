import {Pipe, PipeTransform} from '@angular/core';
import * as numeral from 'numeral';

@Pipe({name: 'confidencePercentage'})
export class ConfidencePercentagePipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }
    return numeral(value * 100).format('0,0.00') + '%';
  }
}
