import {Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

import Utils from '../../utils/utils';
import {PaginationData} from '../utility/pagination/pagination-data';
import {AbstractSimpleComponent} from './abstract.simple.component';

export abstract class AbstractComponent extends AbstractSimpleComponent implements OnInit {

  protected router: Router;
  protected route: ActivatedRoute;

  public queryParams: Params;
  public params: any;
  public id = null;
  public paginationData: PaginationData;

  protected constructor(protected injector: Injector, protected browserTitle: string = 'MBBank') {
    super(injector);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
  }

  protected abstract componentInit(): void;

  ngOnInit() {
    this.logger.debug('NG_ON_INIT', this);

    this.route.queryParams.subscribe((queryParams: Params) => {
      this.logger.debug('queryParams changed', this, queryParams);

      // TODO DONT NEED FOR NOW
      // this.titleService.setTitle(this.translate(this.browserTitle));

      try {
        this.parseParams(queryParams);
        this.componentInit();
      } catch (e) {
        this.logger.error('Cannot parse params from b64.', e);
        this.goTo404();
      }
    });
  }


  // ---------------TO BE OVERRIDE-------------
  // ------------------------------------------
  // ------------------------------------------

  getDefaultBackState() {
    return '';
  }

  getDefaultBackId() {
    return null;
  }

  getDefaultBackParams() {
    return null;
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------


  // ----------------NAVIGATION----------------
  // ------------------------------------------
  // ------------------------------------------
  getRouter() {
    return this.router;
  }

  private parseParams(queryParams: Params) {
    this.queryParams = queryParams;

    // QUERY PARAMS
    if (queryParams.q != null) {
      this.params = Utils.b64ToObject(queryParams.q);
    } else {
      this.params = {};
    }

    // ID
    this.id = queryParams.id;

    // PAGINATION DATA
    this.paginationData = new PaginationData();
    if (this.params['pageSize'] != null && this.params['pageSize'] > 0) {
      this.paginationData.pageSize = this.params['pageSize'];
    }
    if (this.params['pageNumber'] != null && this.params['pageNumber'] > 0) {
      this.paginationData.pageNumber = this.params['pageNumber'];
    }
  }

  getStateQueryParams(insertTimestamp: boolean = true, id?: any, params?: any) {
    const queryParams = {
      id: id
    };

    if (params != null) {
      queryParams['q'] = Utils.objectToB64(params);
      if (queryParams['q'] === 'e30') {
        queryParams['q'] = null;
      }
    }

    if (insertTimestamp) {
      queryParams['t'] = (new Date()).getTime();
    }

    return queryParams;
  }

  goTo(state: string, id?: any, params?: any, replaceUrl?: boolean, relativeTo?: ActivatedRoute) {
    replaceUrl = replaceUrl == null ? false : replaceUrl;
    if (replaceUrl === true) {
      this.utilityService.decrementNbNavigationTimes();
    }

    const queryParams = this.getStateQueryParams(true, id, params);
    this.router.navigate([state], {queryParams: queryParams, relativeTo: relativeTo, replaceUrl: replaceUrl});
  }

  goToNewTab(state: string, id?: any, params?: any, relativeTo?: ActivatedRoute) {
    const queryParams = this.getStateQueryParams(true, id, params);
    let newRelativeUrl = this.router.createUrlTree([state], {
      queryParams: queryParams,
      relativeTo: relativeTo,
    });
    let baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
  }

  reloadState() {
    if (this.utilityService.isHasUnsavedData()) {
      this.utilityService.showConfirmModal('base.unsaved.data.warning.message',
        () => {
          this.utilityService.clearHasUnsavedData();
          this.goTo('.', this.id, this.params, true, this.route);
        });
    } else {
      this.goTo('.', this.id, this.params, true, this.route);
    }
  }

  goTo404() {
    this.utilityService.clearHasUnsavedData();
    this.router.navigate(['404'], {replaceUrl: true});
  }

  reloadData() {
    // DO NOTHING
  }

  refresh() {
    this.reloadState();
  }

  back() {
    if (this.utilityService.getNbNavigationTimes() >= 2) {
      window.history.back();
    } else {
      this.goTo(this.getDefaultBackState(), this.getDefaultBackId(), this.getDefaultBackParams(), true);
    }
  }

  onPageChange(pageRequest) {
    this.params['pageSize'] = pageRequest.pageSize;
    this.params['pageNumber'] = pageRequest.pageNumber;
    this.reloadState();
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------

}
