import {Pipe, PipeTransform} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FrequencyUnit } from 'src/app/business/constant';

@Pipe({name: 'timeUnit'})
export class TimeUnitPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: FrequencyUnit): string {
    if (_.isEmpty(value)) {
      return '';
    }

    switch (value) {
      case FrequencyUnit.Day:
        return this.translateService.instant('base.frequency-unit.DAY');
      case FrequencyUnit.Week:
        return this.translateService.instant('base.frequency-unit.WEEK');
      case FrequencyUnit.Month:
        return this.translateService.instant('base.frequency-unit.MONTH');
      case FrequencyUnit.Year:
        return this.translateService.instant('base.frequency-unit.YEAR');
      default:
        return '';
    }

  }

}
