import {Component, Injector} from '@angular/core';
import {AbstractComponent} from '../../../../../core/components/abstract/abstract.component';
import {
  FaskForm,
  FaskFormBuilder,
  FaskValidatorFactory
} from '../../../../../core/form/fask.form';
import * as _ from 'lodash';
import * as moment from 'moment';
import Utils from '../../../../../core/utils/utils';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-payment-transaction-search',
  templateUrl: './payment-transaction-search.component.html'
})
export class PaymentTransactionSearchComponent extends AbstractComponent {

  vm = this;
  form: FaskForm;
  showFieldError = false;

  statusOptions: any[];
  paymentTypeOptions: any[];
  transactionTypeOptions: any[];
  refundStatusOptions: any[];
  isRefundOption: boolean;
  title = 'feature.payment-transaction.title';

  constructor(injector: Injector) {
    super(injector);
  }

  loadMerchants = (text: string): Observable<any>  => {
    const url = this.getApiUrl('/api/admin/merchant/search');
    return this.httpClient
      .post(url, {
        search: text
      })
      .pipe(
        map((result: any) => {
          _.forEach(result.list, (record) => {
            record.displayValue = record.name + ' (' + record.code + ')';
          });
          return result.list;
        })
      );
  }

  // noinspection JSUnusedLocalSymbols
  onFieldChanged(event: { fieldKey: string; value: any }) {
    if (event.fieldKey === 'transactionType') {
        this.isRefundOption = !this.isRefundOption;
    }
  }

  search() {
    if (!this.checkBeforeAction()) {
      return;
    }

    const params = {
      merchant: this.form.fields['merchant'].value != null ? this.form.fields['merchant'].value : null,
      merchantCode: this.form.fields['merchant'].value != null ? this.form.fields['merchant'].value.code : null,
      paymentTransactionId: this.form.fields['paymentTransactionId'].value,
      cif: this.form.fields['cif'].value,
      status: this.form.fields['status'].value,
      autoDebitId: this.form.fields['autoDebitId'].value,
      paymentType: this.form.fields['paymentType'].value,
      transactionType: this.form.fields['transactionType'].value,
      refundStatus: this.form.fields['refundStatus'].value,
      fromDate: Utils.createIsoDate(this.form.fields['fromDate'].value),
      toDate: Utils.createIsoDate(this.form.fields['toDate'].value)
    };

    this.goTo('payment-transaction-list', null, params);
  }

  protected componentInit() {
    this.showFieldError = false;
    this.statusOptions = [
      {id: this.optionAllId, value: 'base.option.all'},
      ..._.map(this.CONSTANT.PAYMENT_TRANSACTION_STATUS, (status) => {
        return {id: status, value: 'base.payment-transaction-status.' + status};
      })
    ];
    this.paymentTypeOptions = [
      {id: this.optionAllId, value: 'base.option.all'},
      {id: 'IMMEDIATELY', value: 'base.payment-transaction-type.IMMEDIATELY'},
      {id: 'AUTO', value: 'base.payment-transaction-type.AUTO'}
    ];
    this.transactionTypeOptions = [
      {id: 'PAYMENT', value: 'base.transaction.type.payment'},
      {id: 'REFUND', value: 'base.transaction.type.refund'}
    ];
    this.refundStatusOptions = [
      {id: this.optionAllId, value: 'base.option.all'},
      {id: 'FAIL', value: 'base.transaction.type.refund.fail'},
      {id: 'SUCCESS', value: 'base.transaction.type.refund.success'},
      {id: 'PROCESSING', value: 'base.transaction.type.refund.processing'},
      {id: 'NEW', value: 'base.transaction.type.refund.new'},
      {id: 'UNKNOWN', value: 'base.transaction.type.refund.unknown'}
    ];
    this.createForm();
  }

  protected createForm() {
    const status = this.params.status == null ? this.optionAllId : this.params.status;
    const paymentType = this.params.paymentType == null ? this.optionAllId : this.params.paymentType;
    const fromDate = this.params.fromDate == null ? moment(new Date()).startOf('day').toDate() : Utils.parseIsoDate(this.params.fromDate);
    const toDate = this.params.toDate == null ? moment(fromDate).startOf('day').toDate() : Utils.parseIsoDate(this.params.toDate);
    const transactionType = this.params.transactionType == null ? 'PAYMENT' : this.params.transactionType;
    const refundStatus = this.params.refundStatus == null ? this.optionAllId : this.params.refundStatus;

    this.isRefundOption = transactionType === 'REFUND';

    this.form = FaskFormBuilder.buildForm(
      _.assignIn(
        this.createFromToDateFieldConfig(fromDate, toDate),
        {
          merchant: {value: this.params.merchant, validator: FaskValidatorFactory.noRequired()},
          paymentTransactionId: {value: this.params.paymentTransactionId, validator: FaskValidatorFactory.text(false, 1, 30)},
          cif: {value: this.params.cif, validator: FaskValidatorFactory.text(false, 1, 45)},
          autoDebitId: {value: this.params.autoDebitId, validator: FaskValidatorFactory.text(false, 1, 30)},
          mobile: {value: this.params.mobile, validator: FaskValidatorFactory.text(false, 1, 50)},
          status: {value: status, validator: FaskValidatorFactory.noRequired()},
          paymentType: {value: paymentType, validator: FaskValidatorFactory.noRequired()},
          transactionType: {value: transactionType, validator: FaskValidatorFactory.noRequired()},
          refundStatus: {value: refundStatus, validator: FaskValidatorFactory.noRequired()}
        }
      )
    );
  }

  protected checkBeforeAction() {
    if (this.form != null && !this.form.isValid()) {
      this.showFieldError = true;
      return false;
    }

    return true;
  }
}
