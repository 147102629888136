import {Component, Injector} from '@angular/core';
import {PODetailAbstractComponent} from '../../../../../core/components/abstract/po-detail.abstract.component';
import {FaskField, FaskFormBuilder, FaskValidatorFactory} from '../../../../../core/form/fask.form';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-payment-transaction-type-detail',
  templateUrl: './payment-transaction-type-detail.component.html'
})
export class PaymentTransactionTypeDetailComponent extends PODetailAbstractComponent {

  vm = this;

  constructor(injector: Injector) {
    super(injector, 'feature.payment-transaction-type.title', '/api/admin/payment-transaction-type', 'payment-transaction-type-list');
  }

  loadMerchants = (text: string): Observable<any>  => {
    const url = this.getApiUrl('/api/admin/merchant/search');
    if (!this.isNew()) {
      const merchant = this.record.merchant;
      return of([{
        ...merchant,
        displayValue: merchant.name + ' (' + merchant.code + ')',
      }]);
    }
    return this.httpClient
      .post(url, {
        search: text
      })
      .pipe(
        map((result: any) => {
          _.forEach(result.list, (record) => {
            record.displayValue = record.name + ' (' + record.code + ')';
          });
          return result.list;
        })
      );
  }

  protected createForm(): void {
    this.form = FaskFormBuilder.buildForm({
      name: {value: this.record.name, validator: FaskValidatorFactory.text(true, 1, 256)},
      code: {value: this.record.code, validator: FaskValidatorFactory.code(true)},
      allowCard: {value: this.record.allowCard || false, validator: FaskValidatorFactory.required()},
      allowBNPL: {value: this.record.allowBNPL || false, validator: FaskValidatorFactory.required()},
      merchant: {value: this.record.merchant, validator: FaskValidatorFactory.required()},
    });
  }

  protected getBodyToSave(): any {
    const fields: { [key: string]: FaskField } = this.form.fields;
    return {
      name: fields.name.value,
      code: fields.code.value,
      allowCard: fields.allowCard.value,
      allowBNPL: fields.allowBNPL.value,
      merchantId: fields.merchant.value.id
    };
  }

}
