import {AbstractComponent} from '../../abstract/abstract.component';
import {FaskForm} from '../../../form/fask.form';
import {Injector} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import * as _ from 'lodash';

export abstract class BeforeLoggedInAbstractComponent extends AbstractComponent {

  returnUrl = '';

  protected abstract componentInit2(): void;

  protected constructor(injector: Injector) {
    super(injector);
  }

  protected componentInit() {
    this.initReturnUrl();

    this.checkLoginStatus();

    this.componentInit2();
  }

  // PRIVATE
  private initReturnUrl() {
    if (this.route.snapshot.queryParams != null) {
      if (this.route.snapshot.queryParams['ru'] != null) {
        this.returnUrl = this.route.snapshot.queryParams['ru'];
      }
    }
  }

  private checkLoginStatus() {
    this.utilityService.clearSessionData();

    if (this.utilityService.getTokenInfo() == null) {
      return;
    }

    this.utilityService.loadUserInfoFromServer(
      this.loadingStatus,
      (userinfo) => {
        this.utilityService.setUserInfo(userinfo);
        this.getRouter().navigateByUrl(this.returnUrl);
      }
    );
  }

  protected setTokenInfoAndGoInside(tokenInfo, onError?: () => void) {
    this.utilityService.setTokenInfo(tokenInfo);

    this.utilityService.loadUserInfoFromServer(
      this.loadingStatus,
      (userinfo) => {
        this.utilityService.setUserInfo(userinfo);
        this.getRouter().navigateByUrl(this.returnUrl);
      },
      () => {
        if (onError != null) {
          onError();
        }
      }
    );
  }

  protected callLoginApi(username,
                         password,
                         captchaSessionId: string,
                         captchaValue: string,
                         onSuccess: (tokenInfo: any) => void,
                         onFailure?: (error: any, errorStatus: number, errorCode: string, errorData: any) => void) {
    const loginUrl = this.utilityService.getApiUrl('authentication/login');

    this.httpPostOrPut(
      loginUrl,
      {},
      true,
      'login',
      {
        username: username,
        password: password,
        captchaSessionId: captchaSessionId,
        captchaValue: captchaValue
      },
      (tokenInfo) => {
        // this.utilityService.setLocalStorageItem('username', username);
        onSuccess(tokenInfo);
      },
      (error: any, errorStatus: number, errorCode: string, errorData: any) => {
        if (onFailure != null) {
          onFailure(error, errorStatus, errorCode, errorData);
        }
      },
      false
    );
  }

}
