<app-po-list [noFilter]="true" [vm]="vm">

  <table class="table" table-content>
    <thead>
    <tr>
      <th>{{ 'feature.payment-transaction.code' | translate }}</th>
      <th>{{ 'base.created-time' | translate }}</th>
      <th>{{ 'feature.payment-transaction.merchant-code' | translate }}</th>
      <th>{{ 'feature.payment-transaction.cif' | translate }}</th>
<!--      <th *ngIf="!isRefund">{{ 'base.payment-transaction-type.label' | translate }}</th>-->
<!--      <th>{{ 'feature.payment-transaction.auto-debit-id' | translate }}</th>-->
      <th class="text-right">{{ 'feature.payment-transaction.amount' | translate }}</th>
      <th>{{ 'feature.payment-transaction.description' | translate }}</th>
      <th class="w-payment-transaction-status text-center">{{ 'base.status' | translate }}</th>
      <th class="w-1x"></th>
    </tr>
    </thead>
    <tbody>
    <tr (click)="vm.editRecord(record)" *ngFor="let record of vm.records">
      <td *ngIf="!isRefund">{{ record.id }}</td>
      <td *ngIf="isRefund">{{ record.autoDebitId }}</td>
      <td>{{ record.createdTime | isoDateTime }}</td>
      <td>{{ record.merchantCode }}</td>
      <td>{{ record.cif }}</td>
<!--      <td *ngIf="!isRefund">{{ record.billingPeriodId | paymentType }}</td>-->
<!--      <td>{{ record.autoDebitId }}</td>-->
      <td class="text-right">{{ record.amount | myInteger }}</td>
      <td *ngIf="!isRefund">{{ record.description }}</td>
      <td *ngIf="isRefund">{{ record.mbRefundDescription }}</td>
      <td class="text-center">
        <app-payment-transaction-status-label *ngIf="!isRefund"  [status]="record.status"></app-payment-transaction-status-label>
        <app-payment-transaction-status-label *ngIf="isRefund" [status]="record.refundTransactionStatus"></app-payment-transaction-status-label>
      </td>
      <td class="arrow-forward">
        <i class="icon ion-ios-arrow-forward"></i>
      </td>
    </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
        <td>
        <td>
        <td class="font-weight-bold">{{ 'base.total' | translate }}</td>
        <td class="text-right font-weight-bold">{{paginationData.totalAmount | myInteger}}</td>
      </tr>
    </tfoot>
  </table>

</app-po-list>

