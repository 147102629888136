import {Component, Injector} from '@angular/core';
import {AbstractComponent} from '../../abstract/abstract.component';
import Menu from '../../../../business/menu';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent extends AbstractComponent {

  constructor(injector: Injector) {
    super(injector);
  }

  protected componentInit() {
    this.loadingStatus.setLoading('loading', true);
    this.navigateToHomeState();
  }

  private navigateToHomeState() {
    const userInfo = this.getUserInfo();

    for (const menuGroup of Menu.MENU_GROUPS) {
      if (!menuGroup.isValidUserInfo(userInfo)) {
        continue;
      }

      for (const menu of menuGroup.menus) {
        if (!menu.isValidUserInfo(userInfo)) {
          continue;
        }

        if (menu.hasChild()) {
          for (const subMenu of menu.subMenus) {
            if (!subMenu.isValidUserInfo(userInfo)) {
              continue;
            }

            this.router.navigate([subMenu.state], {replaceUrl: true});
            return;
          }
        } else {
          this.router.navigate([menu.state], {replaceUrl: true});
          return;
        }
      }
    }

    this.goTo404();
  }

}
