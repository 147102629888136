import {Directive, Input, Output, EventEmitter, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, timer} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';

@Directive({
  selector: '[appCounter]'
})
export class CounterDirective implements OnChanges, OnInit, OnDestroy {

  private _counterSource$ = new Subject<any>();
  private _subscription: any;
  private _restartEventSubscription: any;

  @Input() appCounter: number;
  @Input() appCounterInterval: number;
  @Output() appCounterOnChange = new EventEmitter<number>();
  @Input() restartEvent: Observable<void>;

  constructor() {
    this.start();
  }

  ngOnInit() {
    if (this.restartEvent != null) {
      this._restartEventSubscription = this.restartEvent.subscribe(() => this.start());
    }
  }

  ngOnDestroy() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }

    if (this._restartEventSubscription != null) {
      this._restartEventSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this._counterSource$.next({count: this.appCounter, interval: this.appCounterInterval});
  }

  // PRIVATE
  private start() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }

    this._subscription = this._counterSource$.pipe(
      switchMap(({interval, count}) =>
        timer(0, interval).pipe(
          take(count),
          tap(() => {
            this.appCounterOnChange.emit(--count);
          })
        )
      )
    ).subscribe();
  }

}
