import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({name: 'fullnameInitial'})
export class FullnameInitialPipe implements PipeTransform {

  transform(_value: string): string {
    if (_.isEmpty(_value.trim())) {
      return '#';
    }

    let value = _value.trim();

    value = _.last(_.words(value));

    value = value.substring(0, 1);

    value = _.toUpper(value);

    return value;
  }

}
