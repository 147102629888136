import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-search-date-range',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search-date-range.component.html',
  styleUrls: ['./search-date-range.component.scss']
})
export class SearchDateRangerComponent implements OnInit {

  @Input() placeholder = 'base.search.placeholder';

  // @Input() locale = 'vi';
  @Input() set searchDate(value: object) {
    if (value && typeof(value[0]) === 'string') {
      value[0] = new Date(value[0]);
      value[1] = new Date(value[1]);
    }
    this.searchTextOriginal = value;
    this.mySearchDate = value;

  }

  isOpen = false;

  get searchDate(): object {
    return this.mySearchDate;
  }

  @Output() searchPerform = new EventEmitter<object>();
  @Output() clearDate = new EventEmitter<object>();
  @Output() searchDateChange = new EventEmitter<object>();

  searchTextOriginal: object;
  mySearchDate: object;

  constructor() {
  }

  ngOnInit() {
  }

  isShowClearBtn() {
    return (this.mySearchDate != null)
      &&
      (this.searchTextOriginal != null) &&
      this.searchTextOriginal === this.mySearchDate;
  }

  onValueChange($event) {
    if ($event) {
      this.searchDate = $event;
      this.searchPerform.emit(this.mySearchDate);
      this.searchDateChange.emit(this.mySearchDate);
    }
  }

  onSearchBtnClick() {
    // this.searchPerform.emit(this.mySearchDate);
  }

  onClearBtnClick() {
    this.mySearchDate = null;
    this.searchPerform.emit(this.mySearchDate);
    this.clearDate.emit(this.mySearchDate);
  }

  onEnter(event) {
    if (event['key'] === 'Enter') {
      if (!this.isShowClearBtn()) {
        this.onSearchBtnClick();
      }
    }
  }

}
