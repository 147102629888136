import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as _ from 'lodash';
import * as $ from 'jquery';
import {ConfirmModalComponent} from '../../components/utility/confirm-modal/confirm-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {SettingsService} from '../../settings/settings.service';
import {Settings} from '../../settings/settings';
import * as moment from 'moment';
import sha256 from 'sha256';
import {LoadingStatus} from '../../components/utility/loading/loading.status.model';
import {HttpClient} from '@angular/common/http';
import DurationConstructor = moment.unitOfTime.DurationConstructor;
import {EkycStatus, SocketIoHolder, SocketServerNotification} from '../socket-io-holder';
import {Subscription} from 'rxjs';

@Injectable()
export class UtilityService {

  private readonly mobile: boolean;
  private nbNavigationTimes: number;
  private tokenInfo: any;
  private userInfo: any;
  private hasUnsavedData: boolean;
  private socketIoHolder: SocketIoHolder;

  public userInfoChange: EventEmitter<any> = new EventEmitter();

  constructor(private deviceService: DeviceDetectorService,
              private router: Router,
              private translateService: TranslateService,
              private modalService: BsModalService,
              private logger: NGXLogger,
              private toastr: ToastrService,
              private settingsService: SettingsService,
              private httpClient: HttpClient) {
    // CHECK IF MOBILE
    this.mobile = false;

    const deviceInfo = this.deviceService.getDeviceInfo();
    console.log(deviceInfo);
    this.mobile = this.deviceService.isMobile();

    // INIT SESSION DATA
    this.clearSessionData();

    this.socketIoHolder = new SocketIoHolder();
  }

  public clearSessionData() {
    this.nbNavigationTimes = 0;
    this.clearHasUnsavedData();
    this.clearUserInfo();
  }

  public getSettings(): Settings {
    if (!this.settingsService.settings.loaded) {
      this.logError('Settings not loaded');
      return null;
    }

    return this.settingsService.settings;
  }

  public getApiUrl(path: string): string {
    if (!_.startsWith(path, '/')) {
      path = '/' + path;
    }

    return this.getSettings().apiBaseUrl + path;
  }

  // ----------------USER INFO-----------------
  // ------------------------------------------
  // ------------------------------------------
  public getTokenInfo() {
    if (this.tokenInfo != null) {
      return this.tokenInfo;
    }
    this.tokenInfo = this.getLocalStorageItem('token_info');
    return this.tokenInfo;
  }

  public setTokenInfo(tokenInfo) {
    this.tokenInfo = tokenInfo;

    if (this.tokenInfo != null) {
      this.setLocalStorageItem('token_info', this.tokenInfo, this.tokenInfo.expires_in, 'seconds');
    } else {
      this.setLocalStorageItem('token_info', null);
    }
  }

  public clearTokenInfo() {
    this.setTokenInfo(null);
  }

  public loadUserInfoFromServer(loadingStatus: LoadingStatus,
                                onSuccess: (value: any) => void,
                                onFailure?: (error: any) => void): void {
    const loadingKey = 'userInfo';

    if (loadingStatus != null) {
      loadingStatus.setLoading(loadingKey, true);
    }

    const onNext = (data) => {
      onSuccess(data);
      if (loadingStatus != null) {
        loadingStatus.setLoading(loadingKey, false);
      }

      this.loginToSocket(data.socketToken);
    };

    const onError = (error) => {
      // UNAUTHORIZED
      if (error != null && error.status === 401) {
        if (onFailure != null) {
          onFailure(error);
        }
      } else {
        this.clearHasUnsavedData();
        this.router.navigate(['500'], {replaceUrl: true});
      }

      this.logError('GET USERINFO.', error);

      if (loadingStatus != null) {
        loadingStatus.setLoading(loadingKey, false);
      }
    };

    this.httpClient.get<any>(
      this.getApiUrl('/authentication/userinfo'),
      {
        observe: 'body',
        responseType: 'json',
        headers: {'Content-Type': 'application/json;charset=UTF-8'},
        params: {}
      }
    ).subscribe(onNext, onError);
  }

  public getUserInfo() {
    return this.userInfo;
  }

  public setUserInfo(userInfo) {
    this.userInfo = userInfo;
    this.userInfoChange.emit(this.userInfo);
  }

  public clearUserInfo() {
    this.setUserInfo(null);
  }

  public getRole() {
    return this.getUserInfo() == null ? '' : this.getUserInfo().role;
  }

  public getCurrentLanguage() {
    return this.translateService.currentLang;
  }

  public changeLanguage(lang: string) {
    this.translateService.use(lang);

    localStorage.setItem('default_lang', lang);
  }

  public getDefaultLanguage() {
    const lang = localStorage.getItem('default_lang');
    if (!_.isEmpty(lang)) {
      return lang;
    }

    return 'vi';
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------


  // ----------------UNSAVED-------------------
  // ------------------------------------------
  // ------------------------------------------

  public isHasUnsavedData(): boolean {
    return this.hasUnsavedData;
  }

  public clearHasUnsavedData(): void {
    this.hasUnsavedData = false;
    $(window).off('beforeunload');
  }

  public setHasUnsavedData(): void {
    this.hasUnsavedData = true;
    $(window).on('beforeunload', () => {
      return 'leave?';
    });
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------


  // ----------NB NAVIGATION TIMES-------------
  // ------------------------------------------
  // ------------------------------------------

  public getNbNavigationTimes() {
    return this.nbNavigationTimes;
  }

  public incrementNbNavigationTimes() {
    this.nbNavigationTimes++;
  }

  public decrementNbNavigationTimes() {
    this.nbNavigationTimes--;
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------


  // ----------SOCKET -------------------------
  // ------------------------------------------
  // ------------------------------------------

  private loginToSocket(token: string) {
    // this.socketIoHolder.login(this.settingsService.settings.socketServerUrl, token);
  }

  public subscribeServerNotification(next: (value: SocketServerNotification) => void): Subscription {
    return this.socketIoHolder.subscribeServerNotification(next);
  }

  public emitEkycStaffStatus(status: EkycStatus) {
    return this.socketIoHolder.emitEkycStaffStatus(status);
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------

  // ----------------UTILS---------------------
  // ------------------------------------------
  // ------------------------------------------

  public getCurrentRoute(url?: string) {
    url = url == null ? this.router.url : url;
    const routesSplitted = _.split(_.split(url, '?', 1)[0], '/', 2);
    if (routesSplitted != null && routesSplitted.length > 1) {
      return routesSplitted[1];
    }

    return '';
  }

  public isMobile(): boolean {
    return this.mobile;
  }

  public translate(key: string | Array<string>, interpolateParams?: Object): string | any {
    return this.translateService.instant(key, interpolateParams);
  }

  public showConfirmModal(message: string,
                          acceptFn: (textAreaValue?: string) => void,
                          cancelFn?: () => void,
                          title?: string,
                          acceptBtnName?: string,
                          cancelBtnName?: string,
                          reverseBtnColor?: boolean,
                          useTextArea?: boolean,
                          textAreaLabel?: string,
                          textAreaRequired ?: boolean,
                          textAreaLengthMin?: number,
                          textAreaLengthMax?: number) {
    const initialState = {
      title: this.translate(title == null ? 'base.confirm.modal.title' : title),
      acceptBtnName: this.translate(acceptBtnName == null ? 'base.confirm.modal.btn.accept' : acceptBtnName),
      cancelBtnName: this.translate(cancelBtnName == null ? 'base.confirm.modal.btn.cancel' : cancelBtnName),
      message: this.translate(message),
      acceptFn: acceptFn,
      cancelFn: cancelFn,
      reverseBtnColor: reverseBtnColor == null ? false : reverseBtnColor,
      useTextArea: useTextArea == null ? false : useTextArea,
      textAreaLabel: textAreaLabel == null ? 'base.note' : textAreaLabel,
      textAreaRequired: textAreaRequired == null ? false : textAreaRequired,
      textAreaLengthMin: textAreaLengthMin == null ? 1 : textAreaLengthMin,
      textAreaLengthMax: textAreaLengthMax == null ? 512 : textAreaLengthMax
    };
    this.modalService.show(ConfirmModalComponent, {initialState: initialState, backdrop: 'static'});
  }

  public showInfoModal(message: string,
                       title?: string,
                       additionalMessageList?: string[],
                       onFn?: () => void
  ) {
    const additionalMessageTranslatedList = [];
    if (!_.isEmpty(additionalMessageList)) {
      for (const additionalMessage of additionalMessageList) {
        additionalMessageTranslatedList.push(this.translate(additionalMessage));
      }
    }

    const initialState = {
      title: this.translate(title == null ? 'base.confirm.modal.title' : title),
      acceptBtnName: this.translate('base.ok'),
      cancelBtnName: null,
      showAcceptBtn: true,
      showCancelBtn: false,
      message: this.translate(message),
      additionalMessageList: additionalMessageTranslatedList,
      acceptFn: onFn,
      cancelFn: null
    };
    this.modalService.show(
      ConfirmModalComponent,
      {initialState: initialState, class: 'modal-sm', backdrop: 'static'}
    );
  }

  public logError(message: any, ...additional: any[]) {
    this.logger.error(message, additional);
  }

  public logInfo(message: any, ...additional: any[]) {
    this.logger.info(message, additional);
  }

  public showInfoToast(messageCode: string, params?: any) {
    if (!_.isEmpty(messageCode)) {
      this.toastr.info(this.translate(messageCode, params));
    }
  }

  public showErrorToast(messageCode: string, params?: any) {
    if (!_.isEmpty(messageCode)) {
      this.toastr.error(this.translate(messageCode, params));
    }
  }

  public setLocalStorageItem(key: string, value: any, expiredIn?: number, expiredInUnit?: DurationConstructor) {
    if (key == null) {
      return;
    }

    if (value == null) {
      window.localStorage.removeItem(key);
      return;
    }

    let expiredAt = null;
    if (expiredIn != null && expiredInUnit != null) {
      expiredAt = moment().add(expiredIn, expiredInUnit).toDate();
    }

    window.localStorage.setItem(key, JSON.stringify({value: value, expiredAt: expiredAt}));
  }

  public getLocalStorageItem(key: string) {
    if (key == null) {
      return null;
    }

    const json = window.localStorage.getItem(key);
    if (json == null) {
      return null;
    }

    const object = JSON.parse(json);
    if (object != null && object.expiredAt != null && moment().isAfter(object.expiredAt)) {
      window.localStorage.removeItem(key);
      return null;
    }

    return object == null ? null : object.value;
  }

  public encodePassword(rawPassword: string): string {
    return sha256(rawPassword);
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------

}
