import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-editor-section-image-modal',
  templateUrl: './editor-section-image-modal.component.html',
  styleUrls: ['./editor-section-image-modal.component.scss']
})
export class EditorSectionImageModalComponent implements OnInit {

  imageKey: any;
  imageFile: any;
  caption: string;
  okFn: (imageKey: any, imageFile: any, imageCaption?: string) => void;

  constructor(private bsModalRef: BsModalRef) {
  }

  ngOnInit() {
  }

  onImageSelected(fileModel) {
    this.imageKey = null;
    this.imageFile = fileModel.file;
  }

  ok() {
    this.bsModalRef.hide();
    if (this.okFn != null) {
      this.okFn(this.imageKey, this.imageFile, this.caption);
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

}
