<div class="modal-header">
  <h4 class="modal-title">
    {{ 'feature.editor.section-youtube.modal-title' | translate }}
  </h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="form-control-label">
          {{ 'feature.editor.section-youtube.youtube-url' | translate }}
        </label>
        <input type="text"
               class="form-control"
               [(ngModel)]="youtubeUrl"/>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label class="form-control-label">
          {{ 'feature.editor.section-youtube.youtube-caption' | translate }}
        </label>
        <input type="text"
               class="form-control"
               [(ngModel)]="caption"/>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn w-xs btn-dark" (click)="ok()">
    {{ 'base.ok' | translate }}
  </button>
  <button class="btn w-xs btn-danger" (click)="cancel()">
    {{ 'base.cancel' | translate }}
  </button>
</div>
