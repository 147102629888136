<div class="pagination-lite">
  <div class="pagination-display">
    <span class="font-weight-bold">{{ getFrom() | myInteger }}&nbsp;-&nbsp;{{ getTo() | number:'1.0-0' }}</span>
    &nbsp;{{'base.pagination.of' | translate }}&nbsp;
    <span class="font-weight-bold">{{ getTotal() | myInteger }}</span>
  </div>

  <button type="button" class="btn btn-white no-border" *ngIf="getFrom() > 1 || getTo() < getTotal()" [disabled]="!canPrevious()" (click)="previous()">
    <span class="icon ion-2x ion-ios-arrow-back"></span>
  </button>

  <button type="button" class="btn btn-white no-border" *ngIf="getFrom() > 1 || getTo() < getTotal()" [disabled]="!canNext()" (click)="next()">
    <span class="icon ion-2x ion-ios-arrow-forward"></span>
  </button>
</div>
