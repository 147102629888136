<div class="form-group" [ngClass]="{ 'has-danger': isAddDangerClass() }" *ngIf="getField() != null">

  <label class="form-control-label"  *ngIf="!checkEmptyText(label)">
    {{ label | translate }}<span *ngIf="getValidator().required">*</span>
  </label>

  <textarea [rows]="rows"
            class="form-control"
            [(ngModel)]="getField().value"
            (ngModelChange)="onTextChanged($event)"
            [disabled]="fieldDisabled || fieldReadonly"
            [placeholder]="placeholder | translate"></textarea>

  <div class="form-control-feedback" *ngIf="isShowTextError()">

    <span *ngIf="useDefaultErrorMessage()">
      <span *ngIf="getValidator().required">{{ 'form.error.message.text-required' | translate:getValidator().params }}</span>
      <span *ngIf="!getValidator().required">{{ 'form.error.message.text' | translate:getValidator().params }}</span>
    </span>

    <span *ngIf="!useDefaultErrorMessage()">
      {{ errorMessage | translate:getValidator().params }}
    </span>

  </div>

</div>
