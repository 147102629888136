import {Component, Injector, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FaskForm, FaskFormBuilder, FaskValidatorFactory} from '../../../../../core/form/fask.form';
import {AbstractSimpleComponent} from '../../../../../core/components/abstract/abstract.simple.component';

@Component({
  selector: 'app-payment-transaction-type-modal',
  templateUrl: './payment-transaction-type-modal.component.html'
})
export class PaymentTransactionTypeModalComponent extends AbstractSimpleComponent implements OnInit {

  title: string;
  paymentTransactionType: any;
  paymentTransactionTypeIndex?: number;
  acceptBtnName: string;
  cancelBtnName: string;
  useAutoDebitFlag: boolean;
  useAutoDebitBlockFlag: boolean;
  acceptFn: (paymentTransactionType: any, paymentTransactionTypeIndex?: number) => boolean;
  cancelFn: () => void;

  public form: FaskForm;
  public showFieldError: boolean;
  public rejectReasons: { key: string, selected: boolean }[] = [];
  autoDebitTypeOptions: any[];

  constructor(injector: Injector, private bsModalRef: BsModalRef) {
    super(injector);
  }

  ngOnInit() {
    this.useAutoDebitBlockFlag = this.paymentTransactionType.autoDebit;
    this.useAutoDebitFlag = this.paymentTransactionType.useAutoDebitFlag;
    this.form = FaskFormBuilder.buildForm({
      name: {value: this.paymentTransactionType.name, validator: FaskValidatorFactory.text(true, 1, 256)},
      code: {value: this.paymentTransactionType.code, validator: FaskValidatorFactory.code(true)},
      allowCard: {
        value: this.paymentTransactionType.allowCard == null ? true : this.paymentTransactionType.allowCard,
        validator: FaskValidatorFactory.required(true)
      },
      allowBNPL: {
        value: this.paymentTransactionType.allowBNPL == null ? false : this.paymentTransactionType.allowBNPL,
        validator: FaskValidatorFactory.required(true)
      },
      autoDebit: {
        value: this.paymentTransactionType.autoDebit == null ? false : this.paymentTransactionType.autoDebit,
        validator: FaskValidatorFactory.required(true)
      },
      allowVoucher: {
        value: this.paymentTransactionType.allowVoucher == null ? false : this.paymentTransactionType.allowVoucher,
        validator: FaskValidatorFactory.noValidator()
      },
      allowLoyalty: {
        value: this.paymentTransactionType.allowLoyalty == null ? false : this.paymentTransactionType.allowLoyalty,
        validator: FaskValidatorFactory.noValidator()
      },
      autoDebitType: {
        value: this.paymentTransactionType.autoDebitType == null ? "NONE" : this.paymentTransactionType.autoDebitType,
        validator: FaskValidatorFactory.noValidator()
      },
      retryDay: {
        value: this.paymentTransactionType.retryDay,
        validator: FaskValidatorFactory.noValidator()
      }
    });
    console.log("init: " + this.useAutoDebitBlockFlag)
    this.showFieldError = false;
    this.autoDebitTypeOptions = [
      {id: "NONE", value: 'NONE'},
      {id: "TOPUP", value: 'TOPUP'},
      {id: 'BILLING_RECEIVE_BILLS', value: 'BILLING_RECEIVE_BILLS'},
      {id: 'BILLING_GET_BILLS', value: 'BILLING_GET_BILLS'}
    ];
  }

  isDraft() {
    return this.paymentTransactionType.id == null;
  }

  // noinspection JSUnusedLocalSymbols
  onFieldChanged(event: { fieldKey: string; value: any }) {
  }

  // Change auto debit flag
  onFieldAutoDebitChanged(event: { fieldKey: string; value: any }) {
    this.useAutoDebitBlockFlag = !this.useAutoDebitBlockFlag;
  }

  accept() {
    if (this.form.isInvalid()) {
      this.showFieldError = true;
      return;
    }

    if (this.acceptFn != null) {
      const fields = this.form.fields;
      const valid = this.acceptFn({
        code: fields['code'].value,
        name: fields['name'].value,
        allowCard: fields['allowCard'].value,
        allowBNPL: fields['allowBNPL'].value,
        autoDebit: fields['autoDebit'].value,
        allowVoucher: fields['allowVoucher'].value,
        allowLoyalty: fields['allowLoyalty'].value,
        autoDebitType: fields['autoDebitType'].value == 'NONE' ? null : fields['autoDebitType'].value,
        retryDay: fields['retryDay'].value
      }, this.paymentTransactionTypeIndex);
      if (valid) {
        this.bsModalRef.hide();
      }
    } else {
      this.bsModalRef.hide();
    }
  }

  cancel() {
    this.bsModalRef.hide();
    if (this.cancelFn != null) {
      this.cancelFn();
    }
  }

}
