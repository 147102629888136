import {Component, Injector, OnInit} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';

@Component({
  selector: 'app-form-group-checkbox',
  templateUrl: './form-group-checkbox.component.html'
})
export class FormGroupCheckboxComponent extends FormGroupAbstractComponent implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onValueChanged(value): void {
    this.valueChange.emit({fieldKey: this.fieldKey, value: value});
  }

}
