import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';
import {BehaviorSubject, Observable, of} from 'rxjs';

@Component({
  selector: 'app-form-group-combobox',
  templateUrl: './form-group-combobox.component.html'
})
export class FormGroupComboboxComponent extends FormGroupAbstractComponent implements OnInit, OnDestroy {

  @Input() itemList: any[];
  @Input() itemKeyField = 'id';
  @Input() itemDisplayField = 'name';
  @Input() defaultDisplayValue = null;
  @Input() needTranslate = false;
  @Input() itemListChangeSubject: BehaviorSubject<any[]>;
  private itemListChangeSubscription: any;

  useFirstSelect = false;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    if (this.itemListChangeSubject != null) {
      this.itemListChangeSubscription = this.itemListChangeSubject.subscribe(
        (newList: any[]) => {
          this.itemList = newList;
          this.reset();
        });
    }
  }

  ngOnDestroy() {
    if (this.itemListChangeSubscription != null) {
      this.itemListChangeSubscription.unsubscribe();
    }
  }

  onValueChanged(value): void {
    this.valueChange.emit({fieldKey: this.fieldKey, value: value});
  }

  getItemKey(item) {
    return item[this.itemKeyField];
  }

  getItemDisplay(item) {
    if (this.needTranslate) {
      return this.translate(item[this.itemDisplayField]);
    }

    return item[this.itemDisplayField];
  }

  reset() {
    this.useFirstSelect = !this.useFirstSelect;
  }

}
