<span class="label label-warning" *ngIf="status === 'NEW'">
  {{ 'base.auto-debit-status.NEW' | translate }}
</span>
<span class="label label-info" *ngIf="status === 'VERIFIED'">
  {{ 'base.auto-debit-status.VERIFIED' | translate }}
</span>
<span class="label label-success" *ngIf="status === 'CONFIRMED'">
  {{ 'base.auto-debit-status.CONFIRMED' | translate }}
</span>
<span class="label label-danger" *ngIf="status === 'CANCELLED'">
  {{ 'base.auto-debit-status.CANCELLED' | translate }}
</span>
<span class="label label-primary" *ngIf="status === 'EXPIRED'">
  {{ 'base.auto-debit-status.EXPIRED' | translate }}
</span>

<span class="label label-info" *ngIf="status === 'REGISTER'">
  {{ 'base.auto-debit-status.REGISTER' | translate }}
</span>

<span class="label label-warning" *ngIf="status === 'REGISTER_PENDING'">
  {{ 'base.auto-debit-status.REGISTER_PENDING' | translate }}
</span>

<span class="label label-info" *ngIf="status === 'REGISTER_VERIFY'">
  {{ 'base.auto-debit-status.REGISTER_VERIFY' | translate }}
</span>

<span class="label label-success" *ngIf="status === 'REGISTED'">
  {{ 'base.auto-debit-status.REGISTED' | translate }}
</span>

<span class="label label-info" *ngIf="status === 'UPDATE'">
  {{ 'base.auto-debit-status.UPDATE' | translate }}
</span>

<span class="label label-warning" *ngIf="status === 'UPDATE_PENDING'">
  {{ 'base.auto-debit-status.UPDATE_PENDING' | translate }}
</span>

<span class="label label-info" *ngIf="status === 'UPDATE_VERIFY'">
  {{ 'base.auto-debit-status.UPDATE_VERIFY' | translate }}
</span>

<span class="label label-info" *ngIf="status === 'CANCEL'">
  {{ 'base.auto-debit-status.CANCEL' | translate }}
</span>

<span class="label label-warning" *ngIf="status === 'CANCEL_PENDING'">
  {{ 'base.auto-debit-status.CANCEL_PENDING' | translate }}
</span>

<span class="label label-info" *ngIf="status === 'CANCEL_VERIFY'">
  {{ 'base.auto-debit-status.CANCEL_VERIFY' | translate }}
</span>

<span class="label label-danger" *ngIf="status === 'FAIL'">
  {{ 'base.auto-debit-status.FAIL' | translate }}
</span>
