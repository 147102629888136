<div [ngClass]="{ 'mobile': isMobile(), 'opened': sidebarOpened, 'fullscreen': isFullscreenState(), 'loading': isLoading() }">

  <!--Loading-->
  <div id="loading-wrapper" class="loading-component-white" *ngIf="isLoading()">
    <div id="loading-container">
      <div class="sk-fading-circle">
        <div class="sk-circle1 sk-circle"></div>
        <div class="sk-circle2 sk-circle"></div>
        <div class="sk-circle3 sk-circle"></div>
        <div class="sk-circle4 sk-circle"></div>
        <div class="sk-circle5 sk-circle"></div>
        <div class="sk-circle6 sk-circle"></div>
        <div class="sk-circle7 sk-circle"></div>
        <div class="sk-circle8 sk-circle"></div>
        <div class="sk-circle9 sk-circle"></div>
        <div class="sk-circle10 sk-circle"></div>
        <div class="sk-circle11 sk-circle"></div>
        <div class="sk-circle12 sk-circle"></div>
      </div>
    </div>
  </div>

  <!-- Sidebar  -->
  <button id="left-sidebar-underlay" (click)="closeSidebar()"></button>
  <app-sidebar id="left-sidebar-container" *ngIf="!isFullscreenState() && !isLoading()"></app-sidebar>

  <!-- Right Container  -->
  <div id="right-container-wrapper" *ngIf="!isLoading()">
    <div id="right-container">
      <app-header id="header" (openSidebarEventEmitter)="openSidebar()" *ngIf="!isFullscreenState()"></app-header>

      <div id="content-container-wrapper">
        <div id="content-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>

</div>



