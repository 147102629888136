import {Component, Injector} from '@angular/core';
import {AbstractComponent} from '../../../../core/components/abstract/abstract.component';
import {FaskField, FaskForm, FaskFormBuilder, FaskValidatorFactory} from '../../../../core/form/fask.form';

@Component({
  selector: 'app-business-config',
  templateUrl: './business-config.component.html'
})
export class BusinessConfigComponent extends AbstractComponent {

  public title: string;
  public record: any;
  public form: FaskForm;
  public showFieldError: boolean;

  constructor(injector: Injector) {
    super(injector, 'feature.business-config.title');

    this.title = 'feature.business-config.title';
  }

  protected componentInit() {
    this.utilityService.clearHasUnsavedData();
    this.record = {};
    this.showFieldError = false;

    this.reloadData();
  }

  reloadData() {
    this.record = {};

    const url = this.getApiUrl('/api/admin/business-config');

    this.httpGet(url,
      null,
      'data',
      (response) => {
        this.record = response;
        this.createForm();
      }
    );
  }

  isCanSave() {
    return this.utilityService.isHasUnsavedData();
  }

  onFieldChanged(_event: { fieldKey: string, value: any }) {
    this.utilityService.setHasUnsavedData();
  }

  save() {
    if (this.form != null && !this.form.isValid()) {
      this.showFieldError = true;
      return;
    }

    const url = this.getApiUrl('/api/admin/business-config');
    const body = this.getBodyToSave();

    this.httpPostOrPut(
      url,
      {},
      true,
      'save', body, () => {
        this.utilityService.clearHasUnsavedData();
      }
    );
  }

  createForm(): void {
    const globalInitRateLimitEnabled = this.record.globalInitRateLimit != null;
    const merchantInitRateLimitEnabled = this.record.merchantInitRateLimit != null;
    if (this.record.exceptionTestConfig == null) {
      this.record.exceptionTestConfig = {
        merchantCode: null,
        loginTokenCreate400: false,
        loginTokenVerify400: false,
        paymentVerify400: false,
        paymentVerify500: false,
        paymentVerifyTimeout: false,
        paymentMarkAsPaid400: false,
        paymentMarkAsPaid500: false,
        paymentMarkAsPaidTimeout: false,
        autoDebitVerify400: false,
        autoDebitVerify500: false,
        autoDebitVerifyTimeout: false,
        autoDebitMarkAsConfirmed400: false,
        autoDebitMarkAsConfirmed500: false,
        autoDebitMarkAsConfirmedTimeout: false,
      };
    }
    this.form = FaskFormBuilder.buildForm({
      globalInitRateLimitEnabled: {
        value: globalInitRateLimitEnabled,
        validator: FaskValidatorFactory.required()
      },
      globalInitRateLimitInterval: {
        value: globalInitRateLimitEnabled ? this.record.globalInitRateLimit.interval : null,
        validator: FaskValidatorFactory.number(globalInitRateLimitEnabled, 1),
        requiredDependingKey: 'globalInitRateLimitEnabled'
      },
      globalInitRateLimitNbRequests: {
        value: globalInitRateLimitEnabled ? this.record.globalInitRateLimit.nbRequests : null,
        validator: FaskValidatorFactory.number(globalInitRateLimitEnabled, 1),
        requiredDependingKey: 'globalInitRateLimitEnabled'
      },
      merchantInitRateLimitEnabled: {
        value: merchantInitRateLimitEnabled,
        validator: FaskValidatorFactory.required()
      },
      merchantInitRateLimitInterval: {
        value: merchantInitRateLimitEnabled ? this.record.merchantInitRateLimit.interval : null,
        validator: FaskValidatorFactory.number(merchantInitRateLimitEnabled, 1),
        requiredDependingKey: 'merchantInitRateLimitEnabled'
      },
      merchantInitRateLimitNbRequests: {
        value: merchantInitRateLimitEnabled ? this.record.merchantInitRateLimit.nbRequests : null,
        validator: FaskValidatorFactory.number(merchantInitRateLimitEnabled, 1),
        requiredDependingKey: 'merchantInitRateLimitEnabled'
      },
      defaultSuccessMessage: {
        value: this.record.defaultSuccessMessage,
        validator: FaskValidatorFactory.text(true, 1, 2000)
      },
      exceptionTestConfigMerchantCode: {
        value: this.record.exceptionTestConfig.merchantCode,
        validator: FaskValidatorFactory.text(false, 1, 100)
      },
      exceptionTestConfigLoginTokenCreate400: {
        value: this.record.exceptionTestConfig.loginTokenCreate400,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigLoginTokenVerify400: {
        value: this.record.exceptionTestConfig.loginTokenVerify400,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigPaymentVerify400: {
        value: this.record.exceptionTestConfig.paymentVerify400,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigPaymentVerify500: {
        value: this.record.exceptionTestConfig.paymentVerify500,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigPaymentVerifyTimeout: {
        value: this.record.exceptionTestConfig.paymentVerifyTimeout,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigPaymentMarkAsPaid400: {
        value: this.record.exceptionTestConfig.paymentMarkAsPaid400,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigPaymentMarkAsPaid500: {
        value: this.record.exceptionTestConfig.paymentMarkAsPaid500,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigPaymentMarkAsPaidTimeout: {
        value: this.record.exceptionTestConfig.paymentMarkAsPaidTimeout,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigAutoDebitVerify400: {
        value: this.record.exceptionTestConfig.autoDebitVerify400,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigAutoDebitVerify500: {
        value: this.record.exceptionTestConfig.autoDebitVerify500,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigAutoDebitVerifyTimeout: {
        value: this.record.exceptionTestConfig.autoDebitVerifyTimeout,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigAutoDebitMarkAsConfirmed400: {
        value: this.record.exceptionTestConfig.autoDebitMarkAsConfirmed400,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigAutoDebitMarkAsConfirmed500: {
        value: this.record.exceptionTestConfig.autoDebitMarkAsConfirmed500,
        validator: FaskValidatorFactory.required()
      },
      exceptionTestConfigAutoDebitMarkAsConfirmedTimeout: {
        value: this.record.exceptionTestConfig.autoDebitMarkAsConfirmedTimeout,
        validator: FaskValidatorFactory.required()
      },
    });
  }

  getBodyToSave(): any {
    const fields: { [key: string]: FaskField } = this.form.fields;
    return {
      globalInitRateLimit: fields.globalInitRateLimitEnabled.value ? {
        interval: fields.globalInitRateLimitInterval.value,
        nbRequests: fields.globalInitRateLimitNbRequests.value
      } : null,
      merchantInitRateLimit: fields.merchantInitRateLimitEnabled.value ? {
        interval: fields.merchantInitRateLimitInterval.value,
        nbRequests: fields.merchantInitRateLimitNbRequests.value
      } : null,
      defaultSuccessMessage: fields.defaultSuccessMessage.value,
      exceptionTestConfig: {
        merchantCode: fields.exceptionTestConfigMerchantCode.value,
        loginTokenCreate400: fields.exceptionTestConfigLoginTokenCreate400.value,
        loginTokenVerify400: fields.exceptionTestConfigLoginTokenVerify400.value,
        paymentVerify400: fields.exceptionTestConfigPaymentVerify400.value,
        paymentVerify500: fields.exceptionTestConfigPaymentVerify500.value,
        paymentVerifyTimeout: fields.exceptionTestConfigPaymentVerifyTimeout.value,
        paymentMarkAsPaid400: fields.exceptionTestConfigPaymentMarkAsPaid400.value,
        paymentMarkAsPaid500: fields.exceptionTestConfigPaymentMarkAsPaid500.value,
        paymentMarkAsPaidTimeout: fields.exceptionTestConfigPaymentMarkAsPaidTimeout.value,
        autoDebitVerify400: fields.exceptionTestConfigAutoDebitVerify400.value,
        autoDebitVerify500: fields.exceptionTestConfigAutoDebitVerify500.value,
        autoDebitVerifyTimeout: fields.exceptionTestConfigAutoDebitVerifyTimeout.value,
        autoDebitMarkAsConfirmed400: fields.exceptionTestConfigAutoDebitMarkAsConfirmed400.value,
        autoDebitMarkAsConfirmed500: fields.exceptionTestConfigAutoDebitMarkAsConfirmed500.value,
        autoDebitMarkAsConfirmedTimeout: fields.exceptionTestConfigAutoDebitMarkAsConfirmedTimeout.value,
      }
    };
  }

}
