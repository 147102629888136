import {Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import videojs from 'video.js';
import * as moment from "moment";
import {AbstractSimpleComponent} from "../../abstract/abstract.simple.component";
import * as _ from "lodash";

@Component({
  selector: 'app-video-player',
  template: `
    <video #target class="video-js vjs-theme-fantasy" controls playsinline></video>
  `,
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent extends AbstractSimpleComponent implements OnInit, OnDestroy {

  @ViewChild('target', {static: true}) target: ElementRef;

  // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html
  @Input() options: {
    width?: number,
    height?: number,
    fluid?: boolean,
    aspectRatio?: string,
    autoplay?: boolean,
    sources: { src: any; type: string }[],
    preload?: string,
    controls?: boolean,
    language?: string
  };

  player: videojs.Player;

  public constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    // instantiate Video.js
    this.player = videojs(this.target.nativeElement, this.options, () => {
      this.onPlayerReady();
    });
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  /**
   * Sets up the div, img and optional a tags for the plugin.
   */
  setupWatermark = () => {
    const mark = this.getUserInfo().username + '-' + moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
    const text = _.padEnd(mark, 512, " " + mark);

    const videoEl = this.player.el();
    const container = document.createElement('div');
    container.classList.add('vjs-watermark-container');

    const topWatermark = document.createElement('container');
    topWatermark.classList.add('vjs-watermark-top', 'vjs-watermark-content');
    topWatermark.innerText = text;

    const middleWatermark = document.createElement('container');
    middleWatermark.classList.add('vjs-watermark-middle', 'vjs-watermark-content');
    middleWatermark.innerText = text;

    const bottomWatermark = document.createElement('container');
    bottomWatermark.classList.add('vjs-watermark-bottom', 'vjs-watermark-content');
    bottomWatermark.innerText = text;

    container.appendChild(topWatermark);
    container.appendChild(middleWatermark);
    container.appendChild(bottomWatermark);

    const posterEl = videoEl.getElementsByClassName('vjs-poster')[0];
    videoEl.insertBefore(container, posterEl);
  };

  onPlayerReady = () => {
    this.player.addClass('vjs-watermark');
    this.setupWatermark();
  };
}
