import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-expiration-status-label',
  templateUrl: './expiration-status-label.component.html'
})
export class ExpirationStatusLabelComponent implements OnInit {

  @Input() po: any;

  constructor() {
  }

  ngOnInit() {
  }

  isExpired() {
    return this.po == null || this.po.expirationStatus == null || this.po.expirationStatus === 'EXPIRED';
  }

  isAvailable() {
    return this.po != null && this.po.expirationStatus != null && this.po.expirationStatus === 'AVAILABLE';
  }

  isFuture() {
    return this.po != null && this.po.expirationStatus != null && this.po.expirationStatus === 'FUTURE';
  }

}
