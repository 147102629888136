<div class="form-group" [ngClass]="{ 'has-danger': isAddDangerClass() }" *ngIf="getField() != null">

  <div class="row no-gutters">
    <div class="col-auto">
      <app-checkbox [(ngModel)]="getField().value"
                    (ngModelChange)="onValueChanged($event)"
                    [disabled]="fieldDisabled || fieldReadonly"></app-checkbox>
    </div>

    <div class="col">
      <label class="form-control-label">
        <span>&nbsp;&nbsp;</span>
        <span>{{ label | translate }}</span>
      </label>
    </div>
  </div>

  <div class="form-control-feedback" *ngIf="isShowTextError()">
    <span *ngIf="useDefaultErrorMessage()">
      {{ 'form.error.message.' + getValidator().getKey() | translate:getValidator().params }}
    </span>

    <span *ngIf="!useDefaultErrorMessage()">
      {{ errorMessage | translate:getValidator().params }}
    </span>
  </div>

</div>
