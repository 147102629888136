<div class="form-group" *ngIf="getField() != null">

  <label class="form-control-label">
    <span>{{ label | translate }}</span>
    <span *ngIf="getValidator().required">*</span>
  </label>

  <div class="form-control" readonly>
    {{ getField().value }}
  </div>

</div>
