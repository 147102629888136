<app-loading [loadingStatus]="loadingStatus">
  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ 'feature.change-password.title' | translate }}</h2>
          </div>

          <div class="col text-right title-btn-bar">
            <button class="btn btn-warning" (click)="back()">
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>

        </div>
      </div>

      <div class="col-12">

        <div class="card">
          <div class="card-body">
            <div class="margin-auto max-w-md">
              <div class="row" *ngIf="form">

                <div class="col-12">
                  <app-form-group-text [password]="true"
                                       [form]="form"
                                       [fieldKey]="'oldPassword'"
                                       [label]="'feature.change-password.old-password'"
                                       [showError]="showFieldError"></app-form-group-text>
                </div>

                <div class="col-12">
                  <app-form-group-text [password]="true"
                                       [form]="form"
                                       [fieldKey]="'newPassword1'"
                                       [label]="'feature.change-password.new-password.1'"
                                       [showError]="showFieldError"></app-form-group-text>
                </div>

                <div class="col-12">
                  <app-form-group-text [password]="true"
                                       [form]="form"
                                       [fieldKey]="'newPassword2'"
                                       [label]="'feature.change-password.new-password.2'"
                                       [showError]="showFieldError"
                                       [errorMessage]="'feature.change-password.new-password.not-match'"></app-form-group-text>
                </div>

                <div class="col-12 text-right">
                  <button class="btn btn-dark m-b m-l" (click)="save()">
                    <span class="fas fa-save"></span>
                    {{ 'base.save' | translate }}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</app-loading>
