import {Component, Injector} from '@angular/core';
import {AbstractComponent} from '../../../../../core/components/abstract/abstract.component';
import {
  FaskField,
  FaskForm,
  FaskFormBuilder,
  FaskValidator,
  FaskValidatorFactory
} from '../../../../../core/form/fask.form';
import * as moment from 'moment';
import Utils from '../../../../../core/utils/utils';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-sandbox-apk-create-link',
  templateUrl: './sandbox-apk-create-link.component.html'
})
export class SandboxApkCreateLinkComponent extends AbstractComponent {

  vm = this;
  form: FaskForm;
  showFieldError = false;
  fileUrl = null;

  constructor(injector: Injector, private clipboard: Clipboard) {
    super(injector);
  }

  createLink() {
    if (!this.checkBeforeAction()) {
      return;
    }

    this.httpPostOrPut(
      this.getApiUrl('/api/admin/sandbox-apk/latest-apk'),
      {nbHourExpired: this.form.fields.nbHourExpired.value},
      true,
      'create-link',
      {},
      (response) => {
        this.fileUrl = response.fileUrl;
      }
    );
  }

  copyLink() {
    this.clipboard.copy(this.fileUrl);
    this.utilityService.showInfoToast('feature.sandbox-apk.create-link.link-copied');
  }

  protected componentInit() {
    this.showFieldError = false;
    this.fileUrl = null;
    this.createForm();
  }

  protected createForm() {
    this.form = FaskFormBuilder.buildForm({
      nbHourExpired: {value: 48, validator: FaskValidatorFactory.number(true, 24, 240)}
    });
  }

  protected checkBeforeAction() {
    if (this.form != null && !this.form.isValid()) {
      this.showFieldError = true;
      return false;
    }

    return true;
  }

}
