<app-loading [loadingStatus]="vm.loadingStatus">
  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ title | translate }}</h2>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="margin-auto max-w-md">
              <div *ngIf="vm.form" class="row">

                <div class="col-12">
                  <app-form-group-combobox (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'transactionType'"
                                           [form]="vm.form"
                                           [itemDisplayField]="'value'"
                                           [itemKeyField]="'id'"
                                           [itemList]="transactionTypeOptions"
                                           [label]="'feature.payment-transaction-type.menu-title'"
                                           [showError]="showFieldError">
                  </app-form-group-combobox>
                </div>

                <div class="col-12">
                  <app-form-group-select (valueChange)="onFieldChanged($event)"
                                         [fieldKey]="'merchant'"
                                         [form]="vm.form"
                                         [itemDisplayField]="'displayValue'"
                                         [label]="'feature.payment-transaction.merchant'"
                                         [loadData]="loadMerchants"
                                         [placeholder]="'base.option.all' | translate"
                                         [showError]="showFieldError"
                                         [useTypeahead]="true">
                  </app-form-group-select>
                </div>

<!--                <div class="col-12">-->
<!--                  <app-form-group-text (valueChange)="vm.onFieldChanged($event)"-->
<!--                                       [fieldKey]="'autoDebitId'"-->
<!--                                       [form]="vm.form"-->
<!--                                       [label]="'feature.payment-transaction.auto-debit-id'"-->
<!--                                       [showError]="vm.showFieldError"-->
<!--                                       [hidden]="isRefundOption">-->
<!--                  </app-form-group-text>-->
<!--                </div>-->

                <div class="col-12">
                  <app-form-group-text (valueChange)="vm.onFieldChanged($event)"
                                       [fieldKey]="'paymentTransactionId'"
                                       [form]="vm.form"
                                       [label]="'feature.payment-transaction.code'"
                                       [showError]="vm.showFieldError">
                  </app-form-group-text>
                </div>

                <div class="col-12">
                  <app-form-group-text (valueChange)="vm.onFieldChanged($event)"
                                       [fieldKey]="'cif'"
                                       [form]="vm.form"
                                       [label]="'feature.payment-transaction.cif'"
                                       [showError]="vm.showFieldError">
                  </app-form-group-text>
                </div>

                <div class="col-12">
                  <app-form-group-combobox (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'paymentType'"
                                           [form]="vm.form"
                                           [itemDisplayField]="'value'"
                                           [itemKeyField]="'id'"
                                           [itemList]="paymentTypeOptions"
                                           [label]="'base.payment-transaction-type.label'"
                                           [showError]="showFieldError"
                                           [hidden]="isRefundOption">
                  </app-form-group-combobox>
                </div>

                <div class="col-12">
                  <app-form-group-combobox (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'refundStatus'"
                                           [form]="vm.form"
                                           [itemDisplayField]="'value'"
                                           [itemKeyField]="'id'"
                                           [itemList]="refundStatusOptions"
                                           [label]="'base.transaction.type.refund.status'"
                                           [showError]="showFieldError"
                                           [hidden]="!isRefundOption">
                  </app-form-group-combobox>
                </div>

                <div class="col-12">
                  <app-form-group-combobox (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'status'"
                                           [form]="vm.form"
                                           [itemDisplayField]="'value'"
                                           [itemKeyField]="'id'"
                                           [itemList]="statusOptions"
                                           [label]="'base.status'"
                                           [showError]="showFieldError"
                                           [hidden]="isRefundOption">
                  </app-form-group-combobox>
                </div>

                <div class="col-12">

                  <div class="row">

                    <div class="col-6">
                      <app-form-group-date (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'fromDate'"
                                           [form]="vm.form"
                                           [label]="'base.from-created-time'"
                                           [placement]="'top'"
                                           [showError]="showFieldError">
                      </app-form-group-date>
                    </div>

                    <div class="col-6">
                      <app-form-group-date (valueChange)="onFieldChanged($event)"
                                           [errorMessage]="'feature.payment-transaction.to-date-cannot-before-from-date'"
                                           [fieldKey]="'toDate'"
                                           [form]="vm.form"
                                           [label]="'base.to-created-time'"
                                           [placement]="'top'"
                                           [showError]="showFieldError">
                      </app-form-group-date>
                    </div>

                  </div>

                </div>

                <div class="col-12">
                  <button (click)="search()" class="btn btn-info w-full">
                    {{ 'base.search' | translate }}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</app-loading>
