import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {ImageViewerComponent} from "./image-viewer.component";
import {IMAGE_VIEWER_EVENT, ImageViewerEventService, IPhoto} from "./image-viewer-event.service";

@Injectable()
export class ImageViewerService {

  private _documentRef: Document;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _applicationRef: ApplicationRef,
    private imageViewerEventService: ImageViewerEventService

  ) {
    this._documentRef = window.document;
  }

  open(album: Array<IPhoto>, curIndex = 0): void {
    const componentRef = this._createComponent(ImageViewerComponent);

    const images = [];
    const descriptions = [];
    album.forEach((photo) => {
      images.push(photo.src);
      descriptions.push(photo.caption);
    });

    componentRef.instance.images = images;
    componentRef.instance.descriptions = descriptions;
    componentRef.instance.currentImageIndex = curIndex;
    componentRef.instance.cmpRef = componentRef;

    setTimeout(() => {
      this._applicationRef.attachView(componentRef.hostView);
      componentRef.onDestroy(() => {
        this._applicationRef.detachView(componentRef.hostView);
      });
      this._documentRef.querySelector('body').appendChild(componentRef.location.nativeElement);
    });
  }

  close(): void {
    if (this.imageViewerEventService) {
      this.imageViewerEventService.broadcastEvent({ id: IMAGE_VIEWER_EVENT.CLOSE });
    }
  }

  _createComponent(ComponentClass: any): ComponentRef<any> {
    const factory = this._componentFactoryResolver.resolveComponentFactory(ComponentClass);
    return factory.create(this._injector);
  }
}
