<app-loading [loadingStatus]="vm.loadingStatus">
  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ title | translate }}</h2>
          </div>

          <div class="col text-right title-btn-bar">
            <button (click)="refresh()" class="btn btn-info">
              <i class="fas fa-sync-alt"></i>
            </button>

            <button (click)="save()" *ngIf="isCanSave()" class="btn btn-success">
              <i class="fas fa-save"></i>
            </button>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-header bg-dark">
                <h4 class="m-b-0 text-white">{{ 'base.general.info' | translate }}</h4>
              </div>
              <div class="card-body">
                <div class="margin-auto max-w-md">
                  <div *ngIf="form" class="row">

                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'globalInitRateLimitEnabled'"
                                               [form]="form"
                                               [label]="'feature.business-config.globalInitRateLimitEnabled'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>

                    <div class="col-6">
                      <app-form-group-number (valueChange)="onFieldChanged($event)"
                                             [decimals]="0"
                                             [fieldKey]="'globalInitRateLimitInterval'"
                                             [fieldReadonly]="!form.fields.globalInitRateLimitEnabled.value"
                                             [form]="form"
                                             [label]="'feature.business-config.globalInitRateLimitInterval'"
                                             [showError]="showFieldError">
                      </app-form-group-number>
                    </div>

                    <div class="col-6">
                      <app-form-group-number (valueChange)="onFieldChanged($event)"
                                             [decimals]="0"
                                             [fieldKey]="'globalInitRateLimitNbRequests'"
                                             [fieldReadonly]="!form.fields.globalInitRateLimitEnabled.value"
                                             [form]="form"
                                             [label]="'feature.business-config.globalInitRateLimitNbRequests'"
                                             [showError]="showFieldError">
                      </app-form-group-number>
                    </div>

                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'merchantInitRateLimitEnabled'"
                                               [form]="form"
                                               [label]="'feature.business-config.merchantInitRateLimitEnabled'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>

                    <div class="col-6">
                      <app-form-group-number (valueChange)="onFieldChanged($event)"
                                             [decimals]="0"
                                             [fieldKey]="'merchantInitRateLimitInterval'"
                                             [fieldReadonly]="!form.fields.merchantInitRateLimitEnabled.value"
                                             [form]="form"
                                             [label]="'feature.business-config.merchantInitRateLimitInterval'"
                                             [showError]="showFieldError">
                      </app-form-group-number>
                    </div>

                    <div class="col-6">
                      <app-form-group-number (valueChange)="onFieldChanged($event)"
                                             [decimals]="0"
                                             [fieldKey]="'merchantInitRateLimitNbRequests'"
                                             [fieldReadonly]="!form.fields.merchantInitRateLimitEnabled.value"
                                             [form]="form"
                                             [label]="'feature.business-config.merchantInitRateLimitNbRequests'"
                                             [showError]="showFieldError">
                      </app-form-group-number>
                    </div>

                    <div class="col-12">
                      <app-form-group-textarea (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'defaultSuccessMessage'"
                                               [form]="form"
                                               [label]="'feature.business-config.defaultSuccessMessage'"
                                               [showError]="showFieldError"></app-form-group-textarea>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="card">
              <div class="card-header bg-dark">
                <h4 class="m-b-0 text-white">Cấu hình test exception</h4>
              </div>
              <div class="card-body">
                <div class="margin-auto max-w-md">
                  <div class="row">
                    <div class="col-12">
                      <app-form-group-text (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'exceptionTestConfigMerchantCode'"
                                           [form]="form"
                                           [label]="'MerchantCode'"
                                           [showError]="showFieldError"></app-form-group-text>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigLoginTokenCreate400'"
                                               [form]="form"
                                               [label]="'LoginTokenCreate400'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigLoginTokenVerify400'"
                                               [form]="form"
                                               [label]="'LoginTokenVerify400'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigPaymentVerify400'"
                                               [form]="form"
                                               [label]="'PaymentVerify400'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigPaymentVerify500'"
                                               [form]="form"
                                               [label]="'PaymentVerify500'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigPaymentVerifyTimeout'"
                                               [form]="form"
                                               [label]="'PaymentVerifyTimeout'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigPaymentMarkAsPaid400'"
                                               [form]="form"
                                               [label]="'PaymentMarkAsPaid400'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigPaymentMarkAsPaid500'"
                                               [form]="form"
                                               [label]="'PaymentMarkAsPaid500'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigPaymentMarkAsPaidTimeout'"
                                               [form]="form"
                                               [label]="'PaymentMarkAsPaidTimeout'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>

                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigAutoDebitVerify400'"
                                               [form]="form"
                                               [label]="'AutoDebitVerify400'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigAutoDebitVerify500'"
                                               [form]="form"
                                               [label]="'AutoDebitVerify500'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigAutoDebitVerifyTimeout'"
                                               [form]="form"
                                               [label]="'AutoDebitVerifyTimeout'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigAutoDebitMarkAsConfirmed400'"
                                               [form]="form"
                                               [label]="'AutoDebitMarkAsConfirmed400'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigAutoDebitMarkAsConfirmed500'"
                                               [form]="form"
                                               [label]="'AutoDebitMarkAsConfirmed500'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'exceptionTestConfigAutoDebitMarkAsConfirmedTimeout'"
                                               [form]="form"
                                               [label]="'AutoDebitMarkAsConfirmedTimeout'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</app-loading>

