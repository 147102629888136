import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'secondDuration'})
export class SecondDurationPipe implements PipeTransform {
  transform(nbSecond: number): string {
    if (nbSecond == null) {
      return '';
    }

    const minute = Math.floor(nbSecond / 60);
    const second = Math.floor(nbSecond % 60);

    return minute + 'm ' + second + 's';
  }
}
