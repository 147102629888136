import {Component, Injector} from '@angular/core';
import {POListAbstractComponent} from "../../../../../core/components/abstract/po-list.abstract.component";

@Component({
  selector: 'app-sub-admin-list',
  templateUrl: './sub-admin-list.component.html'
})
export class SubAdminListComponent extends POListAbstractComponent {

  vm = this;
  activeStatusItemList = [];
  isPoRole: boolean;

  constructor(injector: Injector) {
    super(injector, 'feature.sub-admin.title', '/api/admin/sub-admin/search', 'sub-admin-detail');
    this.isPoRole = this.utilityService.getTokenInfo().user_role.poRole;
  }

  protected componentInit() {
    this.loadActiveStatusItemList();

    if (this.params['activeStatus'] == null) {
      this.params['activeStatus'] = this.optionAllId;
    }

    super.componentInit();
  }

  protected addMoreBodyParams(body: any): any {
    if (!this.checkEmptyText(this.params['searchText'])) {
      body.search = this.params['searchText'];
    }

    if (this.params['activeStatus'] != null && this.params['activeStatus'] !== this.optionAllId) {
      body.activeStatus = this.params['activeStatus'];
    }

    return super.addMoreBodyParams(body);
  }

  getUserRole(isPoRole: boolean): string {
    if (isPoRole) {
      return 'Product Owner';
    }
    return 'Admin';
  }

  // PRIVATE
  private loadActiveStatusItemList() {
    this.activeStatusItemList = [
      this.getOptionAll(),
      {id: 'DRAFT', name: 'base.active-status.DRAFT'},
      {id: 'ACTIVE', name: 'base.active-status.ACTIVE'},
      {id: 'INACTIVE', name: 'base.active-status.INACTIVE'}
    ];
  }

}
