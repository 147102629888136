<app-loading [loadingStatus]="vm.loadingStatus" [keepContentDOM]="vm.keepContentDOM">

  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ vm.title | translate }}</h2>
          </div>

          <div class="col text-right title-btn-bar">
            <button class="btn btn-info" (click)="vm.goToExportExcel()" *ngIf="vm.hasExportExcel()">
              <span>&nbsp;{{ 'base.export-excel' | translate }}</span>
            </button>

            <button class="btn btn-warning" (click)="vm.back()" *ngIf="vm.isCanBack()">
              <i class="fas fa-arrow-left"></i>
            </button>

            <button class="btn btn-info" (click)="vm.refresh()">
              <i class="fas fa-sync-alt"></i>
            </button>

            <button class="btn btn-white" (click)="vm.goToImportExcel()" *ngIf="vm.hasImportExcel()">
              <i class="far fa-file-excel"></i>
              <span>&nbsp;{{ 'base.btn.import-excel' | translate }}</span>
            </button>

            <div class="btn-group" dropdown *ngIf="vm.hasMultipleImportExcel()">
              <button id="button-basic" dropdownToggle type="button" class="btn btn-white dropdown-toggle"
                      aria-controls="dropdown-basic">
                <i class="far fa-file-excel"></i>
                <span>&nbsp;{{ 'base.btn.import-excel' | translate }}</span>
                <span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                  aria-labelledby="button-basic">

                <li role="menuitem" *ngFor="let importModel of vm.getImportExcelStates()">
                  <a class="dropdown-item" [routerLink]="importModel.state"
                     [queryParams]="vm.getStateQueryParams(false, importModel.id, importModel.params)">
                    {{ importModel.name | translate }}
                  </a>
                </li>

              </ul>
            </div>

            <button class="btn btn-success" (click)="vm.createNewRecord()" *ngIf="vm.isCanCreate()" [disabled]="vmIsPoRole">
              <i class="fas fa-plus"></i>
              <span>&nbsp;{{ 'base.btn.create-new' | translate }}</span>
            </button>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body no-padder">

            <div class="table-filter-container" *ngIf="!noFilter">

              <div class="row">
                <div class="col-12 table-filter-item"
                     [ngClass]="{ 'col-sm-6': useTextFilter, 'col-sm-12': !useTextFilter }" *ngIf="useStatusFilter">
                  <label>{{ 'base.status' | translate }}</label>
                  <select class="custom-select" name="activeStatus" [(ngModel)]="vm.params['activeStatus']"
                          (ngModelChange)="vm.onSearch()">
                    <option *ngFor="let activeStatusListItem of vm.activeStatusList" [value]="activeStatusListItem.id">
                      {{ activeStatusListItem.name | translate }}
                    </option>
                  </select>
                </div>

                <div class="col-12 table-filter-item"
                     [ngClass]="{ 'col-sm-6': useStatusFilter, 'col-sm-12': !useStatusFilter }" *ngIf="useTextFilter">
                  <label class="d-none d-sm-block" *ngIf="useStatusFilter">&nbsp;</label>
                  <app-search-field [searchText]="vm.params['searchText']"
                                    (searchPerform)="vm.onSearchText($event)"></app-search-field>
                </div>

              </div>

              <ng-content select="[table-filter]"></ng-content>

            </div>

            <div class="table-responsive table-basic no-margin-bottom border-bottom"
                 [ngClass]="{ 'no-border-top': noFilter, 'clickable': rowClickable }">

              <div class="wrapper b-t empty-warning-container" *ngIf="vm.checkEmptyList(vm.records)">
                <img src="../assets/images/empty-warning-icon.svg">
                <h2>{{ 'base.empty-warning.row-1' | translate }}</h2>
                <h3>{{ 'base.empty-warning.row-2' | translate }}</h3>
              </div>

              <div *ngIf="!vm.checkEmptyList(vm.records)">

                <table class="table" *ngIf="useSimplePOTemplate">
                  <thead>
                  <tr>
                    <th>{{ 'base.name' | translate }}</th>
                    <th class="w-active-status text-center">{{ 'base.status' | translate }}</th>
                    <th class="w-1x"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let record of vm.records" (click)="vm.editRecord(record)">
                    <td>{{ record.name }}</td>
                    <td class="text-center">
                      <app-active-status-label [po]="record"></app-active-status-label>
                    </td>
                    <td class="arrow-forward">
                      <i class="icon ion-ios-arrow-forward"></i>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <ng-content select="[table-content]" *ngIf="!useSimplePOTemplate"></ng-content>

                <app-pagination [paginationData]="vm.paginationData"
                                (pageChange)="vm.onPageChange($event)"
                                *ngIf="vm.isUsePagination()"></app-pagination>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</app-loading>

