import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import Utils from '../../../utils/utils';
import {Observable} from 'rxjs';
import ExifUtils from '../../../utils/exif.utils';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit, OnDestroy {

  @ViewChild('inputFile') inputFile: ElementRef;
  @Input() buttonClass: string;
  @Input() multiple: false;
  @Input() accept: string = null;
  @Input() openChooserEvent: Observable<void>;
  @Output() fileSelect = new EventEmitter<FileModel>();

  private openChooserEventSubscription: any;

  constructor() {
  }

  ngOnInit() {
    if (this.openChooserEvent != null) {
      this.openChooserEventSubscription = this.openChooserEvent.subscribe(() => this.openFileChooser());
    }
  }

  ngOnDestroy() {
    if (this.openChooserEventSubscription != null) {
      this.openChooserEventSubscription.unsubscribe();
    }
  }

  openFileChooser() {
    setTimeout(() => {
      this.inputFile.nativeElement.click();
    });
  }

  onFileSelected(inputFileChangeEvent) {
    if (this.fileSelect != null) {
      Utils.readFile(inputFileChangeEvent, (file: any, fileName: string, fileSize: number, fileType: string) => {
        const exifOrientation = ExifUtils.findExifOrientationInJPEG(file);
        this.fileSelect.emit(new FileModel(inputFileChangeEvent, file, fileName, fileSize, fileType, exifOrientation));
        this.inputFile.nativeElement.value = '';
      });
    }
  }

}

export class FileModel {

  public constructor(public inputFileChangeEvent: any,
                     public file: any,
                     public fileName: string,
                     public fileSize: number,
                     public fileType: string,
                     public exifOrientation) {
  }

}
