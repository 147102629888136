import {APP_INITIALIZER, Injectable, NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {defineLocale, enGbLocale, viLocale} from 'ngx-bootstrap/chronos';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {LoggerModule, NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {ToastrModule} from 'ngx-toastr';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {NumericTextboxModule} from './core/libs/ngx-numeric-textbox/ngx-numeric-textbox.module';
import {ColorPickerModule} from 'ngx-color-picker';
import {UiSwitchModule} from 'ngx-ui-switch';
import {AgmCoreModule} from '@agm/core';
import {from, Observable} from 'rxjs';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ChartsModule} from 'ng2-charts';
import {RouteReuseStrategy} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {LayoutModule} from '@angular/cdk/layout';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';
import {HeaderComponent} from './core/components/base/header/header.component';
import {SidebarComponent} from './core/components/base/sidebar/sidebar.component';

import {SettingsHttpService} from './core/settings/settings.http.service';
import {UtilityService} from './core/utils/service/utility.service';
import {RoleGuardService} from './core/utils/service/role.guard.service';
import {UnsavedDataGuardService} from './core/utils/service/unsaved-data.guard.service';
import {HttpClientInterceptor} from './core/utils/service/http.client.interceptor';
import {ConfirmModalComponent} from './core/components/utility/confirm-modal/confirm-modal.component';
import {LoadingComponent} from './core/components/utility/loading/loading.component';
import {PaginationComponent} from './core/components/utility/pagination/pagination.component';
import {SearchFieldComponent} from './core/components/utility/search-field/search-field.component';
import {ActiveStatusLabelComponent} from './core/components/utility/active-status-label/active-status-label.component';
import {ActiveStatusControlComponent} from './core/components/utility/active-status-control/active-status-control.component';
import {PaymentTransactionStatusLabelComponent} from './core/components/utility/payment-transaction-status-label/payment-transaction-status-label.component';
import {ExpirationStatusLabelComponent} from './core/components/utility/expiration-status-label/expiration-status-label.component';
import {CheckboxComponent} from './core/components/utility/checkbox/checkbox.component';
import {RadioboxComponent} from './core/components/utility/radiobox/radiobox.component';
import {CounterDirective} from './core/components/utility/counter-directive/counter.directive';
import {FormGroupReadonlyComponent} from './core/form/form-group-readonly/form-group-readonly.component';
import {FormGroupTextComponent} from './core/form/form-group-text/form-group-text.component';
import {FormGroupTextareaComponent} from './core/form/form-group-textarea/form-group-textarea.component';
import {FormGroupDateComponent} from './core/form/form-group-date/form-group-date.component';
import {FormGroupNumberComponent} from './core/form/form-group-number/form-group-number.component';
import {FormGroupComboboxComponent} from './core/form/form-group-combobox/form-group-combobox.component';
import {FormGroupComboboxGroupComponent} from './core/form/form-group-combobox-group/form-group-combobox-group.component';
import {FormGroupColorComponent} from './core/form/form-group-color/form-group-color.component';
import {FormGroupSwitchComponent} from './core/form/form-group-switch/form-group-switch.component';
import {FormGroupSquarePhotoComponent} from './core/form/form-group-square-photo/form-group-square-photo.component';
import {FormGroupCheckboxComponent} from './core/form/form-group-checkbox/form-group-checkbox.component';
import {FormGroupSelectComponent} from './core/form/form-group-select/form-group-select.component';
import {FormGroupPhotoCropComponent} from './core/form/form-group-photo-crop/form-group-photo-crop.component';
import {CustomRouterReuseStrategy} from './core/utils/custom-router-reuse-strategy';
import {POListComponent} from './core/components/utility/po-list/po-list.component';
import {PODetailComponent} from './core/components/utility/po-detail/po-detail.component';
import {ImportViewComponent} from './core/components/utility/import-view/import-view.component';
import {InputFileComponent} from './core/components/utility/input-file/input-file.component';
import {MediumEditorComponent} from './core/components/utility/medium-editor/medium-editor.component';
import {EditorSectionImageModalComponent} from './core/components/utility/editor-section-image-modal/editor-section-image-modal.component';
import {EditorSectionYoutubeModalComponent} from './core/components/utility/editor-section-youtube-modal/editor-section-youtube-modal.component';
import {LdsSpinnerComponent} from './core/components/utility/lds-spinner/lds-spinner.component';
import {MatchHeightDirective} from './core/directive/match-height.directive';
import {VideoPlayerComponent} from './core/components/utility/video-player/video-player.component';
import {NgxGalleryPreviewComponent} from './core/components/utility/image-viewer/ngx-gallery-preview.component';
import {NgxGalleryActionComponent} from './core/components/utility/image-viewer/ngx-gallery-action.component';
import {NgxGalleryArrowsComponent} from './core/components/utility/image-viewer/ngx-gallery-arrows.component';
import {NgxGalleryHelperService} from './core/components/utility/image-viewer/ngx-gallery-helper.service';
import {ImageViewerComponent} from './core/components/utility/image-viewer/image-viewer.component';
import {ImageViewerEventService} from './core/components/utility/image-viewer/image-viewer-event.service';
import {ImageViewerService} from './core/components/utility/image-viewer/image-viewer.service';

import {FullnameInitialPipe} from './core/pipe/fullname-initial.pipe';
import {EpochFromNowPipe} from './core/pipe/epoch-from-now.pipe';
import {EpochDateTimePipe} from './core/pipe/epoch-date-time.pipe';
import {EpochDatePipe} from './core/pipe/epoch-date.pipe';
import {IsoDatePipe} from './core/pipe/iso-date.pipe';
import {IsoDateTimePipe} from './core/pipe/iso-date-time.pipe';
import {IsoTimePipe} from './core/pipe/iso-time.pipe';
import {IsoTimeShortPipe} from './core/pipe/iso-time-short.pipe';
import {IsoMonthPipe} from './core/pipe/iso-month.pipe';
import {ConfidencePercentagePipe} from './core/pipe/confidence-percentage.pipe';

import {SecondDurationPipe} from './core/pipe/second-duration.pipe';
import {MyIntegerPipe} from './core/pipe/my-integer-pipe';

import {NotFoundRouteComponent} from './core/components/route/not-found-route/not-found-route.component';
import {UnknownErrorRouteComponent} from './core/components/route/unknown-error-route/unknown-error-route.component';
import {LoginComponent} from './core/components/route/before-logged-in/login/login.component';
import {ChangePasswordComponent} from './core/components/route/change-password/change-password.component';
import {ResetPasswordModalComponent} from './core/components/utility/reset-password-modal/reset-password-modal.component';
import {CropImageModalComponent} from './core/components/utility/crop-image.modal/crop-image-modal.component';
import {SearchDateRangerComponent} from './core/components/utility/search-date-range/search-date-range.component';

import {HomeComponent} from './core/components/route/home/home.component';
import {SubAdminListComponent} from './business/components/route/sub-admin/sub-admin-list/sub-admin-list.component';
import {SubAdminDetailComponent} from './business/components/route/sub-admin/sub-admin-detail/sub-admin-detail.component';
import {BusinessConfigComponent} from './business/components/route/business-config/business-config.component';
import {MerchantListComponent} from './business/components/route/merchant/merchant-list/merchant-list.component';
import {MerchantDetailComponent} from './business/components/route/merchant/merchant-detail/merchant-detail.component';
import {PaymentTransactionTypeListComponent} from './business/components/route/payment-transaction-type/payment-transaction-type-list/payment-transaction-type-list.component';
import {PaymentTransactionTypeDetailComponent} from './business/components/route/payment-transaction-type/payment-transaction-type-detail/payment-transaction-type-detail.component';
import {PaymentTransactionListComponent} from './business/components/route/payment-transaction/payment-transaction-list/payment-transaction-list.component';
import {PaymentTransactionDetailComponent} from './business/components/route/payment-transaction/payment-transaction-detail/payment-transaction-detail.component';
import {PaymentTransactionLogExportComponent} from './business/components/route/payment-transaction-log/payment-transaction-log-export/payment-transaction-log-export.component';
import {PaymentTransactionSearchComponent} from './business/components/route/payment-transaction/payment-transaction-search/payment-transaction-search.component';
import {PaymentTransactionTypeModalComponent} from './business/components/route/merchant/payment-transaction-type-modal/payment-transaction-type-modal.component';
import {ReconciliationReportExportComponent} from './business/components/route/payment-transaction-log/reconciliation-report-export/reconciliation-report-export.component';
import {MerchantApiConfigFieldsComponent} from './business/components/route/merchant/merchant-api-config-fields/merchant-api-config-fields.component';
import {DashboardComponent} from './business/components/route/dashboard/dashboard.component';
import {SandboxApkListComponent} from './business/components/route/sandbox-apk/sandbox-apk-list/sandbox-apk-list.component';
import {SandboxApkDetailComponent} from './business/components/route/sandbox-apk/sandbox-apk-detail/sandbox-apk-detail.component';
import {SandboxApkCreateLinkComponent} from './business/components/route/sandbox-apk/sandbox-apk-create-link/sandbox-apk-create-link.component';
import { PaymentTypePipe } from './core/pipe/payment-type.pipe';
import { AutoDebitSearchComponent } from './business/components/route/auto-debit/auto-debit-search/auto-debit-search.component';
import { AutoDebitListComponent } from './business/components/route/auto-debit/auto-debit-list/auto-debit-list.component';
import { AutoDebitDetailComponent } from './business/components/route/auto-debit/auto-debit-detail/auto-debit-detail.component';
import { AutoDebitStatusLabelComponent } from './core/components/utility/auto-debit-status-label/auto-debit-status-label.component';
import { TimeUnitPipe } from './core/pipe/time-unit.pipe';
import { BillingPeriodStatusLabelComponent } from './core/components/utility/billing-period-status-label/billing-period-status-label.component';
import { AutoDebitLogExportComponent } from './business/components/route/auto-debit-log/auto-debit-log-export/auto-debit-log-export.component';
import {IncomingRequestExportComponent} from './business/components/route/incoming-request-export/incoming-request-export.component';
import {MerchantExportComponent} from './business/components/route/merchant-export/merchant-export.component';

defineLocale('vi', viLocale);
defineLocale('en', enGbLocale);

export class HashTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function app_Init(settingsHttpService: SettingsHttpService) {
  return () => settingsHttpService.initializeApp();
}

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': {enable: false},
    'rotate': {enable: false}
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    ConfirmModalComponent,
    LoadingComponent,
    SearchFieldComponent,
    PaginationComponent,
    // LABEL
    ActiveStatusLabelComponent,
    ActiveStatusControlComponent,
    PaymentTransactionStatusLabelComponent,
    ExpirationStatusLabelComponent,
    AutoDebitStatusLabelComponent,
    BillingPeriodStatusLabelComponent,
    CheckboxComponent,
    RadioboxComponent,
    CounterDirective,
    FormGroupReadonlyComponent,
    FormGroupTextComponent,
    FormGroupTextareaComponent,
    FormGroupDateComponent,
    FormGroupNumberComponent,
    FormGroupComboboxComponent,
    FormGroupComboboxGroupComponent,
    FormGroupColorComponent,
    FormGroupSwitchComponent,
    FormGroupCheckboxComponent,
    FormGroupSquarePhotoComponent,
    FormGroupSelectComponent,
    FormGroupPhotoCropComponent,
    POListComponent,
    PODetailComponent,
    ImportViewComponent,
    InputFileComponent,
    MediumEditorComponent,
    EditorSectionImageModalComponent,
    EditorSectionYoutubeModalComponent,
    LdsSpinnerComponent,
    VideoPlayerComponent,
    SearchDateRangerComponent,
    // PIPE
    FullnameInitialPipe,
    EpochFromNowPipe,
    EpochDateTimePipe,
    EpochDatePipe,
    IsoDatePipe,
    IsoDateTimePipe,
    IsoTimePipe,
    IsoTimeShortPipe,
    IsoMonthPipe,
    SecondDurationPipe,
    MyIntegerPipe,
    ConfidencePercentagePipe,
    PaymentTypePipe,
    TimeUnitPipe,
    // Directive
    MatchHeightDirective,
    // Core components route
    NotFoundRouteComponent,
    UnknownErrorRouteComponent,
    LoginComponent,
    ChangePasswordComponent,
    ResetPasswordModalComponent,
    CropImageModalComponent,
    ImageViewerComponent,
    NgxGalleryPreviewComponent,
    NgxGalleryActionComponent,
    NgxGalleryArrowsComponent,
    HomeComponent,
    // BUSINESS
    // ROUTE
    SubAdminListComponent,
    SubAdminDetailComponent,
    BusinessConfigComponent,
    MerchantListComponent,
    MerchantDetailComponent,
    MerchantApiConfigFieldsComponent,
    PaymentTransactionTypeModalComponent,
    PaymentTransactionTypeListComponent,
    PaymentTransactionTypeDetailComponent,
    PaymentTransactionSearchComponent,
    PaymentTransactionListComponent,
    PaymentTransactionDetailComponent,
    PaymentTransactionLogExportComponent,
    ReconciliationReportExportComponent,
    DashboardComponent,
    SandboxApkListComponent,
    SandboxApkDetailComponent,
    SandboxApkCreateLinkComponent,
    AutoDebitSearchComponent,
    AutoDebitListComponent,
    AutoDebitDetailComponent,
    AutoDebitLogExportComponent,
    IncomingRequestExportComponent,
    MerchantExportComponent
  ],
  entryComponents: [
    ConfirmModalComponent,
    ResetPasswordModalComponent,
    CropImageModalComponent,
    PaymentTransactionTypeModalComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    LayoutModule,
    AppRoutingModule,
    FormsModule,
    BrowserModule,
    LoggerModule.forRoot({level: NgxLoggerLevel.ERROR, serverLogLevel: NgxLoggerLevel.OFF}),
    BsDropdownModule.forRoot(),
    PerfectScrollbarModule,
    DeviceDetectorModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: HashTranslateLoader
      }
    }),
    NgSelectModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NumericTextboxModule,
    ColorPickerModule,
    UiSwitchModule.forRoot({}),
    TabsModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC_nv83yUqTwsk8jCR6FiOQoc5v76zV-ck'
    }),
    ImageCropperModule,
    ChartsModule,
    NgxJsonViewerModule
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: app_Init, deps: [SettingsHttpService], multi: true},
    UtilityService,
    RoleGuardService,
    UnsavedDataGuardService,
    NGXLogger,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouterReuseStrategy
    },
    {provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig},
    ImageViewerEventService,
    ImageViewerService,
    NgxGalleryHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
