import {
  Directive, ElementRef, AfterViewChecked,
  Input, HostListener, OnDestroy
} from '@angular/core';

@Directive({
  selector: '[appMatchHeight]'
})
export class MatchHeightDirective implements AfterViewChecked, OnDestroy {

  // class name to match height
  @Input()
  appMatchHeight: any;

  private timeoutHandle: any = null;

  constructor(private el: ElementRef) {
  }

  ngAfterViewChecked() {
    // call our matchHeight function here later
    this.matchHeight(this.el.nativeElement, this.appMatchHeight);
  }

  ngOnDestroy() {
    if (this.timeoutHandle != null) {
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = null;
    }
  }

  @HostListener('window:resize')
  onResize() {
    // call our matchHeight function here later
    this.matchHeight(this.el.nativeElement, this.appMatchHeight);
  }

  matchHeight(parent: HTMLElement, className: string) {
    // match height logic here

    if (!parent) {
      return;
    }
    const children = parent.getElementsByClassName(className);

    if (!children) return;

    // reset all children height
    Array.from(children).forEach((x: HTMLElement) => {
      x.style.height = 'initial';
    })

    // gather all height
    const itemHeights = Array.from(children)
      .map(x => x.getBoundingClientRect().height);

    // find max height
    const maxHeight = itemHeights.reduce((prev, curr) => {
      return curr > prev ? curr : prev;
    }, 0);

    if (maxHeight > 0) {
      // apply max height
      Array.from(children)
        .forEach((x: HTMLElement) => x.style.height = `${maxHeight}px`);
    }
  }
}
