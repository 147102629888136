import {
  Component,
  Injector,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  ChangeDetectorRef, AfterContentChecked
} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';
import {concat, Observable, of, Subject} from 'rxjs';
import {switchMap, tap, catchError} from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import Utils from '../../utils/utils';

@Component({
  selector: 'app-form-group-select',
  templateUrl: './form-group-select.component.html',
  styleUrls: ['./form-group-select.component.scss']
})
export class FormGroupSelectComponent extends FormGroupAbstractComponent implements OnInit, AfterContentChecked {

  @Input() placeholder = '';
  @Input() loadData?: (term: string) => Observable<Array<any>>;
  @Input() useTypeahead = false;
  @Input() clearOnSelect = false;
  @Input() itemKeyField = 'id';
  @Input() itemDisplayField = 'name';
  @Input() multiple = false;
  @Input() hideSelected = false;
  @Input() typeToSearchText = 'base.typeToSearchText';
  @Input() notFoundText = 'base.notFoundText';
  @Input() addTag: boolean | ((term: string) => any | Promise<any>) = false;
  @ViewChild('input') inputElement: ElementRef;
  @ViewChild('selectComponent') selectComponent: NgSelectComponent;

  loading = false;
  inputSubject$ = new Subject<string>();
  internalDataSource$: Observable<Array<any>>;

  constructor(protected injector: Injector, private cdr: ChangeDetectorRef) {
    super(injector);
  }

  ngOnInit(): void {
    this.internalDataSource$ = this.useTypeahead ? this.createTypeaheadDatasource() : this.createDataSource();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public focusInput(): void {
    this.inputElement.nativeElement.focus();
  }

  public clearData(): void {
    if (this.selectComponent) {
      this.clearInternal();
    }
  }

  onItemSelected(value): void {
    this.getField().value = value;
    this.valueChange.emit({ fieldKey: this.fieldKey, value: value });
    if (this.clearOnSelect && !this.multiple) {
      this.clearInternal();
    }
  }

  compareFunction = (a: any, b: any) => {
    const id1 = a ? a[this.itemKeyField] : null;
    const id2 = b ? b[this.itemKeyField] : null;
    return id1 === id2;
  };

  searchFunction = (term: string, item: any): boolean => {
    return Utils.searchASCII(item[this.itemDisplayField], term);
  };

  private setLoading(loading: boolean) {
    this.loading = loading;
  }

  private createDataSource() {
    if (this.loadData == null) {
      return null;
    }
    return of([]).pipe(
      tap(() => this.setLoading(true)),
      switchMap(() => {
        return this.loadData(null).pipe(
          catchError(() => of([])),
          tap(() => this.setLoading(false))
        );
      })
    );
  }

  private createTypeaheadDatasource() {
    if (this.loadData == null) {
      return null;
    }
    return concat(
      of([]).pipe(
        tap(() => this.setLoading(true)),
        switchMap(() => {
          return this.loadData(null).pipe(
            catchError(() => of([])),
            tap(() => this.setLoading(false))
          );
        })
      ),
      this.inputSubject$.pipe(
        tap(() => this.setLoading(true)),
        switchMap(term => {
          return this.loadData(term).pipe(
            catchError(() => of([])),
            tap(() => this.setLoading(false))
          );
        })
      )
    );
  }

  private clearInternal() {
    if (this.selectComponent.hasValue) {
      this.selectComponent.itemsList.clearSelected(true);
      this.selectComponent['_updateNgModel']();
    }
    this.selectComponent['_clearSearch']();

    if (this.useTypeahead) {
      this.inputSubject$.next(null);
    } else {
      this.internalDataSource$ = this.createDataSource();
    }

    this.selectComponent['_onSelectionChanged']();
  }
}
