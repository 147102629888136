import {Injector} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import * as _ from 'lodash';

import {LoadingStatus} from '../utility/loading/loading.status.model';
import {UtilityService} from '../../utils/service/utility.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Title} from '@angular/platform-browser';
import {Constant} from '../../../business/constant';
import Utils from '../../utils/utils';
import * as FileSaver from 'file-saver';
import * as moment from "moment";
import watermark from 'watermarkjs';
import {FaskField, FaskForm, FaskValidator} from "../../form/fask.form";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export abstract class AbstractSimpleComponent {

  protected utilityService: UtilityService;
  protected httpClient: HttpClient;
  protected logger: NGXLogger;
  protected translateService: TranslateService;
  protected toastr: ToastrService;
  protected modalService: BsModalService;
  protected titleService: Title;

  public vm = this;
  public optionAllId = 'all';
  public loadingStatus = new LoadingStatus();
  public CONSTANT = Constant;

  protected constructor(protected injector: Injector) {
    this.utilityService = injector.get(UtilityService);
    this.httpClient = injector.get(HttpClient);
    this.logger = injector.get(NGXLogger);
    this.translateService = injector.get(TranslateService);
    this.toastr = injector.get(ToastrService);
    this.modalService = injector.get(BsModalService);
    this.titleService = injector.get(Title);
  }

  // ----------------API REQUEST---------------
  // ------------------------------------------
  // ------------------------------------------
  getApiUrl(path: string): string {
    return this.utilityService.getApiUrl(path);
  }

  protected httpGet(url: string,
                    requestParams: HttpParams | { [param: string]: string | string[]},
                    loadingKey: string,
                    onSuccess: (value: any) => void,
                    onFailure?: (error: any) => void,
                    skipErrorProcess = false) {
    const thisComponent = this;

    this.loadingStatus.setLoading(loadingKey, true);

    const onNext = (data) => {
      onSuccess(data);

      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    const onError = (error) => {
      if (onFailure != null) {
        onFailure(error);
      }

      if (!skipErrorProcess) {
        thisComponent.logError('GET UNSUCCESSFULLY.', error);
        thisComponent.loadingStatus.setError(true);
      }

      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    this.httpClient.get<any>(url, {
      observe: 'body',
      responseType: 'json',
      headers: {'Content-Type': 'application/json;charset=UTF-8'},
      params: requestParams
    }).subscribe(onNext, onError);
  }

  protected httpPostOrPut(url: string,
                          requestParams: HttpParams | { [param: string]: string | string[] },
                          post: boolean,
                          loadingKey: string,
                          body: any,
                          onSuccess: (value: any) => void,
                          onFailure?: (error: any, errorStatus: number, errorCode: string, errorData: any) => void,
                          autoShowToast?: boolean,
                          successMessage?: string,
                          failureMessage?: string) {
    if (autoShowToast == null) {
      autoShowToast = true;
    }

    if (successMessage == null) {
      successMessage = 'base.message.save.successfully';
    }

    if (failureMessage == null) {
      failureMessage = 'base.message.save.unsuccessfully';
    }

    const thisComponent = this;

    const onNext = (data) => {
      if (autoShowToast) {
        thisComponent.utilityService.showInfoToast(successMessage);
      }

      onSuccess(data);

      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    const onError = (error) => {
      thisComponent.logError(error);
      const errorStatus = Utils.getFieldSafe(error, 'status');
      const errorCode = Utils.getFieldSafe(error, 'error', 'error_code');
      const errorData = Utils.getFieldSafe(error, 'error', 'error_data');


      if (onFailure != null) {
        onFailure(error, errorStatus, errorCode, errorData);
      }

      if (autoShowToast) {
        if (errorStatus === 400 && errorCode != null) {
          thisComponent.utilityService.showErrorToast('server.error.' + errorCode);
        } else {
          thisComponent.utilityService.showErrorToast(failureMessage);
        }
      }

      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    this.loadingStatus.setLoading(loadingKey, true);
    if (post) {
      this.httpClient.post(url, body, {
        params: requestParams,
        headers: {'Content-Type': 'application/json;charset=UTF-8'}
      }).subscribe(onNext, onError);
    } else {
      this.httpClient.put(url, body, {
        params: requestParams,
        headers: {'Content-Type': 'application/json;charset=UTF-8'}
      }).subscribe(onNext, onError);
    }
  }

  protected httpDelete(url: string,
                       requestParams: HttpParams | { [param: string]: string | string[] },
                       loadingKey: string,
                       onSuccess: (value: any) => void,
                       onFailure?: (error: any, errorStatus: any, errorCode: any) => void,
                       autoShowToast?: boolean,
                       successMessage?: string,
                       failureMessage?: string) {
    if (autoShowToast == null) {
      autoShowToast = true;
    }

    if (successMessage == null) {
      successMessage = 'base.message.delete.successfully';
    }

    if (failureMessage == null) {
      failureMessage = 'base.message.delete.unsuccessfully';
    }

    const thisComponent = this;

    const onNext = (data) => {
      if (autoShowToast) {
        thisComponent.utilityService.showInfoToast(successMessage);
      }

      onSuccess(data);

      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    const onError = (error) => {
      thisComponent.logError(error);
      const errorCode = Utils.getFieldSafe(error, 'error', 'meta', 'error_code');
      const errorStatus = Utils.getFieldSafe(error, 'status');

      if (onFailure != null) {
        onFailure(error, errorCode, errorStatus);
      }

      if (autoShowToast) {
        if (errorStatus === 400 && errorCode != null) {
          thisComponent.utilityService.showErrorToast('server.error.' + errorCode);
        } else {
          thisComponent.utilityService.showErrorToast(failureMessage);
        }
      }

      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    this.loadingStatus.setLoading(loadingKey, true);
    this.httpClient.delete(url, {
      params: requestParams,
      headers: {'Content-Type': 'application/json;charset=UTF-8'}
    }).subscribe(onNext, onError);
  }

  protected httpPostMultipartForm(url: string,
                                  requestParams: HttpParams | { [param: string]: string | string[] },
                                  loadingKey: string,
                                  body: any,
                                  onSuccess: (value: any) => void,
                                  onFailure?: (error: any, errorStatus: any, errorCode: any) => void,
                                  autoShowToast?: boolean,
                                  successMessage?: string,
                                  failureMessage?: string) {
    if (autoShowToast == null) {
      autoShowToast = true;
    }

    if (successMessage == null) {
      successMessage = 'base.message.save.successfully';
    }

    if (failureMessage == null) {
      failureMessage = 'base.message.save.unsuccessfully';
    }

    const thisComponent = this;

    const onNext = (data) => {
      if (autoShowToast) {
        thisComponent.utilityService.showInfoToast(successMessage);
      }

      onSuccess(data);

      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    const onError = (error) => {
      thisComponent.logError(error);
      const errorCode = Utils.getFieldSafe(error, 'error', 'meta', 'error_code');
      const errorStatus = Utils.getFieldSafe(error, 'status');

      if (onFailure != null) {
        onFailure(error, errorCode, errorStatus);
      }

      if (autoShowToast) {
        if (errorStatus === 400 && errorCode != null) {
          thisComponent.utilityService.showErrorToast('server.error.' + errorCode);
        } else {
          thisComponent.utilityService.showErrorToast(failureMessage);
        }
      }

      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    const bodyForm = new FormData();
    if (body != null) {
      for (const key of Object.keys(body)) {
        bodyForm.append(key, body[key]);
      }
    }

    this.loadingStatus.setLoading(loadingKey, true);
    this.httpClient.post(url, bodyForm, {
      params: requestParams
    }).subscribe(onNext, onError);
  }

  protected httpDownloadFile(url: string,
                             _requestParams: HttpParams | { [param: string]: string | string[] },
                             loadingKey: string,
                             filename: string = null,
                             onFailure: (error: any, errorStatus: any, errorCode: any) => void = null,
                             autoShowToast: boolean = true,
                             failureMessage: string = 'base.message.download.unsuccessfully') {
    const thisComponent = this;

    this.loadingStatus.setLoading(loadingKey, true);

    const onNext = (response: HttpResponse<Blob>) => {
      if (filename == null) {
        filename = AbstractSimpleComponent.getFileNameFromResponseContentDisposition(response);
      }

      FileSaver.saveAs(response.body, filename);
      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    const onError = (error) => {
      thisComponent.logError(error);
      const errorCode = Utils.getFieldSafe(error, 'error', 'meta', 'error_code');
      const errorStatus = Utils.getFieldSafe(error, 'status');

      if (onFailure != null) {
        onFailure(error, errorCode, errorStatus);
      }

      if (autoShowToast) {
        if (errorStatus === 400 && errorCode != null) {
          thisComponent.utilityService.showErrorToast('server.error.' + errorCode);
        } else {
          thisComponent.utilityService.showErrorToast(failureMessage);
        }
      }

      thisComponent.loadingStatus.setLoading(loadingKey, false);
    };

    const requestParams = {};
    Object.keys(_requestParams).forEach(key => {
      if (_requestParams[key] != null) {
        requestParams[key] = _requestParams[key];
      }
    });

    this.httpClient.get(url, {
      observe: 'response',
      responseType: 'blob',
      headers: {'Content-Type': 'application/octet-stream'},
      params: requestParams
    }).subscribe(onNext, onError);
  }

  private static getFileNameFromResponseContentDisposition(response: HttpResponse<any>): string {
    const hasFilename = response.headers.has('Content-Disposition');
    if (hasFilename) {
      const contentDisposition = response.headers.get('content-disposition') || '';
      const matches = /filename=([^;]+)/ig.exec(contentDisposition);
      let filename = (matches[1] || 'untitled').trim();
      filename = filename.split('"').join('');
      return filename;
    }

    return 'untitled';
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------

  // ----------------UTILS---------------------
  // ------------------------------------------
  // ------------------------------------------
  checkEmptyList(list): boolean {
    return _.isEmpty(list);
  }

  checkEmptyText(text: string): boolean {
    return text == null || _.toString(text).trim().length === 0;
  }

  getObjectProperty(object, propertyPath: string) {
    return _.get(object, propertyPath);
  }

  getCategoryName(category) {
    return this.getObjectProperty(category, 'name');
  }

  getOptionAll() {
    return {id: this.optionAllId, name: this.translate('base.option.all')};
  }

  getUserInfo() {
    return this.utilityService.getUserInfo();
  }

  getRole(): string {
    return this.utilityService.getRole();
  }

  isMobile(): boolean {
    return this.utilityService.isMobile();
  }

  protected translate(key: string | Array<string>, interpolateParams?: Object): string | any {
    return this.utilityService.translate(key, interpolateParams);
  }

  protected logError(message: any, ...additional: any[]) {
    this.utilityService.logError(message, additional);
  }

  protected createFromToDateFieldConfig(defaultFromDate: Date,
                                        defaultToDate: Date,
                                        required: boolean = true,
                                        maxDiffTime: number = 3,
                                        maxDiffTimeUnit: moment.unitOfTime.DurationConstructor = 'months',
                                        fromDateKey: string = 'fromDate',
                                        toDateKey: string = 'toDate') {
    const fromDateValidator = new FaskValidator('invalid-date', (field: FaskField) => {
      return moment(field.value).isValid();
    }, required);

    const toDateValidator = new FaskValidator('invalid-date', (field: FaskField, form: FaskForm) => {
      const toDateField = field;
      const fromDateField = form.fields['fromDate'];
      if (fromDateField == null || !toDateField.value) {
        return false;
      }
      if (moment(fromDateField.value).isAfter(toDateField.value)) {
        return false;
      }
      return moment(fromDateField.value).add(maxDiffTime, maxDiffTimeUnit).isAfter(toDateField.value);
    }, required);

    const config: any = {};
    config[fromDateKey] = {value: defaultFromDate, validator: fromDateValidator};
    config[toDateKey] = {value: defaultToDate, validator: toDateValidator, relatedKeys: [fromDateKey]};

    return config;
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------

  // ----------------BUSINESS------------------
  // ------------------------------------------
  // ------------------------------------------

  protected isInRole(role: string): boolean {
    const currentRole = this.getRole();
    return role === currentRole;
  }

  protected createWatermark(url: any, callback: (base64Src: string) => void): void {
    if (url == null) {
      return;
    }
    const options = {
      init(img) {
        img.crossOrigin = 'anonymous'
      }
    };
    const mark = this.getUserInfo().username + '-' + moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
    watermark([url], options)
      .image(function (target) {
        const context = target.getContext('2d');
        const text = _.padEnd(mark, 512, " " + mark);

        const centerX = target.width / 2;
        const startY1 = (target.height + centerX) / 3;
        const startY2 = startY1 * 2;

        context.save();
        context.translate(0, startY1);
        context.globalAlpha = 0.3;
        context.fillStyle = '#fff';
        context.font = '21px serif';
        context.rotate(-45 * Math.PI / 180);
        context.fillText(text, 0, 0);
        context.restore();

        context.save();
        context.translate(0, startY2);
        context.globalAlpha = 0.3;
        context.fillStyle = '#fff';
        context.font = '21px serif';
        context.rotate(-45 * Math.PI / 180);
        context.fillText(text, 0, 0);
        context.restore();

        context.save();
        context.translate(centerX, target.height);
        context.globalAlpha = 0.3;
        context.fillStyle = '#fff';
        context.font = '21px serif';
        context.rotate(-45 * Math.PI / 180);
        context.fillText(text, 0, 0);
        context.restore();

        return target;
      })
      .then((img) => {
        callback(img.src);
      });
  }

  loadMerchantData(text: string): Observable<any> {
    const url = this.getApiUrl('/api/admin/merchant/search-active');
    return this.httpClient
      .post(url, {
        search: text
      })
      .pipe(
        map((result: any) => {
          _.forEach(result.list, (record) => {
            record.displayValue = record.name + ' (' + record.code + ')';
          });
          return result.list;
        })
      );
  }

  loadUserData(text: string): Observable<any> {
    const url = this.getApiUrl('/api/admin/user-list/search-active');
    return this.httpClient
      .post(url, {
        search: text
      })
      .pipe(
        map((result: any) => {
          _.forEach(result.list, (record) => {
            record.displayValue = record.fullname + ' (' + record.username + ')';
          });
          return result.list;
        })
      );
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------
}
