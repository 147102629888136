import {Component, Injector} from '@angular/core';
import {FaskField, FaskForm, FaskFormBuilder, FaskValidator, FaskValidatorFactory} from '../../../form/fask.form';
import Utils from '../../../utils/utils';
import {AbstractComponent} from '../../abstract/abstract.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends AbstractComponent {

  form: FaskForm;
  showFieldError: boolean;

  constructor(injector: Injector) {
    super(injector, 'feature.change-password.title');
  }

  protected componentInit() {
    this.utilityService.clearHasUnsavedData();
    this.showFieldError = false;

    this.createForm();
  }

  protected getBodyToSave() {
    return {
      oldPassword: this.form.fields['oldPassword'].value,
      newPassword: this.form.fields['newPassword1'].value
    };
  }

  protected createForm() {
    this.form = FaskFormBuilder.buildForm({
      oldPassword: {value: '', validator: FaskValidatorFactory.text(true, 8, 30)},
      newPassword1: {value: '', validator: FaskValidatorFactory.text(true, 8, 30)},
      newPassword2: {
        value: '',
        validator: new FaskValidator('text', (field: FaskField, form: FaskForm) => {
          const newPassword1Field = form.fields['newPassword1'];
          if (newPassword1Field == null || Utils.isEmptyString(newPassword1Field.value)) {
            return false;
          }

          const newPassword1 = newPassword1Field.value.trim();

          if (field != null && !Utils.isEmptyString(field.value)) {
            const newPassword2 = field.value.toString().trim();

            return newPassword1 === newPassword2;
          }

          return false;
        }, true),
        relatedKeys: ['newPassword1']
      }
    });
  }

  save() {
    if (!this.form.isValid()) {
      this.showFieldError = true;
      return;
    }

    const body = this.getBodyToSave();

    const url = this.utilityService.getApiUrl('/authentication/change-password');
    this.httpPostOrPut(
      url,
      null,
      true,
      'change-password',
      body,
      () => {
        this.reloadState();
      }
    );
  }

}
