<nav id="sidebar">

  <perfect-scrollbar style="max-width: 100%; max-height: 100%;" [config]="perfectScrollbarConfig">
    <div class="sidebar-header">
      <img src="../assets/sidebar/sidebar-header-logo.svg" alt="LOGO">
    </div>

    <div class="menu-container">

      <ul class="list-unstyled menu-group-node" *ngFor="let menuGroup of menuGroups">

        <!--Menu group name-->
        <li class="menu-header font-weight-light">
          <span>{{ menuGroup.name | translate }}</span>
        </li>

        <!--Menu group content-->
        <li class="menu-node" *ngFor="let menu of menuGroup.menus" [ngClass]="{ 'open' : menu.opened, 'active' : isActiveMenu(menu) }">

          <!--Menu no child-->
          <a class="menu-no-child" *ngIf="!menu.hasChild()" [routerLink]="'/' + menu.state" [queryParams]="menu.queryParams">
            <i ngClass="{{ menu.icon }}"></i>
            <span>{{ menu.name | translate }}</span>
          </a>

          <!--Menu has child-->
          <a class="menu-has-child" href="javascript:void(0);" *ngIf="menu.hasChild()" (click)="showSubMenu(menu)">
            <span class="caret-container">
              <i class="pe-20px pe-7s-angle-right"></i>
              <i class="pe-20px pe-7s-angle-down"></i>
            </span>
            <i ngClass="{{ menu.icon }}"></i>
            <span>{{ menu.name | translate }}</span>
          </a>

          <!--Sub menu container-->
          <ul class="list-unstyled sub-menu-container" *ngIf="menu.hasChild()">
            <li class="sub-menu-node" *ngFor="let subMenu of menu.subMenus" [ngClass]="{ 'active' : isActiveMenu(subMenu) }">
              <a [routerLink]="'/' + subMenu.state" [queryParams]="subMenu.queryParams">
                <span>{{ subMenu.name | translate }}</span>
              </a>
            </li>
          </ul>

        </li>

        <!--Menu group footer-->
        <li class="separator"></li>

      </ul>

    </div>
  </perfect-scrollbar>

</nav>
