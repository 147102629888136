<app-loading [loadingStatus]="vm.loadingStatus" class="import-view">
  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ vm.title | translate }}</h2>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <!--HEADER-->
            <div class="row">
              <div class="col-12">

                <div class="row">
                  <div class="col" *ngFor="let step of vm.steps; let i = index;">
                    <div class="step-item"
                         [ngClass]="{'step-item-completed': vm.isDoneStep(i), 'step-item-current': vm.isStep(i)}">
                      {{ (i + 1) }}.&nbsp;{{ step | translate }}
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-12">
                <hr>
              </div>

              <div class="col-12">
                <ng-content select="[additional-field]"></ng-content>
              </div>

              <!--BODY-->
              <div class="col-12">

                <div *ngIf="vm.isStep(0)">
                  <app-input-file buttonClass="btn btn-info w-full" (fileSelect)="onExcelSelected($event)">
                    <i class="far fa-file-excel"></i>
                    {{ 'base.import.choose.file' | translate }}
                  </app-input-file>
                </div>

                <div *ngIf="vm.isStep(1)">

                  <!--SUCCESS-->
                  <div *ngIf="!vm.params['importVerifyDto']['error']">
                    <h3> {{ 'base.import.verify.ok' | translate }} </h3>
                  </div>

                  <!--ERROR-->
                  <div *ngIf="vm.params['importVerifyDto']['error']">
                    <div class="fask-box">

                      <span class="fask-box-icon fask-box-icon-danger">
                        <i class="fas fa-exclamation-triangle"></i>
                      </span>

                      <div class="fask-box-info">
                        <p class="fask-box-info-title">{{ vm.params['importVerifyDto']['nbErrorRows']}}</p>
                        <p class="fask-box-info-content">{{ 'base.import.record.error' | translate }}</p>
                      </div>

                    </div>
                  </div>

                </div>

                <div *ngIf="vm.isStep(2)">
                  <h3> {{ vm.getFinishMessage() | translate }} </h3>
                </div>

              </div>

              <div class="col-12">
                <hr>
              </div>

              <!--FOOTER-->
              <div class="col-12">
                <div class="row">
                  <div class="col-auto">
                    <a class="btn btn-dark" [href]="vm.getTemplateLink()" *ngIf="vm.isStep(0)">
                      <span class="fa fa-download"></span>
                      {{ 'base.import.download.template.file' | translate }}
                    </a>

                    <a class="btn btn-danger" [href]="vm.params['importVerifyDto']['errorFileUrl']"
                       *ngIf="vm.isStep(1) && vm.params['importVerifyDto']['error']">
                      <span class="fa fa-download"></span>
                      {{ 'base.import.download.error.file' | translate }}
                    </a>

                    <app-checkbox [(ngModel)]="vm.params['enable']"
                                  [label]="'base.import.enable.option'"
                                  *ngIf="vm.isStep(1) && !vm.params['importVerifyDto']['error'] && vm.isUseEnableButton()">
                    </app-checkbox>
                  </div>

                  <div class="col text-right">
                    <button class="btn btn-warning" *ngIf="vm.isCanBackStep()" (click)="vm.backStep()">
                      {{ 'base.import.back' | translate }}
                    </button>

                    <button class="btn btn-success m-l" *ngIf="vm.isCanNextStep()" (click)="vm.nextStep()">
                      {{ 'base.import.next' | translate }}
                    </button>

                    <button class="btn btn-success m-l" *ngIf="vm.isLastStep()" (click)="vm.back()">
                      {{ 'base.import.finish' | translate }}
                    </button>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</app-loading>





