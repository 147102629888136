import {Injector} from '@angular/core';
import {AbstractComponent} from './abstract.component';
import {FaskForm} from '../../form/fask.form';
import Utils from '../../utils/utils';

export abstract class PODetailAbstractComponent extends AbstractComponent {

  public record: any;
  public form: FaskForm;
  public showFieldError: boolean;

  protected constructor(injector: Injector,
                        public title: string,
                        protected poBackendPath: string,
                        protected listRoute: string) {
    super(injector, title);
  }

  protected abstract createForm(): void;

  protected abstract getBodyToSave(): any;

  protected isValidForSave() {
    return true;
  }

  protected componentInit() {
    this.utilityService.clearHasUnsavedData();
    this.record = {};
    this.showFieldError = false;

    if (this.isNew()) {
      this.record = {
        activeStatus: 'DRAFT'
      };

      this.createForm();
    } else {
      if (Utils.isEmptyString(this.id)) {
        this.goTo404();
        return;
      }

      this.reloadData();
    }
  }

  public getDefaultBackState() {
    return this.listRoute != null ? this.listRoute : super.getDefaultBackState();
  }

  public isNew() {
    return this.params['new'] != null && this.params['new'] === true;
  }

  public getReloadDataUrl() {
    return this.getApiUrl(this.poBackendPath + '/' + this.id);
  }

  protected getDetailState() {
    return null;
  }

  public reloadData() {
    this.record = {};

    const url = this.getReloadDataUrl();

    this.httpGet(url,
      null,
      'data',
      (response) => {
        this.record = response;
        this.createForm();
      }
    );
  }

  public isDraft() {
    return Utils.isPODraft(this.record);
  }

  public isActive() {
    return Utils.isPOActive(this.record);
  }

  public isInactive() {
    return Utils.isPOInactive(this.record);
  }

  public isCanBack() {
    return true;
  }

  public isCanSave() {
    return this.utilityService.isHasUnsavedData();
  }

  public isCanDelete() {
    return this.isDraft() && !this.isNew() && !this.isCanSave();
  }

  public isCanEnable() {
    return this.isDraft();
  }

  public isCanActivate() {
    return this.isInactive();
  }

  public isCanDeactivate() {
    return this.isActive();
  }

  public isReadonly() {
    return this.isInactive();
  }

  public onFieldChanged(event: { fieldKey: string, value: any }) {
    this.utilityService.setHasUnsavedData();
  }

  public getSaveUrl() {
    if (this.isNew()) {
      return this.getApiUrl(this.poBackendPath);
    } else {
      return this.getApiUrl(this.poBackendPath + '/' + this.id);
    }
  }

  public save(enable?: boolean) {
    if (enable == null) {
      enable = false;
    }

    const requestParams = {
      enable: enable.toString()
    };

    if (this.form != null && !this.form.isValid()) {
      this.showFieldError = true;
      return;
    }

    if (!this.isValidForSave()) {
      return;
    }

    const url = this.getSaveUrl();
    const body = this.getBodyToSave();

    if (this.isNew()) {
      this.httpPostOrPut(
        url,
        requestParams,
        true,
        'save', body, (data) => {
          this.utilityService.clearHasUnsavedData();
          if (this.getDetailState() != null) {
            this.goTo(this.getDetailState(), data.id, null, true);
          } else {
            this.goTo('.', data.id, null, true, this.route);
          }
        });
    } else {
      this.httpPostOrPut(
        url,
        requestParams,
        true,
        'save',
        body,
        () => {
          this.utilityService.clearHasUnsavedData();
          this.reloadState();
        });
    }
  }

  public enable() {
    this.save(true);
  }

  public delete() {
    const url = this.getApiUrl(this.poBackendPath + '/' + this.id + '/delete');
    this.httpPostOrPut(
      url,
      null,
      true,
      'delete',
      null,
      () => {
        this.back();
      },
      null,
      true,
      'base.message.delete.successfully',
      'base.message.delete.unsuccessfully'
    );
  }

  public activate() {
    const url = this.getApiUrl(this.poBackendPath + '/' + this.id + '/activate');
    this.httpPostOrPut(
      url,
      null,
      true,
      'activate',
      null,
      () => {
        this.reloadState();
      },
      null,
      true,
      'base.message.activate.successfully',
      'base.message.activate.unsuccessfully'
    );
  }

  public deactivate() {
    const url = this.getApiUrl(this.poBackendPath + '/' + this.id + '/deactivate');
    this.httpPostOrPut(
      url,
      null,
      true,
      'deactivate',
      null,
      () => {
        this.reloadState();
      },
      null,
      true,
      'base.message.deactivate.successfully',
      'base.message.deactivate.unsuccessfully'
    );
  }

}
