import {Component, Injector} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractComponent} from '../../../../core/components/abstract/abstract.component';
import {FaskForm, FaskFormBuilder, FaskValidatorFactory} from '../../../../core/form/fask.form';
import Utils from '../../../../core/utils/utils';

@Component({
  selector: 'app-merchant-export',
  templateUrl: './merchant-export-component.html'
})
export class MerchantExportComponent extends AbstractComponent {

  vm = this;
  form: FaskForm;
  showFieldError = false;

  merchantCodes: any[];
  isValidDeleteBtn = false;

  title = 'feature.merchant.export-title';

  constructor(injector: Injector) {
    super(injector);
  }

  loadMerchants = (text: string): Observable<any> => {
    const url = this.getApiUrl('/api/admin/merchant/search');
    return this.httpClient
      .post(url, {
        search: text
      })
      .pipe(
        map((result: any) => {
          _.forEach(result.list, (record) => {
            record.displayValue = record.name + ' (' + record.code + ')';
          });
          return result.list;
        })
      );
  };

  // noinspection JSUnusedLocalSymbols
  onFieldChanged(event: { fieldKey: string; value: any }) {
    const mcValue = event.value;
    if (mcValue == null) {
      this.merchantCodes = [];
      this.isValidDeleteBtn = false;
    } else {
      if(this.merchantCodes.indexOf(mcValue.code) === -1) {
        this.merchantCodes.push(mcValue.code);
        this.isValidDeleteBtn = true;
      }
    }
  }

  export() {
    if (!this.checkBeforeAction()) {
      return;
    }
    this.httpDownloadFile(
      this.getApiUrl('/api/admin/merchant/info/export'),
      {
        merchantCodes: this.merchantCodes,
      },
      'export'
    );
  }

  deleteMerchantCodes() {
    this.componentInit();
    this.merchantCodes = [];
    this.isValidDeleteBtn = false;
  }

  deleteMerchantCode($event, merchantCode) {
    const index = this.merchantCodes.indexOf(merchantCode);
    if (index > -1) { // only splice array when item is found
      this.merchantCodes.splice(index, 1); // 2nd parameter means remove one item only
    }
    if (this.merchantCodes.length < 1) {
      this.isValidDeleteBtn = false;
    }
  }

  protected componentInit() {
    this.showFieldError = false;
    this.isValidDeleteBtn = false;
    this.merchantCodes = [];
    this.createForm();
  }

  protected createForm() {

    this.form = FaskFormBuilder.buildForm(
      {
        merchant: {value: null, validator: FaskValidatorFactory.noRequired()}
      }
    );
  }

  protected checkBeforeAction() {
    if (this.form != null && !this.form.isValid()) {
      this.showFieldError = true;
      return false;
    }

    return true;
  }
}
