import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FaskForm} from "../../../../../core/form/fask.form";

@Component({
  selector: 'app-merchant-api-config-fields',
  templateUrl: './merchant-api-config-fields.component.html',
  styleUrls: ['./merchant-api-config-fields.component.scss']
})
export class MerchantApiConfigFieldsComponent implements OnInit {

  @Input() title: string;
  @Input() form: FaskForm;
  @Input() showError: boolean;
  @Input() fieldReadonly = false;
  @Input() fieldPrefix: string;
  @Output() valueChange = new EventEmitter<{ fieldKey: string, value: any }>();

  constructor() {
  }

  ngOnInit() {
  }

  public onFieldChanged(event: { fieldKey: string, value: any }) {
    if (this.valueChange != null) {
      this.valueChange.emit(event);
    }
  }

}
