<app-po-detail [useSimplePOTemplate]="false" [vm]="vm">

  <div class="content-bottom-btn-bar-container" po-detail-content *ngIf="form != null"
       [ngClass]="{'show-bottom-btn-bar': isCanDelete() || isCanSave()}">
    <div class="row">

      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'base.general.info' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="margin-auto max-w-lg">

              <div class="row">
                <div class="col-12">
                  <div class="row">

                    <div class="col-auto">
                      <div class="form-group">
                        <label> {{ 'base.status' | translate }}:&nbsp;</label>
                        <app-active-status-label [po]="vm.record"></app-active-status-label>
                      </div>
                    </div>

                    <div class="col text-right" *ngIf="!vm.isNew()">
                      <button class="btn btn-dark m-b"
                              (click)="vm.enable()"
                              [disabled]="vm.isCanSave()"
                              *ngIf="vm.isCanEnable()">
                        <span class="fas fa-check"></span>
                        {{ 'base.enable' | translate }}
                      </button>

                      <button class="btn btn-danger m-b"
                              (click)="vm.deactivate()"
                              [disabled]="vm.isCanSave()"
                              *ngIf="vm.isCanDeactivate()">
                        <span class="fas fa-ban"></span>
                        {{ 'base.deactivate' | translate }}
                      </button>

                      <button class="btn btn-dark m-b"
                              (click)="vm.activate()"
                              [disabled]="vm.isCanSave()"
                              *ngIf="vm.isCanActivate()">
                        <span class="fas fa-check"></span>
                        {{ 'base.activate' | translate }}
                      </button>
                    </div>

                  </div>
                </div>

                <div *ngIf="!vm.isNew()" class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      <span>{{ 'base.created-time' | translate }}</span>
                    </label>
                    <div class="form-control" readonly>
                      <span>{{ record.createdTime | isoDateTime }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <app-form-group-text (valueChange)="vm.onFieldChanged($event)"
                                       [fieldKey]="'name'"
                                       [fieldReadonly]="vm.isReadonly() || isPoRole"
                                       [form]="form"
                                       [label]="'base.name'"
                                       [showError]="vm.showFieldError"></app-form-group-text>
                </div>

                <div class="col-12">
                  <app-form-group-text (valueChange)="onFieldChanged($event)"
                                       [fieldKey]="'code'"
                                       [fieldReadonly]="!isDraft() || isPoRole"
                                       [form]="form"
                                       [label]="'feature.merchant.code'"
                                       [showError]="showFieldError"></app-form-group-text>
                </div>

                <div class="col-12">
                  <app-form-group-text (valueChange)="onFieldChanged($event)"
                                       [fieldKey]="'channel'"
                                       [fieldReadonly]="vm.isReadonly() || isPoRole"
                                       [form]="form"
                                       [label]="'feature.merchant.channel'"
                                       [showError]="showFieldError"></app-form-group-text>
                </div>

                <div class="col-12">
                  <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'master'"
                                           [fieldReadonly]="isReadonly() || isPoRole"
                                           [form]="form"
                                           [label]="'feature.merchant.master'"
                                           [showError]="showFieldError"></app-form-group-checkbox>
                </div>

                <div class="col-12">
                  <app-form-group-text (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'merchantWhitelist'"
                                           [fieldReadonly]="!form.fields.master.value || isReadonly() || isPoRole"
                                           [form]="form"
                                           [label]="'feature.merchant.merchantWhitelist'"
                                           [showError]="showFieldError"></app-form-group-text>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.merchant.payment.label' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="margin-auto max-w-lg">
              <div class="sub-section">
                <div class="tooltips">
                  <i class="fas fa-info-circle"></i>
                  {{'feature.payment-transaction-type.guild' | translate}}
                  <span class="tooltiptext">{{'feature.payment-transaction-type.tooltip' | translate}}</span>
                </div>
              </div>

              <div class="sub-section">
                <div class="sub-section-header">
                  {{ 'feature.merchant.transaction-type' | translate }}
                </div>
                <div class="sub-section-body">
                  <table class="table table-sm table-hover transaction-type-table">
                    <thead class="thead-light">
                    <tr>
                      <th>{{ 'base.select' | translate }}</th>
                      <th class="text-center">{{ 'feature.merchant.transaction-type-name' | translate }}</th>
                      <th class="w-sm">{{ 'feature.merchant.transaction-type-code' | translate }}</th>
                      <th class="text-center">{{ 'feature.merchant.transaction-type-active' | translate }}</th>
                      <th class="text-center">{{ 'feature.merchant.transaction-type-default' | translate }}</th>
                      <th class="text-center">{{ 'feature.merchant.transaction-type-allow-card' | translate }}</th>
                      <th class="text-center">{{ 'feature.merchant.transaction-type-allow-bnpl' | translate }}</th>
                      <th class="text-center">{{ 'feature.merchant.transaction-type-auto-debit' | translate }}</th>
                      <th class="w-1x"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="text-center">
                          <app-checkbox [(ngModel)]="deleteSelectedAll"
                                        (ngModelChange) = "vm.onDeleteSelectedAll($event) "
                                        [disabled]="false"></app-checkbox>
                        </td>
                        <td class="text-left font-weight-bold">
                          {{ 'base.selectAll' | translate }}
                        </td>
                    </tr>
                    <tr
                      *ngFor="let transactionTypeForm of vm.paymentTransactionTypeForms; let transactionTypeIndex = index">
                      <td class="text-center">
                        <app-checkbox [(ngModel)]="transactionTypeForm.fields['toDeleted'].value"
                                      (ngModelChange)="vm.onPaymentTransactionTypeDeletedChanged($event, transactionTypeIndex)"
                                      [disabled]="false"></app-checkbox>
                      </td>
                      <td
                        (click)="editPaymentTransactionType(transactionTypeIndex)">{{ transactionTypeForm.fields['name'].value }}</td>
                      <td
                        (click)="editPaymentTransactionType(transactionTypeIndex)">{{ transactionTypeForm.fields['code'].value }}</td>
                      <td class="text-center">
                        <app-checkbox [(ngModel)]="transactionTypeForm.fields['active'].value"
                                      (ngModelChange)="vm.onPaymentTransactionTypeActiveChanged()"
                                      [disabled]="transactionTypeForm.fields['defaultType'].value"></app-checkbox>
                      </td>
                      <td class="text-center">
                        <app-checkbox [(ngModel)]="transactionTypeForm.fields['defaultType'].value"
                                      (ngModelChange)="vm.onPaymentTransactionTypeDefaultChanged($event, transactionTypeIndex)"
                                      [disabled]="transactionTypeForm.fields['active'].value === false"></app-checkbox>
                      </td>
                      <td class="text-center">
                        <app-checkbox [(ngModel)]="transactionTypeForm.fields['allowCard'].value"
                                      (ngModelChange)="vm.onPaymentTransactionTypeActiveChanged()"
                                      [disabled]="false"></app-checkbox>
                      </td>
                      <td class="text-center">
                        <app-checkbox [(ngModel)]="transactionTypeForm.fields['allowBNPL'].value"
                                      (ngModelChange)="vm.onPaymentTransactionTypeActiveChanged()"
                                      [disabled]="false"></app-checkbox>
                      </td>
                      <td class="text-center">
                        <app-checkbox [(ngModel)]="transactionTypeForm.fields['autoDebit'].value"
                                      (ngModelChange)="vm.onPaymentTransactionTypeActiveChanged()"
                                      [disabled]="!this.form.fields['useAutoDebit'].value || vm.record.activeStatus == 'INACTIVE'"></app-checkbox>
                      </td>
                      <td (click)="editPaymentTransactionType(transactionTypeIndex)" class="arrow-forward">
                        <i class="icon ion-ios-arrow-forward"></i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col"></div>
                    <div class="col-auto m-b text-right">
                      <button *ngIf="deleteSelectedPT > 0" class="btn btn-danger" (click)="deletePaymentTransactionType()">
                        {{ 'base.deletePTT' | translate}}
                      </button>
                    </div>
                    <div class="col-auto m-b text-right">
                      <button class="btn btn-primary" (click)="addPaymentTransactionType()">
                        {{ 'feature.merchant.add-transaction-type' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sub-section">
                <div class="sub-section-header">
                  {{ 'feature.merchant.instant-payment.label' | translate }}
                </div>
                <div class="sub-section-body">
                  <div class="row">
                    <div class="col-12">
                      <app-form-group-switch (valueChange)="onFieldChanged($event)"
                                             [fieldKey]="'useInstantPayment'"
                                             [fieldReadonly]="vm.isReadonly()"
                                             [form]="form"
                                             [label]="'feature.merchant.instant-payment.enabled'"
                                             [showError]="showFieldError"></app-form-group-switch>
<!--                      <app-form-group-switch (valueChange)="onFieldChanged($event)"-->
<!--                                             [fieldKey]="'allowMultiSourcePayment'"-->
<!--                                             [fieldReadonly]="vm.isReadonly()"-->
<!--                                             [form]="form"-->
<!--                                             [label]="'feature.merchant.use-multi-source-payment'"-->
<!--                                             [showError]="showFieldError"></app-form-group-switch>-->
                    </div>
                    <div class="col-12">
                      <app-merchant-api-config-fields [title]="'feature.merchant.payment.payment-callback-url1'"
                                                      [fieldPrefix]="'callbackAfterPay'"
                                                      [fieldReadonly]="!form.fields.useInstantPayment.value || vm.isReadonly()"
                                                      [form]="instantPaymentForm"
                                                      [showError]="showFieldError"
                                                      (valueChange)="onFieldChanged($event)"></app-merchant-api-config-fields>

                      <app-merchant-api-config-fields [title]="'feature.merchant.payment.payment-callback-url2'"
                                                      [fieldPrefix]="'callbackAfterPayV2'"
                                                      [fieldReadonly]="!form.fields.useInstantPayment.value || vm.isReadonly()"
                                                      [form]="instantPaymentForm"
                                                      [showError]="showFieldError"
                                                      (valueChange)="onFieldChanged($event)"></app-merchant-api-config-fields>

                      <app-merchant-api-config-fields [title]="'feature.merchant.payment.callbackAfterRefund'"
                                                      [fieldPrefix]="'callbackAfterRefund'"
                                                      [fieldReadonly]="!form.fields.useInstantPayment.value || vm.isReadonly()"
                                                      [form]="instantPaymentForm"
                                                      [showError]="showFieldError"
                                                      (valueChange)="onFieldChanged($event)"></app-merchant-api-config-fields>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sub-section">
                <div class="sub-section-header">
                  {{ 'feature.merchant.auto-debit.label' | translate }}
                </div>

                <div class="sub-section-body">
                  <div class="row">
                    <div class="col-12">
                      <app-form-group-switch (valueChange)="onFieldChanged($event)"
                                             [fieldKey]="'useAutoDebit'"
                                             [fieldReadonly]="vm.isReadonly()"
                                             [form]="form"
                                             [label]="'feature.merchant.auto-debit.enabled'"
                                             [showError]="showFieldError"></app-form-group-switch>
                    </div>

                    <div class="col-12">
                      <app-merchant-api-config-fields [title]="'feature.merchant.auto-debit.callbackAfterRegister'"
                                                      [fieldPrefix]="'callbackAfterRegister'"
                                                      [fieldReadonly]="!form.fields.useAutoDebit.value || vm.isReadonly()"
                                                      [form]="autoDebitForm"
                                                      [showError]="showFieldError"
                                                      (valueChange)="onFieldChanged($event)"></app-merchant-api-config-fields>
                    </div>
                    <div class="col-12">
                      <app-merchant-api-config-fields [title]="'feature.merchant.auto-debit.callbackAfterPaymentPeriod'"
                                                      [fieldPrefix]="'callbackAfterPayPeriod'"
                                                      [fieldReadonly]="!form.fields.useAutoDebit.value || vm.isReadonly()"
                                                      [form]="autoDebitForm"
                                                      [showError]="showFieldError"
                                                      (valueChange)="onFieldChanged($event)"></app-merchant-api-config-fields>
                    </div>
                    <div class="col-12">
                      <app-merchant-api-config-fields [title]="'feature.merchant.auto-debit.getBillUrl'"
                                                      [fieldPrefix]="'getMerchantBill'"
                                                      [fieldReadonly]="!form.fields.useAutoDebit.value || vm.isReadonly()"
                                                      [form]="autoDebitForm"
                                                      [showError]="showFieldError"
                                                      (valueChange)="onFieldChanged($event)"></app-merchant-api-config-fields>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card" *ngIf="!vm.isNew()">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.merchant.api-security.label' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="margin-auto max-w-lg">
              <div class="sub-section">
                <div class="sub-section-header">
                  {{ 'feature.merchant.api-security.inbound' | translate }}
                </div>
                <div class="sub-section-body">
                  <div class="row">
                    <div  class="col-12">
                      <div class="form-group">
                        <label class="form-control-label">
                          <span>{{ 'feature.merchant.secret' | translate }}</span>
                        </label>
                        <div class="input-group">
                          <div class="form-control" readonly style="min-height: 38px; height: auto;">
                            <span>{{ record.secret }}</span>
                          </div>
                          <div class="input-group-append">
                            <button (click)="copyMerchantSecret()" class="btn btn-primary" type="button" [disabled]="isPoRole">
                              <i class="fas fa-copy"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="m-b text-right">
                        <button class="btn btn-danger" (click)="regenerateMerchantSecret()" [disabled]="isPoRole">
                          {{ 'feature.merchant.regenerate-secret' | translate}}
                        </button>
                      </div>
                    </div>

                    <div class="col-12">
                      <app-form-group-textarea (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'ipWhitelist'"
                                               [fieldReadonly]="vm.isReadonly()"
                                               [form]="form"
                                               [label]="'feature.merchant.ip-whitelist'"
                                               [showError]="showFieldError"
                                               [placeholder]="'feature.merchant.cidr-placeholder'"
                                               [errorMessage]="'feature.merchant.cidr-invalid'"
                                               [fieldReadonly]="isPoRole"></app-form-group-textarea>
                    </div>
                  </div>
                </div>
              </div>


              <div class="sub-section">
                <div class="sub-section-header">
                  {{ 'feature.merchant.api-security.outbound' | translate }}
                </div>
                <div class="sub-section-body">
                  <div class="row">
                    <div class="col-12">
                      <app-form-group-text (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'callbackCheckSumSecret'"
                                           [fieldReadonly]="vm.isReadonly() || isPoRole"
                                           [form]="form"
                                           [label]="'feature.merchant.callback-check-sum-secret'"
                                           [showError]="showFieldError"></app-form-group-text>
                    </div>

                    <div class="col-12">
                      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                                               [fieldKey]="'useChecksumSha256'"
                                               [fieldReadonly]="isReadonly() || isPoRole"
                                               [form]="form"
                                               [label]="'feature.merchant.useChecksumSha256'"
                                               [showError]="showFieldError"></app-form-group-checkbox>
                    </div>

                    <div class="col-6">
                      <app-form-group-text (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'callbackHeaderKey1'"
                                           [fieldReadonly]="vm.isReadonly() || isPoRole"
                                           [form]="form"
                                           [label]="'feature.merchant.callback-header-key-1'"
                                           [showError]="showFieldError"></app-form-group-text>
                    </div>

                    <div class="col-6">
                      <app-form-group-text (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'callbackHeaderValue1'"
                                           [fieldReadonly]="vm.isReadonly() || isPoRole"
                                           [form]="form"
                                           [label]="'feature.merchant.callback-header-value-1'"
                                           [showError]="showFieldError"></app-form-group-text>
                    </div>

                    <div class="col-6">
                      <app-form-group-text (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'callbackHeaderKey2'"
                                           [fieldReadonly]="vm.isReadonly() || isPoRole"
                                           [form]="form"
                                           [label]="'feature.merchant.callback-header-key-2'"
                                           [showError]="showFieldError"></app-form-group-text>
                    </div>

                    <div class="col-6">
                      <app-form-group-text (valueChange)="onFieldChanged($event)"
                                           [fieldKey]="'callbackHeaderValue2'"
                                           [fieldReadonly]="vm.isReadonly() || isPoRole"
                                           [form]="form"
                                           [label]="'feature.merchant.callback-header-value-2'"
                                           [showError]="showFieldError"></app-form-group-text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header bg-dark">
            <h4 class="m-b-0 text-white">{{ 'feature.merchant.login-session' | translate }}</h4>
          </div>
          <div class="card-body">
            <div class="margin-auto max-w-lg">
              <div class="row">
                <div class="col-12">
                  <app-form-group-switch (valueChange)="onFieldChanged($event)"
                                         [fieldKey]="'shareLoginSession'"
                                         [fieldReadonly]="vm.isReadonly() || isPoRole"
                                         [form]="form"
                                         [label]="'feature.merchant.share-login-session'"
                                         [showError]="showFieldError"></app-form-group-switch>
                </div>

                <div class="col-12" *ngIf="form.fields.shareLoginSession.value">
                  <div class="form-group">
                    <label class="form-control-label">
                      {{ 'feature.merchant.share-login-fields' | translate }}
                    </label>
                    <table class="table table-sm table-hover transaction-type-table">
                      <thead class="thead-light">
                      <tr>
                        <th>{{ 'feature.merchant.share-login-field' | translate }}</th>
                        <th class="text-center w-xs"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let field of vm.shareLoginFields;">
                        <td>{{ field.name }}</td>
                        <td class="text-center">
                          <app-checkbox [(ngModel)]="field.select"
                                        [disabled]="field.readonly || !form.fields.shareLoginSession.value || isPoRole"
                                        (ngModelChange)="onFieldChanged(null)"></app-checkbox>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="content-bottom-btn-bar">
      <div class="row small-gutters">
        <div class="col"></div>
        <div class="col-auto" *ngIf="isCanBack()">
          <button class="btn btn-warning" (click)="back()">
            {{ (this.isCanSave() ? 'base.btn.cancel' : 'base.btn.back') | translate }}
          </button>
        </div>
        <div class="col-auto" *ngIf="isCanDelete()">
          <button class="btn btn-danger" (click)="delete()">
            {{ 'base.delete' | translate }}
          </button>
        </div>
        <div class="col-auto" *ngIf="isCanSave()">
          <button class="btn btn-success" (click)="save()">
            {{ 'base.btn.save' | translate }}
          </button>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>

</app-po-detail>

