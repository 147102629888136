import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {UtilityService} from './utility.service';
import {Observable, ReplaySubject} from 'rxjs';
import {SettingsService} from '../../settings/settings.service';

@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(private utilityService: UtilityService, private httpClient: HttpClient, private router: Router, private settingsService: SettingsService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.utilityService.getTokenInfo() == null) {
      this.processWhenNotLoggedIn(state);
      return false;
    }

    if (this.utilityService.getUserInfo() != null) {
      const userInfo = this.utilityService.getUserInfo();
      return this.checkPermission(route, userInfo);
    } else {
      const subject = new ReplaySubject<boolean>();

      this.utilityService.loadUserInfoFromServer(
        null,
        (userInfo) => {
          if (userInfo == null) {
            this.processWhenNotLoggedIn(state);
            subject.next(false);
          }

          this.utilityService.setUserInfo(userInfo);
          subject.next(this.checkPermission(route, userInfo));
        },
        () => {
          this.utilityService.clearSessionData();
          this.utilityService.clearTokenInfo();

          const url = state.url;
          if (url == null || _.isEmpty(url) || url === '/') {
            this.router.navigate(['/login']);
          } else {
            const queryParams = {
              'ru': url
            };

            this.router.navigate(['/login'], {queryParams: queryParams});
          }

          subject.next(false);
        }
      );

      return subject;
    }
  }

  // PRIVATE
  private processWhenNotLoggedIn(state: RouterStateSnapshot): void {
    this.utilityService.clearSessionData();
    this.utilityService.clearUserInfo();

    const url = state.url;
    if (url == null || _.isEmpty(url) || url === '/') {
      this.router.navigate(['/login']);
    } else {
      const queryParams = {
        'ru': url
      };

      this.router.navigate(['/login'], {queryParams: queryParams});
    }
  }

  private checkPermission(route: ActivatedRouteSnapshot, userInfo): boolean {
    if (userInfo == null) {
      this.router.navigate(['/404']);
      return false;
    }
    if (route.data == null) {
      return true;
    }

    if (route.data.roles != null && !_.includes(route.data.roles, userInfo.role)) {
      this.router.navigate(['/404']);
      return false;
    }

    if (userInfo.needCheckFeature) {
      if (route.data.features != null) {
        let valid = false;

        for (const expectedFeature of route.data.features) {
          if (_.includes(userInfo.features, expectedFeature)) {
            valid = true;
            break;
          }
        }

        if (!valid) {
          this.router.navigate(['/404']);
          return false;
        }
      }
    }

    return true;
  }

}
