<app-po-list [noFilter]="true" [vm]="vm">

  <table class="table" table-content>
    <thead>
    <tr>
      <th>{{ 'feature.auto-debit.code' | translate }}</th>
      <th>{{ 'base.created-time' | translate }}</th>
      <th>{{ 'feature.auto-debit.merchant-code' | translate }}</th>
      <th>{{ 'feature.auto-debit.cif' | translate }}</th>
      <th class="text-right">{{ 'feature.auto-debit.amount' | translate }}</th>
      <th>{{ 'feature.auto-debit.contract-code' | translate }}</th>
      <th class="w-auto-debit-status text-center">{{ 'base.status' | translate }}</th>
      <th class="w-1x"></th>
    </tr>
    </thead>
    <tbody>
    <tr (click)="vm.editRecord(record)" *ngFor="let record of vm.records">
      <td>{{ record.id }}</td>
      <td>{{ record.createdTime | isoDateTime }}</td>
      <td>{{ record.merchantCode }}</td>
      <td>{{ record.cif }}</td>
      <td class="text-right">{{ record.amount | myInteger }}</td>
      <td>{{ record.contractCode }}</td>
      <td class="text-center">
        <app-auto-debit-status-label [status]="record.status"></app-auto-debit-status-label>
      </td>
      <td class="arrow-forward">
        <i class="icon ion-ios-arrow-forward"></i>
      </td>
    </tr>
    </tbody>
  </table>

</app-po-list>

