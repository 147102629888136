import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-active-status-control',
  templateUrl: './active-status-control.component.html',
  styleUrls: ['./active-status-control.component.scss']
})
export class ActiveStatusControlComponent implements OnInit {

  @Input() active: boolean;
  @Input() activeLabel = 'base.status.ACTIVE';
  @Input() inactiveLabel = 'base.status.INACTIVE';
  @Input() activateBtnLabel = 'base.activate';
  @Input() deactivateBtnLabel = 'base.deactivate';
  @Input() readonly = false;
  @Output() activate = new EventEmitter<any>();
  @Output() deactivate = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  onActivateBtnClicked() {
    this.activate.emit();
  }

  onDeactivateBtnClicked() {
    this.deactivate.emit();
  }

}
