import Utils from '../utils/utils';
import * as _ from 'lodash';
import * as moment from 'moment';
import {FormGroupPhotoModel} from './form-group-photo.model';

export class FaskForm {

  public fields: { [key: string]: FaskField };

  constructor() {
    this.fields = {};
  }

  public isValid() {
    if (this.fields != null) {
      for (const key of Object.keys(this.fields)) {
        const field = this.fields[key];
        if (field != null) {
          if (!field.isValid()) {
            console.log('Field ' + field.key + ' is invalid');
            return false;
          }
        }
      }
    }

    return true;
  }

  public isInvalid(): boolean {
    return !this.isValid();
  }

  public valueChanged(key: string, value: any) {
    for (const fieldKey of Object.keys(this.fields)) {
      if (key === fieldKey) {
        continue;
      }

      const field = this.fields[fieldKey];
      if (field.relatedKeys != null && _.includes(field.relatedKeys, key)) {
        field.updateErrors();
      }
      if (field.requiredDependingKey === key) {
        field.changeValidatorRequired(value, field.noRequiredValue);
      }
    }
  }

}

export class FaskField {

  private _value: any = null;
  get value(): any {
    return this._value;
  }

  set value(_value: any) {
    this._value = _value;
    this.updateErrors();
    this.form.valueChanged(this.key, _value);
  }

  private valid: boolean;

  constructor(public form: FaskForm,
              public key: string, p_value: any,
              public validator: FaskValidator,
              public relatedKeys?: string[],
              public requiredDependingKey?: string,
              public noRequiredValue?: any) {
    this.valid = true;
    this.value = p_value;
  }

  public isValid(): boolean {
    return this.valid;
  }

  public isInvalid(): boolean {
    return !this.isValid();
  }

  public getValidator(): FaskValidator {
    if (this.validator != null) {
      return this.validator;
    }

    return null;
  }

  public updateErrors() {
    if (this.validator == null) {
      this.valid = true;
      return;
    } else {
      this.valid = this.validator.isValid(this, this.form);
    }
  }

  public changeValidatorRequired(required: boolean, noRequiredValue = null) {
    if (this.validator.required === required) {
      return;
    }

    if (required) {
      this.validator.required = true;
      this.updateErrors();
    } else {
      this.validator.required = false;
      this.value = noRequiredValue;
    }
  }

}

export class FaskValidator {

  constructor(private key: string,
              private validateFn: (field: FaskField, form: FaskForm) => boolean,
              public required: boolean,
              public params?: any) {
  }

  public getKey() {
    return this.key;
  }

  public isValid(field: FaskField, form: FaskForm) {
    return this.validateFn(field, form);
  }

}

export class FaskValidatorFactory {

  public static noValidator(): FaskValidator {
    return new FaskValidator('no-validator', (field: FaskField, form: FaskForm) => {
      return true;
    }, false);
  }

  public static required(required: boolean = true): FaskValidator {
    return new FaskValidator('required', (field: FaskField, form: FaskForm) => {
      if (field.validator.required) {
        return field.value != null && !Utils.isEmptyString(field.value);
      }

      return true;
    }, required);
  }

  public static noRequired(): FaskValidator {
    return new FaskValidator('no-required', (field: FaskField, form: FaskForm) => {
      return true;
    }, false);
  }

  public static photoRequired(required: boolean = true): FaskValidator {
    return new FaskValidator('required', (field: FaskField, form: FaskForm) => {
      if (field.validator.required) {
        return field.value != null && field.value instanceof FormGroupPhotoModel && (field.value.oldPhotoUrl != null || field.value.newPhotoBase64 != null);
      }

      return true;
    }, required);
  }

  public static text(required: boolean, min: number, max: number, regExp?: RegExp): FaskValidator {
    return this.getTextFaskValidator('text', required, min, max, regExp);
  }

  public static number(required: boolean, min?: number, max?: number, excludeMin?: boolean, excludeMax?: boolean): FaskValidator {
    excludeMin = excludeMin == null ? false : excludeMin;
    excludeMax = excludeMax == null ? false : excludeMax;

    return new FaskValidator('number', (field: FaskField, form: FaskForm) => {
      if (field != null && field.value != null) {
        const value = field.value;

        return (min == null || value > min || (!excludeMin && value === min)) &&
          (max == null || value < max || (!excludeMax && value === max));
      }

      return !field.validator.required;
    }, required, {min: min, max: max, excludeMin: excludeMin, excludeMax: excludeMax});
  }

  public static wrap(validator: FaskValidator, moreCheck: (field: FaskField, form: FaskForm) => boolean) {
    return new FaskValidator(validator.getKey(), (field: FaskField, form: FaskForm) => {
      const firstCheck = validator.isValid(field, form);
      if (!firstCheck) {
        return false;
      }

      return moreCheck(field, form);
    }, validator.required, validator.params);
  }

  public static email(required: boolean): FaskValidator {
    return this.getTextFaskValidator('email', required, null, null, null, (value) => {
      return Utils.validateEmail(value);
    });
  }

  public static link(required: boolean): FaskValidator {
    return this.getTextFaskValidator('link', required, null, null, null, (value) => {
      return Utils.validateLink(value);
    });
  }

  public static phone(required: boolean): FaskValidator {
    return this.getTextFaskValidator('phone', required, null, null, null, (value) => {
      return Utils.validatePhone(value);
    });
  }

  public static username(required: boolean): FaskValidator {
    return this.getTextFaskValidator('username', required, null, null, null, (value) => {
      return Utils.validateUsername(value);
    });
  }

  public static passwordLogin(required: boolean): FaskValidator {
    return FaskValidatorFactory.text(required, 6, 32);
  }

  public static password(required: boolean): FaskValidator {
    return FaskValidatorFactory.text(required, 8, 30);
  }

  public static code(required: boolean): FaskValidator {
    return this.getTextFaskValidator('code', required, 3, 100, null, (value) => {
      return Utils.validateCode(value);
    });
  }

  public static otp(required: boolean): FaskValidator {
    return this.getTextFaskValidator('otp', required, null, null, null, (value) => {
      return Utils.validateOtp(value);
    });
  }

  public static cidr(required: boolean): FaskValidator {
    return this.getTextFaskValidator('cidr', required, null, null, null, (value) => {
      return Utils.validateCidr(value);
    });
  }

  public static cidrList(required: boolean): FaskValidator {
    return this.getTextFaskValidator('cidr', required, null, null, null, (value) => {
      return Utils.validateCidrList(value);
    });
  }

  public static stringList(required: boolean): FaskValidator {
    return this.getTextFaskValidator('stringList', required, null, null, null, (value) => {
      return Utils.validateStringList(value);
    });
  }


  public static name_(required: boolean): FaskValidator {
    return FaskValidatorFactory.text(required, 1, 256);
  }

  public static fullname(required: boolean): FaskValidator {
    return FaskValidatorFactory.text(required, 1, 100);
  }

  public static date(required: boolean, min?: moment.Moment, max?: moment.Moment, excludeMin: boolean = false, excludeMax: boolean = false): FaskValidator {
    return new FaskValidator('date', (field: FaskField, form: FaskForm) => {
      if (field != null && field.value != null) {
        const value = field.value;

        if (min != null) {
          if (excludeMin) {
            if (!min.isBefore(value, 'day')) {
              return false;
            }
          } else {
            if (min.isAfter(value, 'day')) {
              return false;
            }
          }
        }

        if (max != null) {
          if (excludeMax) {
            if (!moment(value).isBefore(max, 'day')) {
              return false;
            }
          } else {
            if (moment(value).isAfter(max, 'day')) {
              return false;
            }
          }
        }

        return true;
      }

      return !field.validator.required;
    }, required, {min: min, max: max, excludeMin: excludeMin, excludeMax: excludeMax});
  }

  // PRIVATE
  private static getTextFaskValidator(key: string,
                                      required: boolean,
                                      min?: number,
                                      max?: number,
                                      regExp?: RegExp,
                                      textValidateFn?: (value: string) => boolean) {
    return new FaskValidator(key, (field: FaskField, form: FaskForm) => {
      if (field != null && !Utils.isEmptyString(field.value)) {
        const value = field.value.toString().trim();

        if (min != null && value.length < min) {
          return false;
        }

        if (max != null && value.length > max) {
          return false;
        }

        if (regExp != null && !regExp.test(value)) {
          return false;
        }

        if (textValidateFn != null && !textValidateFn(value)) {
          return false;
        }

        return true;
      }

      return !field.validator.required;
    }, required, {min: min, max: max});
  }

}

export class FaskFormBuilder {

  public static buildForm(config: {
    [key: string]: {
      value: any,
      validator: FaskValidator,
      relatedKeys?: string[],
      requiredDependingKey?: string,
      noRequiredValue?: any
    }
  }): FaskForm {
    const form = new FaskForm();

    if (config != null) {
      for (const key of Object.keys(config)) {
        const fieldConfig = config[key];
        if (fieldConfig != null) {
          form.fields[key] = new FaskField(
            form, key,
            fieldConfig.value,
            fieldConfig.validator,
            fieldConfig.relatedKeys,
            fieldConfig.requiredDependingKey,
            fieldConfig.noRequiredValue
          );
        }
      }
    }

    return form;
  }

}
