import {AbstractComponent} from './abstract.component';
import {Injector} from '@angular/core';

export abstract class POListAbstractComponent extends AbstractComponent {

  public records: any[];
  public keepContentDOM = false;
  public activeStatusList = [];

  protected constructor(injector: Injector,
                        public title: string,
                        protected getListPath: string,
                        protected detailRoute: string) {
    super(injector, title);
  }

  protected componentInit() {
    if (this.params['activeStatus'] == null) {
      this.params['activeStatus'] = this.optionAllId;
    }

    this.activeStatusList = [
      this.getOptionAll(),
      {id: 'DRAFT', name: this.translate('base.active-status.DRAFT')},
      {id: 'ACTIVE', name: this.translate('base.active-status.ACTIVE')},
      {id: 'INACTIVE', name: this.translate('base.active-status.INACTIVE')}
    ];

    this.reloadData();
  }

  // -----------FUNCTION TO BE OVERRIDE--------
  // ------------------------------------------
  // ------------------------------------------
  protected addMoreBodyParams(body: any): any {
    return body;
  }

  protected onLoadSuccess(response: any) {
    // DO NOTHING
  }

  protected onLoadFailure(error: any) {
    // DO NOTHING
  }

  protected getImportExcelState() {
    return null;
  }

  getImportExcelStates(): { name: string, state: string, id?: any, params?: any }[] {
    return null;
  }

  isCanBack() {
    return false;
  }

  isCanCreate() {
    return true;
  }

  isUsePagination() {
    return true;
  }

  // ------------------------------------------
  // ------------------------------------------
  // ------------------------------------------

  reloadData() {
    this.records = [];

    let _body = {
      pageSize: this.paginationData.pageSize,
      pageNumber: this.paginationData.pageNumber,
      search: this.params['searchText']
    };

    if (this.params['activeStatus'] != null && this.params['activeStatus'] !== this.optionAllId) {
      _body['activeStatus'] = this.params['activeStatus'];
    }

    _body = this.addMoreBodyParams(_body);

    this.httpPostOrPut(
      this.getApiUrl(this.getListPath),
      {},
      true,
      'data',
      _body,
      (response) => {
        this.records = response.list;
        this.paginationData.count = response.count;
        this.paginationData.totalAmount = response.totalAmount;
        this.onLoadSuccess(response);
      },
      (error) => {
        this.loadingStatus.setError(true);
        this.onLoadFailure(error);
      },
      false
    );
  }

  public onSearch() {
    this.params['pageNumber'] = 1;
    this.reloadState();
  }

  public onSearchText(searchText) {
    this.params['searchText'] = searchText;
    this.onSearch();
  }

  public hasImportExcel() {
    return this.getImportExcelState() != null;
  }

  public hasExportExcel() {
    return false;
  }

  public goToExportExcel() {
    return null;
  }

  public goToImportExcel() {
    if (this.hasImportExcel()) {
      this.goTo(this.getImportExcelState());
    }
  }

  public hasMultipleImportExcel() {
    return !this.checkEmptyList(this.getImportExcelStates());
  }

  public goToMultiImportExcel(index) {
    const importModel = this.getImportExcelStates()[index];
    if (importModel != null) {
      this.goTo(importModel.state, importModel.id, importModel.params);
    }
  }

  public createNewRecord() {
    this.goTo(this.detailRoute, null, {new: true});
  }

  public editRecord(record) {
    this.goTo(this.detailRoute, record.id);
  }

}
