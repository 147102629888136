<div class="modal-header">
  <h4 class="modal-title">
    {{ 'feature.crop-image-modal.title' | translate }}
  </h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <image-cropper
        style="max-height: 400px"
        [imageBase64]="imageBase64"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatio"
        [resizeToWidth]="resizeToWidth"
        [resizeToHeight]="resizeToHeight"
        [imageQuality]="100"
        format="jpeg"
        (imageCropped)="imageCropped($event)"></image-cropper>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-success w-xs" (click)="save()">
    {{ 'base.save' | translate }}
  </button>
  <button class="btn btn-danger w-xs" (click)="close()">
    {{ 'base.close' | translate }}
  </button>
</div>
