import {Component, Input, OnInit} from '@angular/core';
import {PODetailAbstractComponent} from '../../abstract/po-detail.abstract.component';

@Component({
  selector: 'app-po-detail',
  templateUrl: './po-detail.component.html'
})
export class PODetailComponent implements OnInit {

  @Input() vm: PODetailAbstractComponent;
  @Input() useSimplePOTemplate = false;
  @Input() useSimplePOTemplateFullWidth = false;
  @Input() useSimplePOTemplateFieldStatus = true;
  @Input() useSimplePOTemplateFieldName = true;

  constructor() {
  }

  ngOnInit() {
  }

}
