import { Component, Injector, OnInit } from '@angular/core';
import { PODetailAbstractComponent } from 'src/app/core/components/abstract/po-detail.abstract.component';
import { FaskFormBuilder } from 'src/app/core/form/fask.form';

@Component({
  selector: 'app-auto-debit-detail',
  templateUrl: './auto-debit-detail.component.html',
  styleUrls: ['./auto-debit-detail.component.scss']
})
export class AutoDebitDetailComponent extends PODetailAbstractComponent {

  vm = this;

  constructor(injector: Injector) {
    super(injector, 'feature.auto-debit.title', '/api/admin/auto-debit', 'auto-debit-list');
  }

  back() {
    if (this.params.hasOwnProperty('returnState')) {
      const params = {};
      const returnToState = this.params['returnState'];
      if (this.params.hasOwnProperty('returnParams')) {
        params['returnParams'] = this.params['returnParams'];
      }
      this.goTo(returnToState, null, params, true);
    } else {
      super.back();
    }
  }

  protected createForm(): void {
    this.form = FaskFormBuilder.buildForm({});
  }

  protected getBodyToSave(): any {
    return {};
  }

}
