<div class="form-group app-form-group-color" [ngClass]="{ 'has-danger': isAddDangerClass() }" *ngIf="getField() != null">

  <label class="form-control-label">
    {{ label | translate }}<span *ngIf="getValidator().required">*</span>
  </label>

  <div class="input-group">
    <input type="text"
           class="form-control"
           [ngClass]="{ 'text-white' : isDarkColor() }"
           [style.background]="getField().value"
           [style.border-color]="getField().value"
           [disabled]="true"
           [placeholder]="'form-group.color.no-color' | translate"
           [(ngModel)]="getField().value">

    <div class="input-group-append">
      <button class="btn btn-info btn-palette"
              type="button"
              [(colorPicker)]="getField().value"
              [cpOutputFormat]="'hex'"
              (colorPickerChange)="onColorChanged($event)"
              [cpDisabled]="fieldDisabled || fieldReadonly"
              [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
              [disabled]="fieldDisabled || fieldReadonly">
        <i class="fas fa-palette"></i>
      </button>
    </div>
  </div>

  <div class="form-control-feedback" *ngIf="isShowTextError()">

    <span *ngIf="useDefaultErrorMessage()">
      <span>{{ 'form.error.message.required' | translate:getValidator().params }}</span>
    </span>

    <span *ngIf="!useDefaultErrorMessage()">
      {{ errorMessage | translate:getValidator().params }}
    </span>

  </div>

</div>
