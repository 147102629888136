<div class="modal-header">
  <h4 class="modal-title">
    {{ 'feature.reset-password.title' | translate }}
  </h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <app-form-group-text [password]="!passwordGenerated"
                           [form]="form"
                           [fieldKey]="'newPassword1'"
                           [label]="'feature.reset-password.new-password.1'"
                           [showError]="showFieldError"></app-form-group-text>
    </div>

    <div class="col-12">
      <app-form-group-text [password]="!passwordGenerated"
                           [form]="form"
                           [fieldKey]="'newPassword2'"
                           [label]="'feature.reset-password.new-password.2'"
                           [showError]="showFieldError"
                           [errorMessage]="'feature.reset-password.new-password.not-match'"></app-form-group-text>
    </div>

    <div class="col-12" *ngIf="passwordGenerated">
      <i class="text-danger">*&nbsp;{{ 'feature.reset-password.remember-password-warning' | translate }}</i>
    </div>
  </div>
</div>

<div style="padding: 1rem">
  <div class="row">
    <div class="col-auto">
      <button class="btn btn-primary" (click)="generateStrongPassword()">
        {{ 'feature.reset-password.generate-password' | translate }}
      </button>
    </div>
    <div class="col text-right">
      <button class="btn btn-success w-xs" (click)="save()">
        {{ 'base.save' | translate }}
      </button>
      <button class="btn btn-danger w-xs m-l" (click)="close()">
        {{ 'base.close' | translate }}
      </button>
    </div>
  </div>
</div>
