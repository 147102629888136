import * as io from 'socket.io-client';
import {Observer, Subject, Subscription} from 'rxjs';

export class SocketIoHolder {

  private socket: SocketIOClient.Socket;
  private token: String;
  private serverNotification: Subject<SocketServerNotification>;

  login(url: string, token: string) {
    if (this.serverNotification != null) {
      this.serverNotification.complete();
    }
    this.serverNotification = new Subject<SocketServerNotification>();

    if (this.socket != null) {
      this.socket.disconnect();
    }

    this.token = token;
    this.socket = io.connect(url);
    this.socket.on('connect', () => {
      console.log(this.socket.id + ' connected');
      this.socket.emit('login', {token: this.token});

      this.socket.on('server-notification', (data: SocketServerNotification) => {
        this.serverNotification.next(data);
      });
    });
  }

  logout() {
    if (this.socket != null) {
      this.socket.disconnect();
    }
    this.socket = null;
  }

  subscribeServerNotification(next: (value: SocketServerNotification) => void): Subscription {
    return this.serverNotification.subscribe(next);
  }

  emitEkycStaffStatus(status: EkycStatus) {
    this.socket.emit('ekyc-staff-status', status);
  }

}

export class SocketServerNotification {
  type: string;
  data: any;
}

export enum EkycStatus {
  AVAILABLE = 'AVAILABLE',
  IN_CALL = 'IN_CALL',
  OUT = 'OUT',
}


