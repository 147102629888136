<div class="form-group app-form-group-photo-crop" [ngClass]="{ 'has-danger': isAddDangerClass() }"
     *ngIf="getField() != null">

  <label class="form-control-label">
    <span>{{ label | translate }}</span>
    <span *ngIf="getValidator().required">*</span>
  </label>

  <div>
    <div class="photo-placeholder" [ngStyle]="{'padding-bottom': getPaddingBottom() + '%'}">
      <img class="photo-item" alt="no-photo" src="../assets/images/no-photo.svg" *ngIf="!isHasPhoto()">

      <img class="photo-item"
           alt="photo"
           [src]="getPhotoSrc()"
           *ngIf="isHasPhoto()">
    </div>
  </div>

  <div class="m-t" *ngIf="!isHasPhoto() && !isReadonly()">
    <app-input-file buttonClass="btn btn-info w-full"
                    [accept]="'image/x-png,image/jpeg'"
                    [openChooserEvent]="openPhotoChooserSubject.asObservable()"
                    (fileSelect)="onPhotoSelected($event)">
      <i class="fas fa-camera"></i>&nbsp;
      <span>{{ 'base.add-photo' | translate }}</span>
    </app-input-file>
  </div>

  <div class="m-t row small-gutters" *ngIf="isHasPhoto() && !isReadonly()">
    <div class="col-6">
      <app-input-file buttonClass="btn btn-info w-full"
                      [accept]="'image/x-png,image/jpeg'"
                      [openChooserEvent]="openPhotoChooserSubject.asObservable()"
                      (fileSelect)="onPhotoSelected($event)">
        <i class="fas fa-camera"></i>
        <span>&nbsp;{{ 'base.change-photo' | translate }}</span>
      </app-input-file>
    </div>

    <div class="col-6">
      <button class="btn btn-danger w-full" (click)="removePhoto()">
        <i class="fas fa-times"></i>
        <span>&nbsp;{{ 'base.remove-photo' | translate }}</span>
      </button>
    </div>
  </div>

</div>
