import { Component, Injector, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractComponent } from 'src/app/core/components/abstract/abstract.component';
import { FaskForm, FaskFormBuilder, FaskValidatorFactory } from 'src/app/core/form/fask.form';
import Utils from 'src/app/core/utils/utils';

@Component({
  selector: 'app-auto-debit-search',
  templateUrl: './auto-debit-search.component.html',
  styleUrls: ['./auto-debit-search.component.scss']
})
export class AutoDebitSearchComponent extends AbstractComponent {

  vm = this;
  form: FaskForm;
  showFieldError = false;

  statusOptions: any[];
  title = 'feature.auto-debit.title';

  constructor(injector: Injector) {
    super(injector);
  }

  loadMerchants = (text: string): Observable<any>  => {
    const url = this.getApiUrl('/api/admin/merchant/search');
    return this.httpClient
      .post(url, {
        search: text
      })
      .pipe(
        map((result: any) => {
          _.forEach(result.list, (record) => {
            record.displayValue = record.name + ' (' + record.code + ')';
          });
          return result.list;
        })
      );
  }

  // noinspection JSUnusedLocalSymbols
  onFieldChanged(event: { fieldKey: string; value: any }) {
    // DO NOTHING
  }

  search() {
    if (!this.checkBeforeAction()) {
      return;
    }

    const params = {
      merchant: this.form.fields['merchant'].value != null ? this.form.fields['merchant'].value : null,
      merchantCode: this.form.fields['merchant'].value != null ? this.form.fields['merchant'].value.code : null,
      cif: this.form.fields['cif'].value,
      contractCode: this.form.fields['contractCode'].value,
      status: this.form.fields['status'].value,
      autoDebitId: this.form.fields['autoDebitId'].value,
      fromDate: Utils.createIsoDate(this.form.fields['fromDate'].value),
      toDate: Utils.createIsoDate(this.form.fields['toDate'].value)
    };

    this.goTo('auto-debit-list', null, params);
  }

  protected componentInit() {
    this.showFieldError = false;
    this.statusOptions = [
      {id: this.optionAllId, value: 'base.option.all'},
      ..._.map(this.CONSTANT.AUTO_DEBIT_STATUS2, (status) => {
        return {id: status, value: 'base.auto-debit-status.' + status};
      })
    ];
    this.createForm();
  }

  protected createForm() {
    const status = this.params.status == null ? this.optionAllId : this.params.status;
    const fromDate = this.params.fromDate == null ? moment(new Date()).startOf('day').toDate() : Utils.parseIsoDate(this.params.fromDate);
    const toDate = this.params.toDate == null ? moment(fromDate).startOf('day').toDate() : Utils.parseIsoDate(this.params.toDate);

    this.form = FaskFormBuilder.buildForm(
      _.assignIn(
        this.createFromToDateFieldConfig(fromDate, toDate),
        {
          merchant: {value: this.params.merchant, validator: FaskValidatorFactory.noRequired()},
          cif: {value: this.params.cif, validator: FaskValidatorFactory.text(false, 1, 45)},
          contractCode: {value: this.params.contractCode, validator: FaskValidatorFactory.text(false, 1, 45)},
          autoDebitId: {value: this.params.autoDebitId, validator: FaskValidatorFactory.text(false, 1, 30)},
          status: {value: status, validator: FaskValidatorFactory.noRequired()}
        }
      )
    );
  }

  protected checkBeforeAction() {
    if (this.form != null && !this.form.isValid()) {
      this.showFieldError = true;
      return false;
    }

    return true;
  }
}
