import {Component, Injector} from '@angular/core';
import {POListAbstractComponent} from '../../../../../core/components/abstract/po-list.abstract.component';
import {FaskForm} from '../../../../../core/form/fask.form';
import {PaginationData} from '../../../../../core/components/utility/pagination/pagination-data';

@Component({
  selector: 'app-payment-transaction-list',
  templateUrl: './payment-transaction-list.component.html'
})
export class PaymentTransactionListComponent extends POListAbstractComponent {

  vm = this;
  form: FaskForm;
  isRefund: boolean;

  constructor(injector: Injector) {
    super(injector,
      'feature.payment-transaction.title',
      '/api/admin/payment-transaction/search',
      'payment-transaction-detail'
    );
  }

  isCanCreate(): boolean {
    return false;
  }

  isCanBack() {
    return true;
  }

  hasExportExcel() {
    if (this.params['refundStatus'] === 'PROCESSING' || this.params['refundStatus'] === 'FAIL') {
      return true;
    }
    return this.params['status'] === 'PENDING' || this.params['status'] === 'FAIL';
  }

  goToExportExcel() {
    this.utilityService.showConfirmModal('base.exportPTConfirm', () => {
      this.exportExcel();
    });
  }

  exportExcel() {
    this.httpDownloadFile(
      this.getApiUrl('/api/admin/payment-transaction/export'),
      this.params
      ,
      'export'
    );
  }

  back() {
    this.params['refundStatus'] = 'all';
    this.params['status'] = 'all';
    this.goTo('payment-transaction-search', null, this.params, true);
  }

  editRecord(record) {
    this.utilityService.setLocalStorageItem('payment-transaction-list', this.records);
    this.goToDetailState(record.id);
  }

  protected componentInit() {
    this.parseReturnedParams();
    super.componentInit();
  }

  protected addMoreBodyParams(body: any): any {
    body = super.addMoreBodyParams(body);

    body['paymentTransactionId'] = this.params['paymentTransactionId'];
    body['merchantCode'] = this.params['merchantCode'];
    body['autoDebitId'] = this.params['autoDebitId'];
    body['cif'] = this.params['cif'];
    body['fromDate'] = this.params['fromDate'];
    body['toDate'] = this.params['toDate'];
    body['transactionType'] = this.params['transactionType'];

    if (this.params['refundStatus'] != null && this.params['refundStatus'] !== this.optionAllId) {
      body['refundStatus'] = this.params['refundStatus'];
    }

    if (this.params['status'] != null && this.params['status'] !== this.optionAllId) {
      body['status'] = this.params['status'];
    }
    if (this.params['paymentType'] != null && this.params['paymentType'] !== this.optionAllId) {
      body['paymentType'] = this.params['paymentType'];
    }

    this.isRefund = this.params['transactionType'] === 'REFUND';

    return body;
  }

  private parseReturnedParams(): void {
    if (this.params['returnParams'] != null) {
      const returnedParams = this.params['returnParams'];
      delete this.params.returnParams;

      this.params = {
        ...this.params,
        ...returnedParams
      };

      // PAGINATION DATA
      this.paginationData = new PaginationData();
      if (this.params['pageSize'] != null && this.params['pageSize'] > 0) {
        this.paginationData.pageSize = this.params['pageSize'];
      }
      if (this.params['pageNumber'] != null && this.params['pageNumber'] > 0) {
        this.paginationData.pageNumber = this.params['pageNumber'];
      }
    }
  }

  private goToDetailState(id: any) {
    const currentParams = this.params;
    delete currentParams.returnParams;

    const params = {
      returnState: 'payment-transaction-list',
      returnParams: currentParams,
      localStorage: 'payment-transaction-list'
    };

    this.goTo('payment-transaction-detail', id, params, true);
  }

}
