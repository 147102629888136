import {Component, Input, OnInit} from '@angular/core';
import Utils from '../../../utils/utils';

@Component({
  selector: 'app-active-status-label',
  templateUrl: './active-status-label.component.html',
  styleUrls: ['./active-status-label.component.scss']
})
export class ActiveStatusLabelComponent implements OnInit {

  @Input() po: any;

  constructor() { }

  ngOnInit() {
  }

  isDraft() {
    return Utils.isPODraft(this.po);
  }

  isActive() {
    return Utils.isPOActive(this.po);
  }

  isInactive() {
    return Utils.isPOInactive(this.po);
  }

}
