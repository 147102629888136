import {Component, Injector} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';

@Component({
  selector: 'app-form-group-readonly',
  templateUrl: './form-group-readonly.component.html'
})
export class FormGroupReadonlyComponent extends FormGroupAbstractComponent {

  constructor(protected injector: Injector) {
    super(injector);
  }

}
