<span class="label label-warning" *ngIf="status === 'NEW' || status === 'PREPARE'">
  {{ 'base.payment-transaction-status.NEW_OR_PREPARE' | translate }}
</span>
<span class="label label-info" *ngIf="status === 'VERIFIED'">
  {{ 'base.payment-transaction-status.VERIFIED' | translate }}
</span>
<span class="label label-success" *ngIf="status === 'PAID'">
  {{ 'base.payment-transaction-status.PAID' | translate }}
</span>
<span class="label label-danger" *ngIf="status === 'FAIL'">
  {{ 'base.payment-transaction-status.FAIL' | translate }}
</span>
<span class="label label-pending"  *ngIf="status === 'PENDING'">
  {{ 'base.payment-transaction-status.PENDING' | translate }}
</span>

<span class="label label-warning"  *ngIf="status === 'PROCESSING'">
  {{ 'base.payment-transaction-status.PROCESSING' | translate }}
</span>

<span class="label label-success"  *ngIf="status === 'SUCCESS'">
  {{ 'base.payment-transaction-status.SUCCESS' | translate }}
</span>

<span class="label label-danger"  *ngIf="status === 'UNKNOWN'">
  {{ 'base.payment-transaction-status.UNKNOWN' | translate }}
</span>
