import {Component, Injector} from '@angular/core';
import {POListAbstractComponent} from '../../../../../core/components/abstract/po-list.abstract.component';

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html'
})
export class MerchantListComponent extends POListAbstractComponent {

  vm = this;
  isPoRole: boolean;

  constructor(injector: Injector) {
    super(injector,
      'feature.merchant.title',
      '/api/admin/merchant/search',
      'merchant-detail'
    );
    this.isPoRole = this.utilityService.getTokenInfo().user_role.poRole;
  }

}
