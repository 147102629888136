import {Component, Injector} from '@angular/core';
import {PODetailAbstractComponent} from '../../../../../core/components/abstract/po-detail.abstract.component';
import {FaskFormBuilder} from '../../../../../core/form/fask.form';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-payment-transaction-detail',
  templateUrl: './payment-transaction-detail.component.html'
})
export class PaymentTransactionDetailComponent extends PODetailAbstractComponent {

  vm = this;
  isRefund: boolean;

  constructor(injector: Injector) {
    super(injector, 'feature.payment-transaction.title', '/api/admin/payment-transaction', 'payment-transaction-list');
  }

  back() {
    if (this.params.hasOwnProperty('returnState')) {
      const params = {};
      const returnToState = this.params['returnState'];
      if (this.params.hasOwnProperty('returnParams')) {
        params['returnParams'] = this.params['returnParams'];
      }
      this.goTo(returnToState, null, params, true);
    } else {
      super.back();
    }
  }

  protected createForm(): void {
    this.form = FaskFormBuilder.buildForm({});
  }

  protected getBodyToSave(): any {
    return {};
  }

  public getReloadDataUrl() {
    this.isRefund = this.params['returnParams']['transactionType'] === 'REFUND' ? true : false;
    if(this.isRefund) {
      return this.getApiUrl(this.poBackendPath + '/refund/' + this.id);
    }

    return this.getApiUrl(this.poBackendPath + '/' + this.id);
  }

}
