import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import * as MediumEditor from 'medium-editor';

@Component({
  selector: 'app-medium-editor',
  templateUrl: './medium-editor.component.html',
  styleUrls: ['./medium-editor.component.scss']
})
export class MediumEditorComponent implements OnInit {

  @ViewChild('mediumEditor') mediumEditorElementRef: ElementRef;
  @Output() editorReady = new EventEmitter<{ editor: MediumEditor, element: ElementRef }>();

  constructor() {
  }

  ngOnInit() {
    const editor = new MediumEditor(this.mediumEditorElementRef.nativeElement, {
      toolbar: {
        buttons: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'unorderedlist']
      }
    });

    this.editorReady.emit({editor: editor, element: this.mediumEditorElementRef});
  }

}
