<div class="modal-header">
  <h4 class="modal-title">
    {{ title | translate }}
  </h4>
</div>

<div class="modal-body">

  <div>
    <app-form-group-text [fieldKey]="'name'"
                         [form]="form"
                         [label]="'base.name'"
                         [showError]="showFieldError"></app-form-group-text>
  </div>

  <div>
    <app-form-group-text [fieldKey]="'code'"
                         [fieldReadonly]="!isDraft()"
                         [form]="form"
                         [label]="'feature.payment-transaction-type.code'"
                         [showError]="showFieldError"></app-form-group-text>
  </div>

  <div>
    <app-form-group-combobox (valueChange)="onFieldChanged($event)"
                             [fieldKey]="'autoDebitType'"
                             [form]="form"
                             [itemDisplayField]="'value'"
                             [itemKeyField]="'id'"
                             [fieldReadonly]="!useAutoDebitBlockFlag || !useAutoDebitFlag"
                             [itemList]="autoDebitTypeOptions"
                             [label]="'base.auto-debit-payment-type'"
                             [showError]="showFieldError">
    </app-form-group-combobox>
  </div>

  <div>
    <app-form-group-text [fieldKey]="'retryDay'"
                         [form]="form"
                         [fieldReadonly]="!useAutoDebitBlockFlag || !useAutoDebitFlag"
                         [label]="'base.retry-day'"
                         [showError]="showFieldError"></app-form-group-text>
  </div>

  <div>
    <app-form-group-checkbox [fieldKey]="'allowCard'"
                             [form]="form"
                             [label]="'feature.payment-transaction-type.allow-card'"
                             [showError]="showFieldError"
                             class="no-margin-bottom"></app-form-group-checkbox>
  </div>
  <div>
    <app-form-group-checkbox [fieldKey]="'allowBNPL'"
                             [form]="form"
                             [label]="'feature.payment-transaction-type.allow-bnpl'"
                             [showError]="showFieldError"
                             class="no-margin-bottom"></app-form-group-checkbox>
  </div>
  <div>
    <app-form-group-checkbox [fieldKey]="'allowVoucher'"
                             [form]="form"
                             [label]="'feature.payment-transaction-type.allow-voucher'"
                             [showError]="showFieldError"
                             class="no-margin-bottom"></app-form-group-checkbox>
  </div>
  <div>
    <app-form-group-checkbox [fieldKey]="'allowLoyalty'"
                             [form]="form"
                             [label]="'feature.payment-transaction-type.allow-loyalty'"
                             [showError]="showFieldError"
                             class="no-margin-bottom"></app-form-group-checkbox>
  </div>
  <div>
    <app-form-group-checkbox (valueChange)="onFieldAutoDebitChanged($event)"
                             [fieldKey]="'autoDebit'"
                             [form]="form"
                             [fieldDisabled]="!useAutoDebitFlag || paymentTransactionType.recordStatus == 'INACTIVE'"
                             [label]="'feature.payment-transaction-type.auto-debit'"
                             [showError]="showFieldError"
                             class="no-margin-bottom"></app-form-group-checkbox>
  </div>
</div>

<div class="modal-footer">
  <button (click)="accept()" class="btn btn-dark">
    {{ acceptBtnName | translate }}
  </button>
  <button (click)="cancel()" class="btn btn-danger">
    {{ cancelBtnName | translate }}
  </button>
</div>
