<div class="form-group" [ngClass]="{ 'has-danger': isAddDangerClass() }" *ngIf="getField() != null">

  <label class="form-control-label">
    {{ label | translate }}<span *ngIf="getValidator().required">*</span>
  </label>

  <input type="text"
         class="form-control"
         bsDatepicker
         [bsConfig]="{ dateInputFormat: dateFormatPattern, containerClass: 'theme-dark-blue' }"
         [placement]="placement"
         *ngIf="!fieldDisabled && !fieldReadonly"
         [minDate]="minDate"
         [maxDate]="maxDate"
         [(ngModel)]="getField().value"
         (ngModelChange)="onDateChanged($event)"/>

  <div class="form-control" readonly *ngIf="(fieldDisabled || fieldReadonly)">
    {{ getDateDisplay() }}
  </div>

  <div class="form-control-feedback" *ngIf="isShowTextError()">

    <span *ngIf="useDefaultErrorMessage()">
      <span *ngIf="getValidator().required">{{ 'form.error.message.date-required' | translate }}</span>
      <span *ngIf="!getValidator().required">{{ 'form.error.message.date' | translate }}</span>
      <span *ngIf="hasMinMaxConstraint()">&nbsp;({{getMinMaxConstraintErrorMessage()}})</span>
    </span>

    <span *ngIf="!useDefaultErrorMessage()">
      {{ errorMessage | translate:getValidator().params }}
    </span>

  </div>

</div>
