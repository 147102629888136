<app-form-group-text (valueChange)="onFieldChanged($event)"
                     [errorMessage]="'form.error.message.link'"
                     [fieldKey]="fieldPrefix + 'Url'"
                     [fieldReadonly]="fieldReadonly"
                     [form]="form"
                     [label]="(title | translate) + ' ' + ('feature.merchant.url' | translate)"
                     [showError]="showError"></app-form-group-text>

<!--<div class="sub-section">-->
<!--  <div class="sub-section-header">-->
<!--    {{ title | translate }}-->
<!--  </div>-->
<!--<div class="sub-section-body">-->
<!--  <div class="row">-->
<!--    <div class="col-12">-->
<!--      <app-form-group-text (valueChange)="onFieldChanged($event)"-->
<!--                           [errorMessage]="'form.error.message.link'"-->
<!--                           [fieldKey]="fieldPrefix + 'Url'"-->
<!--                           [fieldReadonly]="fieldReadonly"-->
<!--                           [form]="form"-->
<!--                           [label]="'feature.merchant.url'"-->
<!--                           [showError]="showError"></app-form-group-text>-->
<!--    </div>-->

<!--    <div class="col-12">-->
<!--      <app-form-group-text (valueChange)="onFieldChanged($event)"-->
<!--                           [fieldKey]="fieldPrefix + 'ChecksumKey'"-->
<!--                           [fieldReadonly]="fieldReadonly"-->
<!--                           [form]="form"-->
<!--                           [label]="'feature.merchant.checksum-key'"-->
<!--                           [showError]="showError"></app-form-group-text>-->
<!--    </div>-->

<!--    <div class="col-6">-->
<!--      <app-form-group-text (valueChange)="onFieldChanged($event)"-->
<!--                           [fieldKey]="fieldPrefix + 'HeaderKey1'"-->
<!--                           [fieldReadonly]="fieldReadonly"-->
<!--                           [form]="form"-->
<!--                           [label]="'feature.merchant.header-key-1'"-->
<!--                           [showError]="showError"></app-form-group-text>-->
<!--    </div>-->

<!--    <div class="col-6">-->
<!--      <app-form-group-text (valueChange)="onFieldChanged($event)"-->
<!--                           [fieldKey]="fieldPrefix + 'HeaderValue1'"-->
<!--                           [fieldReadonly]="fieldReadonly"-->
<!--                           [form]="form"-->
<!--                           [label]="'feature.merchant.header-value-1'"-->
<!--                           [showError]="showError"></app-form-group-text>-->
<!--    </div>-->

<!--    <div class="col-6">-->
<!--      <app-form-group-text (valueChange)="onFieldChanged($event)"-->
<!--                           [fieldKey]="fieldPrefix + 'HeaderKey2'"-->
<!--                           [fieldReadonly]="fieldReadonly"-->
<!--                           [form]="form"-->
<!--                           [label]="'feature.merchant.header-key-2'"-->
<!--                           [showError]="showError"></app-form-group-text>-->
<!--    </div>-->

<!--    <div class="col-6">-->
<!--      <app-form-group-text (valueChange)="onFieldChanged($event)"-->
<!--                           [fieldKey]="fieldPrefix + 'HeaderValue2'"-->
<!--                           [fieldReadonly]="fieldReadonly"-->
<!--                           [form]="form"-->
<!--                           [label]="'feature.merchant.header-value-2'"-->
<!--                           [showError]="showError"></app-form-group-text>-->
<!--    </div>-->
<!--  </div>-->
<!--  </div>-->
<!--</div>-->
