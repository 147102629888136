import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';
import Utils from '../utils/utils';

@Pipe({name: 'isoTimeShort'})
export class IsoTimeShortPipe implements PipeTransform {
  transform(value: string): string {
    const date = Utils.parseIsoTime(value);

    if (date == null) {
      return '';
    }

    return moment(date).format('HH:mm');
  }
}
