import {SidebarMenu, SidebarMenuGroup} from '../core/components/base/sidebar/sidebar.menu.model';

export default class Menu {

  public static MENU_GROUPS: SidebarMenuGroup[] = [

    // 0---DASHBOARD---
    new SidebarMenuGroup('menu.group.dashboard',
      [

        // 1---DASHBOARD---
        new SidebarMenu(
          'feature.dashboard.menu-title',
          'fas fa-tachometer-alt',
          'dashboard',
          null,
          ['ADMIN', 'SUB_ADMIN'],
          'DASHBOARD',
          null
        )
      ]
    ),

    // 0---MERCHANT---
    new SidebarMenuGroup('menu.group.merchant',
      [

        // 1---MERCHANT MANAGEMENT---
        new SidebarMenu('feature.merchant.menu-title',
          'fas fa-hands-helping',
          'merchant-list',
          null,
          ['ADMIN', 'SUB_ADMIN'],
          'MERCHANT_MANAGEMENT',
          ['merchant-detail']
        ),

        new SidebarMenu(
          'feature.merchant.export-title',
          'fas fa-file-export',
          'merchant-export',
          null,
          ['ADMIN', 'SUB_ADMIN'],
          'MERCHANT_MANAGEMENT',
          null
        )

      ]
    ),

    // 0---MANAGEMENT---
    new SidebarMenuGroup('menu.group.management',
      [

        // 1---PAYMENT_TRANSACTION MANAGEMENT---
        new SidebarMenu('feature.payment-transaction.menu-title',
          'fas fa-tag',
          'payment-transaction-search',
          null,
          ['ADMIN', 'SUB_ADMIN'],
          'PAYMENT_TRANSACTION_MANAGEMENT',
          ['payment-transaction-list', 'payment-transaction-detail']
        ),

        // 1---PAYMENT_TRANSACTION MANAGEMENT---
        new SidebarMenu('feature.auto-debit.menu-title',
          'fas fa-tag',
          'auto-debit-search',
          null,
          ['ADMIN', 'SUB_ADMIN'],
          'PAYMENT_TRANSACTION_MANAGEMENT',
          ['auto-debit-list', 'auto-debit-detail']
        ),

        // 1---EXPORT PAYMENT TRANSACTION LOG
        new SidebarMenu('feature.payment-transaction-log-export.menu-title',
          'fas fa-file-export',
          'payment-transaction-log-export',
          null,
          ['ADMIN', 'SUB_ADMIN'],
          'PAYMENT_TRANSACTION_LOG_EXPORT',
          null
        ),

        // 1---EXPORT RECONCILIATION REPORT
        new SidebarMenu('feature.reconciliation-report-export.menu-title',
          'fas fa-file-export',
          'reconciliation-report-export',
          null,
          ['ADMIN', 'SUB_ADMIN'],
          'RECONCILIATION_REPORT_EXPORT',
          null
        ),

        // 1---EXPORT AUTO DEBIT
        new SidebarMenu('feature.auto-debit-log-export.menu-title',
          'fas fa-file-export',
          'auto-debit-log-export',
          null,
          ['ADMIN', 'SUB_ADMIN'],
          'AUTO_DEBIT_LOG_EXPORT',
          null
        ),

        // 1---EXPORT INCOMING REQUEST
        new SidebarMenu('feature.incoming-request-export.menu-title',
          'fas fa-file-export',
          'incoming-request-export',
          null,
          ['ADMIN', 'SUB_ADMIN'],
          'INCOMING_REQUEST_EXPORT',
          null
        ),

      ]
    ),

    // 0---CONFIG---
    new SidebarMenuGroup('menu.group.config',
      [
        // 1---BUSINESS CONFIG---
        new SidebarMenu('feature.business-config.menu-title',
          'fas fa-cog',
          'business-config',
          null,
          ['ADMIN', 'PO']
        ),

        // 1---SANDBOX APk MANAGEMENT---
        new SidebarMenu('feature.sandbox-apk.menu-title',
          'fab fa-android',
          'sandbox-apk-list',
          null,
          ['ADMIN', 'PO'],
          null,
          ['sandbox-apk-detail']
        ),

        // 1---SANDBOX APk MANAGEMENT---
        new SidebarMenu('feature.sandbox-apk.create-link.menu-title',
          'fas fa-link',
          'sandbox-apk-create-link',
          null,
          ['ADMIN', 'PO'],
          null,
        ),

      ],
      ['ADMIN', 'PO'],
      null
    ),

    // 0---USER---
    new SidebarMenuGroup('menu.group.user',
      [

        // 1---SUB ADMIN---
        new SidebarMenu(
          'feature.sub-admin.menu.title',
          'fas fa-user-secret',
          'sub-admin-list',
          null,
          ['ADMIN'],
          null,
          ['sub-admin-detail']
        ),

      ]
      ,
      ['ADMIN', 'PO'],
      null
    ),

  ];

}
