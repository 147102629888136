import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';

@Component({
  selector: 'app-form-group-textarea',
  templateUrl: './form-group-textarea.component.html'
})
export class FormGroupTextareaComponent extends FormGroupAbstractComponent implements OnInit {

  @Input() placeholder = '';
  @Input() rows = 4;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onTextChanged(value): void {
    this.valueChange.emit({fieldKey: this.fieldKey, value: value});
  }

}
