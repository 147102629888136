<app-loading [loadingStatus]="vm.loadingStatus">
  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ vm.title | translate }}</h2>
          </div>

          <div class="col text-right title-btn-bar">
            <button class="btn btn-dark" (click)="vm.back()" *ngIf="vm.isCanBack()">
              <i class="fas fa-arrow-left"></i>
            </button>

            <button class="btn btn-info" (click)="vm.refresh()">
              <i class="fas fa-sync-alt"></i>
            </button>

            <button class="btn btn-success" (click)="vm.save()" *ngIf="vm.isCanSave()">
              <i class="fas fa-save"></i>
            </button>

            <ng-content select="[title-btn-bar-content]"></ng-content>
          </div>

        </div>
      </div>

      <div class="col-12" *ngIf="useSimplePOTemplate">
        <div class="card">
          <div class="card-body">
            <div class="margin-auto" [ngClass]="{ 'max-w-md': !useSimplePOTemplateFullWidth }">
              <div class="row" *ngIf="vm.form">

                <div class="col-12" *ngIf="useSimplePOTemplateFieldStatus">
                  <div class="row">

                    <div class="col-auto">
                      <div class="form-group">
                        <label> {{ 'base.status' | translate }}:&nbsp;</label>
                        <app-active-status-label [po]="vm.record"></app-active-status-label>
                      </div>
                    </div>

                    <div class="col text-right" *ngIf="!vm.isNew()">
                      <button class="btn btn-dark m-b"
                              (click)="vm.enable()"
                              [disabled]="vm.isCanSave()"
                              *ngIf="vm.isCanEnable()">
                        <span class="fas fa-check"></span>
                        {{ 'base.enable' | translate }}
                      </button>

                      <button class="btn btn-danger m-b"
                              (click)="vm.deactivate()"
                              [disabled]="vm.isCanSave()"
                              *ngIf="vm.isCanDeactivate()">
                        <span class="fas fa-ban"></span>
                        {{ 'base.deactivate' | translate }}
                      </button>

                      <button class="btn btn-dark m-b"
                              (click)="vm.activate()"
                              [disabled]="vm.isCanSave()"
                              *ngIf="vm.isCanActivate()">
                        <span class="fas fa-check"></span>
                        {{ 'base.activate' | translate }}
                      </button>
                    </div>

                  </div>

                </div>


                <div class="col-12" *ngIf="useSimplePOTemplateFieldName">
                  <app-form-group-text [form]="vm.form"
                                       [fieldKey]="'name'"
                                       [label]="'base.name'"
                                       [showError]="vm.showFieldError"
                                       (valueChange)="vm.onFieldChanged($event)"
                                       [fieldReadonly]="vm.isReadonly()"></app-form-group-text>
                </div>

                <div class="col-12">
                  <ng-content select="[simple-po-detail-field-container]"></ng-content>
                </div>

                <div class="col-12">
                  <div class="row small-gutters">
                    <div class="col-auto" *ngIf="vm.isCanDelete()">
                      <button class="btn btn-danger m-b" (click)="vm.delete()">
                        <span>{{ 'base.delete' | translate }}</span>
                      </button>
                    </div>

                    <div class="col-auto">
                      <ng-content select="[simple-po-detail-action-container]"></ng-content>
                    </div>

                    <div class="col">
                      <div class="row small-gutters">
                        <div class="col"><!--EMPTY--></div>

                        <div class="col-auto" *ngIf="vm.isCanBack()">
                          <button class="btn btn-dark m-b" (click)="vm.back()">
                            <span *ngIf="!vm.isCanSave()">{{ 'base.btn.back' | translate }}</span>
                            <span *ngIf="vm.isCanSave()">{{ 'base.btn.cancel' | translate }}</span>
                          </button>
                        </div>

                        <div class="col-auto">
                          <button class="btn btn-success m-b" (click)="vm.save()" [disabled]="!vm.isCanSave()">
                            <span>{{ 'base.btn.save' | translate }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="!useSimplePOTemplate">
        <ng-content select="[po-detail-content]"></ng-content>
      </div>

    </div>

  </div>
</app-loading>
