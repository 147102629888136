import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import * as _ from 'lodash';

import {SidebarMenu, SidebarMenuGroup} from './sidebar.menu.model';
import Menu from '../../../../business/menu';
import {UtilityService} from '../../../utils/service/utility.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, OnDestroy {

  menuGroups: SidebarMenuGroup[];
  userInfoSubscription: any;
  perfectScrollbarConfig = {
    suppressScrollX: true
  };

  constructor(private utilityService: UtilityService) {
  }

  ngOnInit() {
    this.updateMenus();

    const thisComponent = this;
    this.userInfoSubscription = this.utilityService.userInfoChange
      .subscribe(() => {
        thisComponent.updateMenus();
      });
  }

  ngOnDestroy() {
    this.userInfoSubscription.unsubscribe();
  }

  showSubMenu(menu: SidebarMenu) {
    menu.opened = !menu.opened;
  }

  private updateMenus() {
    this.menuGroups = [];
    const userInfo = this.utilityService.getUserInfo();
    for (const menuGroup of Menu.MENU_GROUPS) {
      if (this.isDisplayMenuGroup(menuGroup)) {
        const menus = [];
        if (menuGroup.isConfigMenu() && userInfo.poRole) {
          continue;
        }
        for (const menu of menuGroup.menus) {

          if (this.isDisplayMenu(menu)) {
            if (menu.hasChild()) {
              const subMenus = [];
              for (const subMenu of menu.subMenus) {
                if (this.isDisplayMenu(subMenu)) {
                  subMenus.push(subMenu.clone(null));
                }
              }

              if (!_.isEmpty(subMenus)) {
                const _menu = menu.clone(subMenus);
                if (this.isActiveMenu(_menu)) {
                  this.showSubMenu(_menu);
                }
                menus.push(_menu);
              }
            } else {
              menus.push(menu.clone(null));
            }
          }

        }

        if (!_.isEmpty(menus)) {
          this.menuGroups.push(menuGroup.clone(menus));
        }
      }
    }
  }

  isActiveMenu(menu: SidebarMenu) {
    if (menu.hasChild()) {
      for (const subMenu of menu.subMenus) {
        if (this.isActiveMenu(subMenu)) {
          return true;
        }
      }

      return false;
    } else {
      if (menu.state == null) {
        return false;
      }

      let states = [menu.state];
      if (menu.childStates != null) {
        states = _.union(states, menu.childStates);
      }

      return menu.state != null && _.includes(states, this.utilityService.getCurrentRoute());
    }
  }

  private isDisplayMenuGroup(menuGroup: SidebarMenuGroup) {
    const userInfo = this.utilityService.getUserInfo();
    return menuGroup.isValidUserInfo(userInfo);
  }

  private isDisplayMenu(menu: SidebarMenu) {
    const userInfo = this.utilityService.getUserInfo();
    return menu.isValidUserInfo(userInfo);
  }

}
