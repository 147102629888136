<app-loading [loadingStatus]="vm.loadingStatus">
  <div class="wrapper">

    <div class="row">

      <div class="col-12 title-bar">
        <div class="row">

          <div class="col-auto">
            <h2>{{ title | translate }}</h2>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="margin-auto max-w-md">
              <div *ngIf="vm.form" class="row">

                <div class="col-12">
                  <app-form-group-select (valueChange)="onFieldChanged($event)"
                                         [fieldKey]="'merchant'"
                                         [form]="vm.form"
                                         [itemDisplayField]="'displayValue'"
                                         [label]="'feature.payment-transaction.merchant'"
                                         [loadData]="loadMerchants"
                                         [placeholder]="'base.option.all' | translate"
                                         [showError]="showFieldError"
                                         [useTypeahead]="true">
                  </app-form-group-select>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-2" *ngFor="let merchantCode of merchantCodes">
                      <div class="form-group">
                        <label class="form-control-label">
                          <button (click)="deleteMerchantCode($event, merchantCode)" class="btn btn-outline-secondary ">{{ merchantCode }}</button>
                        </label>
                      </div>
                    </div>


                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <button (click)="deleteMerchantCodes()" class="btn btn-danger" *ngIf="isValidDeleteBtn">
                      {{ 'Chọn lại' }}
                    </button>
                  </div>
                </div>


                <div class="col-12">
                  <button (click)="export()" class="btn btn-info w-full">
                    {{ 'base.export-excel' | translate }}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</app-loading>
