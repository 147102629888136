import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import * as numeral from 'numeral';
import {BsLocaleService} from "ngx-bootstrap/datepicker";

import {UtilityService} from './core/utils/service/utility.service';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {

  static NO_NEED_LOGIN_ROUTES = ['login', 'signup', '404', '500', 'sdk-level1'];
  sidebarOpened = false;

  constructor(private router: Router,
              private translate: TranslateService,
              private utilityService: UtilityService,
              private bsLocaleService: BsLocaleService) {
  }

  ngOnInit() {
    const thisComponent = this;

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('vi');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.utilityService.getDefaultLanguage());

    // Listen to router events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        thisComponent.closeSidebar();

        // if (!this.isNoNeedLoginRoute(event.url) && this.utilityService.getUserInfo() == null) {
        //   const url = this.router.url;
        //
        //   this.httpClient.get(Properties.ADDRESS_BACKEND + '/api/userinfo').subscribe((userinfo) => {
        //     this.utilityService.setUserInfo(userinfo);
        //   }, () => {
        //     this.utilityService.clearUserInfo();
        //
        //     const queryParams = {
        //       'ru': url
        //     };
        //
        //     this.router.navigate([`/login`], {queryParams: queryParams});
        //   });
        // }
      }

      if (event instanceof NavigationEnd) {
        thisComponent.utilityService.incrementNbNavigationTimes();
      }
    });

    // Config Numeral to Vietnam
    numeral.register('locale', 'vi', {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      abbreviations: {
        thousand: 'k',
        million: 'M',
        billion: 'B',
        trillion: 'T'
      },
      ordinal: function (number) {
        return '';
      },
      currency: {
        symbol: '₫'
      }
    });
    numeral.locale('en');

    this.bsLocaleService.use(this.utilityService.getDefaultLanguage());
    this.translate.onLangChange.subscribe(() => {
      this.bsLocaleService.use(this.translate.currentLang);
    });
  }

  ngAfterViewInit() {
    const underlay = <HTMLElement>document.querySelector('#left-sidebar-underlay');

    const thisComponent = this;
    underlay.ontouchstart = function (ev: TouchEvent) {
      thisComponent.closeSidebar();
      ev.preventDefault();
    };
  }

  isMobile(): boolean {
    return this.utilityService.isMobile();
  }

  openSidebar() {
    if (this.isMobile()) {
      this.sidebarOpened = true;

      // Lock body scroll
      disableBodyScroll(document.querySelector('#left-sidebar-container'));
    }
  }

  closeSidebar() {
    if (this.isMobile()) {
      this.sidebarOpened = false;

      // Unlock body scroll
      clearAllBodyScrollLocks();
    }
  }

  isLoading() {
    if (!this.isNoNeedLoginRoute()) {
      if (this.utilityService.getUserInfo() == null) {
        return true;
      }
    }

    return false;
  }

  isFullscreenState() {
    return this.isNoNeedLoginRoute();
  }

  isNoNeedLoginRoute(url?: string) {
    const currentRoute = this.utilityService.getCurrentRoute(url);
    return _.includes(AppComponent.NO_NEED_LOGIN_ROUTES, currentRoute);
  }

}
