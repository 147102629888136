import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lds-spinner',
  templateUrl: './lds-spinner.component.html',
  styleUrls: ['./lds-spinner.component.scss']
})
export class LdsSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
