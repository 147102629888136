import {ChartOptions, ChartType} from 'chart.js';
import {Color} from 'ng2-charts/lib/color';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

export class Constant {

  public static USER_ROLES = {
    ADMIN: 'ADMIN',
    SUB_ADMIN: 'SUB_ADMIN',
    PO: 'PO'
  };

  public static PAYMENT_TRANSACTION_STATUS = {
    NEW: 'NEW',
    VERIFIED: 'VERIFIED',
    PAID: 'PAID',
    PREPARE: 'PREPARE',
    FAIL: 'FAIL',
    PENDING: 'PENDING'
  };

  public static AUTO_DEBIT_STATUS = {
    NEW: 'NEW',
    VERIFIED: 'VERIFIED',
    CONFIRMED: 'CONFIRMED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED'
  };

  public static AUTO_DEBIT_STATUS2 = {
    REGISTER: 'REGISTER',
    REGISTER_VERIFY: 'REGISTER_VERIFY',
    REGISTED: 'REGISTED',
    UPDATE: 'UPDATE',
    UPDATE_VERIFY: 'UPDATE_VERIFY',
    CANCEL: 'CANCEL',
    CANCEL_VERIFY: 'CANCEL_VERIFY',
    CANCELLED: 'CANCELLED',
    FAIL: 'FAIL'
  };

  public static PIE_CHART: {
    pieChartOptions: ChartOptions,
    pieChartType: ChartType,
    pieChartLegend: boolean,
    pieChartPlugins: any[],
    pieChartColors: Color[]
  } = {
    pieChartOptions: {
      responsive: true,
      legend: {
        position: 'right',
        labels: {
          fontColor: 'black'
        }
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            // const label = ctx.chart.data.labels[ctx.dataIndex];
            //
            // const data = _.get(_.head(_.get(ctx, 'chart.data.datasets')), 'data');
            // if (data != null && data[ctx.dataIndex] <= 0) {
            //   return '';
            // }
            //
            // return label;
            return '';
          },
          color: 'white',
        },
      }
    },
    pieChartType: 'pie',
    pieChartLegend: true,
    pieChartPlugins: [
      pluginDataLabels
    ],
    pieChartColors: [
      {
        backgroundColor: ['#2b72bd', '#f33155', '#4caf50', '#ff891e', '#7266ba', '#d76eac'],
      },
    ],
  };

  public static LINE_CHART: {
    lineChartOptions: ChartOptions,
    lineChartColors: Color[],
    lineChartLegend: boolean,
    lineChartType: string
  } = {
    lineChartOptions: {
      responsive: true,
      aspectRatio: 2,
      scales: {
        // We use this empty structure as a placeholder for dynamic theming.
        xAxes: [{}],
        yAxes: [
          {
            id: 'y-axis-0',
            position: 'left',
          },
          // {
          //   id: 'y-axis-1',
          //   position: 'right',
          //   gridLines: {
          //     color: 'rgba(43,114,189,0.3)',
          //   },
          //   ticks: {
          //     fontColor: 'black',
          //   }
          // }
        ]
      }
    },
    lineChartColors: [
      { // grey
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      },
      { // red
        backgroundColor: 'rgba(76,175,80,0.4)',
        borderColor: '#4caf50',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      },
    ],
    lineChartLegend: true,
    lineChartType: 'line',
  };

  public static BAR_CHART: {
    barChartOptions: ChartOptions,
    barChartLegend: boolean,
    barChartPlugins: any[],
    barChartType: ChartType
  } = {
    barChartOptions: {
      responsive: true,
      // We use these empty structures as placeholders for dynamic theming.
      maintainAspectRatio: false,
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxes: [{}], yAxes: [{
          position: 'left',
          ticks: {
            beginAtZero: true,
            display: true
          }
        },
        ]
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      }
    },
    barChartLegend: true,
    barChartPlugins: [
      pluginDataLabels
    ],
    barChartType: 'bar',
  };

  public static BAR_CHART_DOUBLE_Y: {
    barChartOptions: ChartOptions,
    barChartLegend: boolean,
    barChartPlugins: any[],
    barChartType: ChartType
  } = {
    barChartOptions: {
      responsive: true,
      // We use these empty structures as placeholders for dynamic theming.
      maintainAspectRatio: false,
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxes: [{}], yAxes: [{
          id: 'y-axis-left',
          position: 'left',
          ticks: {
            beginAtZero: true,
            display: true
          }
        },
          {
            id: 'y-axis-right',
            position: 'right',
            ticks: {
              beginAtZero: true,
              display: true
            }
          }
        ]
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      }
    },
    barChartLegend: true,
    barChartPlugins: [
      pluginDataLabels
    ],
    barChartType: 'bar',
  };

  public static BAR_CHART_HORIZONTAL: {
    barChartOptions: ChartOptions,
    barChartLegend: boolean,
    barChartPlugins: any[],
    barChartType: ChartType
  } = {
    barChartOptions: {
      legend: {
        position: 'bottom',
      },
      tooltips: {
        enabled: false
      },
      responsive: true,
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            // const label = ctx.chart.data.labels[ctx.dataIndex];
            // if (ctx.dataset.data[ctx.dataIndex] > 0) {
            //   return label + ' : ' + ctx.dataset.data[ctx.dataIndex];
            // } else {
            return '';
            // }
          },
        }
      },
      scales: {
        xAxes: [
          {
            display: false
          }
        ],
        yAxes: [
          {
            display: false
          }
        ]
      }
    },
    barChartLegend: false,
    barChartPlugins: [
      pluginDataLabels
    ],
    barChartType: 'horizontalBar',
  };

  public static DOUGHNUT_CHART: {
    doughnutChartOptions: ChartOptions,
    doughnutChartLegend: boolean,
    doughnutChartType: ChartType,
    doughnutChartColors: Color[]
  } = {
    doughnutChartOptions: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 15,
          fontColor: 'black',
          fontSize: 15,
          padding: 25
        },
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            // const label = ctx.chart.data.labels[ctx.dataIndex];
            // if (ctx.dataset.data[ctx.dataIndex] > 0) {
            //   return label + ' : ' + ctx.dataset.data[ctx.dataIndex];
            // } else {
            return '';
            // }
          },
        }
      },
    },
    doughnutChartLegend: true,
    doughnutChartType: 'doughnut',
    doughnutChartColors: [
      {
        backgroundColor: [
          '#2b72bd',
          '#f33155',
          '#ff891e',
          '#45B29D',
        ],
        hoverBackgroundColor: [
          '#5EB5EF',
          '#F77A91',
          '#FFB36D',
          '#87CDC0',
        ]
      }
    ]
  };

  public static DOUGHNUT_CHART_GRAY: {
    doughnutChartColors: Color[]
  } = {
    doughnutChartColors: [
      {
        backgroundColor: [
          '#2b72bd',
          '#d8d8d8'
        ],
        hoverBackgroundColor: [
          '#5EB5EF',
          '#e8e8e8'
        ]
      }
    ]
  };

  public static DOUGHNUT_CHART_RED: {
    doughnutChartColors: Color[]
  } = {
    doughnutChartColors: [
      {
        backgroundColor: [
          '#F33155',
          '#d8d8d8'
        ],
        hoverBackgroundColor: [
          '#F77A91',
          '#e8e8e8'
        ]
      }
    ]
  };

  public static DOUGHNUT_CHART_YELLOW: {
    doughnutChartColors: Color[]
  } = {
    doughnutChartColors: [
      {
        backgroundColor: [
          '#FAD733',
          '#d8d8d8'
        ],
        hoverBackgroundColor: [
          '#FFD878',
          '#e8e8e8'
        ]
      }
    ]
  };

}

export enum FrequencyUnit {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR'
}
