<div class="loading-component"
     [ngClass]="{ 'keep-content-dom': keepContentDOM, 'error': isShowError(), 'loading': isShowLoading() }">

  <div *ngIf="isShowError()">
    <div class="error-container">
      <h1 class="text-info">:-(</h1>
      <h2>ERROR</h2>
      <h3>Something went wrong...</h3>
      <button class="btn btn-info" (click)="reload()">
        <i class="fas fa-sync-alt"></i>
        <span>&nbsp;&nbsp;{{ 'base.retry' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="loading-container" *ngIf="isShowLoading()">
    <div class="sk-fading-circle">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>
  </div>

  <div class="content">
    <ng-content *ngIf="keepContentDOM || isShowContent()"></ng-content>
  </div>

</div>
