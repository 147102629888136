import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';

@Pipe({name: 'epochDateTime'})
export class EpochDateTimePipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }

    return moment(value * 1000).format('YYYY-MM-DD HH:mm:ss');
  }
}
