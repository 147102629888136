import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

export interface IPhoto {
  src: string;
  caption?: string;
}

export const IMAGE_VIEWER_EVENT = {
  CLOSE: 1
};

@Injectable()
export class ImageViewerEventService {

  private _eventSource: Subject<Object>;
  public event$: Observable<Object>;

  constructor() {
    this._eventSource = new Subject<Object>();
    this.event$ = this._eventSource.asObservable();
  }

  broadcastEvent(event: any): void {
    this._eventSource.next(event);
  }
}
