import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationData} from "./pagination-data";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() paginationData: PaginationData;
  @Output() pageChange = new EventEmitter<{ pageSize: number, pageNumber: number }>();

  constructor() {
  }

  ngOnInit() {
  }

  getFrom() {
    return (this.paginationData.pageSize * (this.paginationData.pageNumber - 1)) + 1;
  }

  getTo() {
    if (this.paginationData.count > (this.paginationData.pageSize * this.paginationData.pageNumber)) {
      return this.paginationData.pageSize * this.paginationData.pageNumber;
    } else {
      return this.paginationData.count;
    }
  }

  getTotal() {
    return this.paginationData.count;
  }

  canNext() {
    return this.paginationData.count > (this.paginationData.pageSize * this.paginationData.pageNumber);
  }

  next() {
    if (this.canNext()) {
      this.pageChange.emit({pageSize: this.paginationData.pageSize, pageNumber: this.paginationData.pageNumber + 1});
    }
  }

  canPrevious() {
    return this.getFrom() > 1;
  }

  previous() {
    if (this.canPrevious()) {
      this.pageChange.emit({pageSize: this.paginationData.pageSize, pageNumber: this.paginationData.pageNumber - 1});
    }
  }

}
