import {Component, Injector, OnDestroy} from '@angular/core';
import {AbstractComponent} from '../../../../core/components/abstract/abstract.component';
import {Label} from 'ng2-charts';
import {ChartDataSets} from 'chart.js';
import Utils from '../../../../core/utils/utils';
import * as moment from 'moment';
import * as _ from 'lodash';
import {FaskForm, FaskFormBuilder, FaskValidatorFactory} from '../../../../core/form/fask.form';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends AbstractComponent implements OnDestroy {

  public loginRequestChartData: ChartDataSets[];
  public loginRequestChartLabels: Label[];
  public paidRequestChartData: ChartDataSets[];
  public paidRequestChartLabels: Label[];
  showFieldError = false;
  form: FaskForm;
  vm = this;

  dashboard = null;

  constructor(protected injector: Injector) {
    super(injector);
  }

  protected componentInit(): void {
    this.loginRequestChartData = [
      {
        data: [], label: 'Số request', backgroundColor: '#2b72bd', hoverBackgroundColor: '#5EB5EF', datalabels: {
          color: 'transparent'
        }
      },
      {
        data: [], label: 'Số khách hàng', backgroundColor: '#f33155', hoverBackgroundColor: '#F77A91', datalabels: {
          color: 'transparent'
        }
      }
    ];
    this.loginRequestChartLabels = [];

    this.paidRequestChartData = [
      {
        yAxisID: 'y-axis-left',
        data: [],
        label: 'Số giao dịch',
        backgroundColor: '#2b72bd',
        hoverBackgroundColor: '#5EB5EF',
        datalabels: {
          color: 'transparent'
        },
      },
      {
        yAxisID: 'y-axis-right',
        data: [],
        label: 'Tổng số tiền',
        backgroundColor: '#f33155',
        hoverBackgroundColor: '#F77A91',
        datalabels: {
          color: 'transparent'
        }
      }
    ];
    this.paidRequestChartLabels = [];

    // this.loadDashboard();
    // this.showFieldError = false;
    // this.createForm();
  }

  ngOnDestroy(): void {
  }

  weekly() {
    this.params.type = 'week';
    this.reloadState();
  }

  monthly() {
    this.params.type = 'month';
    this.reloadState();
  }

  // PRIVATE
  private loadDashboard() {
    if (this.params.type !== 'month') {
      this.params.type = 'week';
    }

    this.dashboard = null;

    const toDate = moment().startOf('day').toDate();
    let fromDate = moment(toDate).subtract(6, 'days').toDate();
    if (this.params.type === 'month') {
      fromDate = moment(toDate).subtract(29, 'days').toDate();
    }

    this.httpGet(
      this.getApiUrl('/api/admin/dashboard'),
      {
        merchantCode: this.params['merchant'] ? this.params['merchant'].code : '',
        fromDate: Utils.createIsoDate(fromDate),
        toDate: Utils.createIsoDate(toDate)
      },
      'load-dashboard',
      (response) => {
        this.dashboard = response;

        const loginNbRequestData = [];
        const loginNbCifData = [];
        const paidNbTransactionData = [];
        const paidTotalAmountData = [];

        for (const dailyData of this.dashboard.dailyDatas) {
          const date = Utils.parseIsoDate(dailyData.date);
          let dateDisplay = '';
          if (date != null) {
            dateDisplay = moment(date).format('DD/MM/YYYY');
          }

          this.loginRequestChartLabels.push(dateDisplay);
          this.paidRequestChartLabels.push(dateDisplay);

          loginNbRequestData.push(dailyData.nbLoginRequest);
          loginNbCifData.push(dailyData.nbLoginCustomer);
          paidNbTransactionData.push(dailyData.nbPaymentTransaction);
          paidTotalAmountData.push(dailyData.totalAmount);
        }
        this.loginRequestChartData[0].data = loginNbRequestData;
        this.loginRequestChartData[1].data = loginNbCifData
        this.paidRequestChartData[0].data = paidNbTransactionData;
        this.paidRequestChartData[1].data = paidTotalAmountData;
      }
    );
  }

  protected createForm() {
    this.form = FaskFormBuilder.buildForm(
      _.assignIn(
        {
          merchant: {
            value: this.params['merchant'] ? this.params['merchant'] : null,
            validator: FaskValidatorFactory.noRequired()
          }
        }
      )
    );
  }

  onSearchMerchant(event: { fieldKey: string; value: any }) {
    const merchant = this.form.fields['merchant'].value;
    if (merchant) {
      this.params['merchant'] = {
        id: merchant.id,
        displayValue: merchant.displayValue,
        name: merchant.name,
        code: merchant.code
      };
    } else {
      this.params['merchant'] = null;
    }
    this.reloadState();
  }

  loadMerchants = (text: string): Observable<any> => {
    const url = this.getApiUrl('/api/admin/merchant/search');
    return this.httpClient
      .post(url, {
        search: text
      })
      .pipe(
        map((result: any) => {
          _.forEach(result.list, (record) => {
            record.displayValue = record.name + ' (' + record.code + ')';
          });
          return result.list;
        })
      );
  };

}
