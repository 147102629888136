<div class="modal-header">
  <h4 class="modal-title">
    {{ title }}
  </h4>
</div>

<div class="modal-body">
  <span>{{ message }}</span>

  <div *ngFor="let additionalMessage of additionalMessageList">
    <br>
    <span>{{ additionalMessage }}</span>
  </div>

  <div *ngIf="useTextArea">
    <br>
    <app-form-group-textarea class="no-margin-bottom"
                             [form]="form"
                             [fieldKey]="'text'"
                             [label]="textAreaLabel"
                             [showError]="showFieldError"></app-form-group-textarea>
  </div>
</div>

<div class="modal-footer">
  <button class="btn"
          [ngClass]="{'btn-dark': !reverseBtnColor, 'btn-danger': reverseBtnColor}"
          (click)="accept()"
          *ngIf="showAcceptBtn">
    {{ acceptBtnName }}
  </button>
  <button class="btn btn-danger"
          [ngClass]="{'btn-danger': !reverseBtnColor, 'btn-dark': reverseBtnColor}"
          (click)="cancel()"
          *ngIf="showCancelBtn">
    {{ cancelBtnName }}
  </button>
</div>
