import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import { SafeResourceUrl} from '@angular/platform-browser';

import {IMAGE_VIEWER_EVENT, ImageViewerEventService} from './image-viewer-event.service';
import {NgxGalleryPreviewComponent} from "./ngx-gallery-preview.component";

@Component({
  template: `
    <ngx-gallery-preview
      [images]="images"
      [descriptions]="descriptions"
      [class.ngx-gallery-active]="previewEnabled"
      [showDescription]="true"
      [arrows]="true"
      [arrowsAutoHide]="false"
      [arrowPrevIcon]="'fal fa-arrow-left'"
      [arrowNextIcon]="'fal fa-arrow-right'"
      [closeIcon]="'fal fa-times'"
      [fullscreenIcon]="'fal fa-expand-arrows-alt'"
      [spinnerIcon]="'fal fa-spinner fa-pulse'"
      [swipe]="true"
      [fullscreen]="false"
      [forceFullscreen]="false"
      [closeOnClick]="true"
      [closeOnEsc]="true"
      [keyboardNavigation]="true"
      [animation]="true"
      [autoPlay]="false"
      [infinityMove]="true"
      [zoom]="true"
      [zoomStep]="0.5"
      [zoomMax]="4"
      [zoomMin]="0.5"
      [zoomInIcon]="'fal fa-search-plus'"
      [zoomOutIcon]="'fal fa-search-minus'"
      [rotateLeftIcon]="'fal fa-undo'"
      [rotateRightIcon]="'fal fa-redo'"
      [rotate]="true"
      (onClose)="onPreviewClose()">
    </ngx-gallery-preview>`,
  selector: '[app-image-viewer]',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnDestroy, AfterViewInit {

  @Input() images: string[] | SafeResourceUrl[];
  @Input() descriptions: string[];
  @Input() currentImageIndex: number;
  @Input() cmpRef: any;

  @ViewChild(NgxGalleryPreviewComponent) preview: NgxGalleryPreviewComponent;

  previewEnabled = true;

  private _event: any;

  constructor(private imageViewerEventService: ImageViewerEventService) {
    this.images = this.images || [];
    this.descriptions = this.descriptions || [];
    this.currentImageIndex = this.currentImageIndex || 0;

    this._event = {};
    this._event.subscription = this.imageViewerEventService.event$.subscribe((event: any) => this._onReceivedEvent(event));
  }

  public ngOnDestroy(): void {
    this._event.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.preview.open(this.currentImageIndex);
    });
  }

  public onPreviewClose() {
    this.imageViewerEventService.broadcastEvent({ id: IMAGE_VIEWER_EVENT.CLOSE, data: null });
  }

  private _end(): void {
    this.previewEnabled = false;
    setTimeout(() => {
      this.cmpRef.destroy();
    }, 1000);
  }

  private _onReceivedEvent(event: any): void {
    switch (event.id) {
      case IMAGE_VIEWER_EVENT.CLOSE:
        this._end();
        break;
      default:
        break;
    }
  }
}
