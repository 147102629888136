import { Component, Injector, OnInit } from '@angular/core';
import { POListAbstractComponent } from 'src/app/core/components/abstract/po-list.abstract.component';
import { PaginationData } from 'src/app/core/components/utility/pagination/pagination-data';
import { FaskForm } from 'src/app/core/form/fask.form';

@Component({
  selector: 'app-auto-debit-list',
  templateUrl: './auto-debit-list.component.html',
  styleUrls: ['./auto-debit-list.component.scss']
})
export class AutoDebitListComponent extends POListAbstractComponent {

  vm = this;
  form: FaskForm;

  constructor(injector: Injector) {
    super(injector,
      'feature.auto-debit.title-list',
      '/api/admin/auto-debit/search',
      'auto-debit-detail'
    );
  }

  isCanCreate(): boolean {
    return false;
  }

  isCanBack() {
    return true;
  }

  back() {
    this.goTo('auto-debit-search', null, this.params, true);
  }

  editRecord(record) {
    this.utilityService.setLocalStorageItem('auto-debit-list', this.records);
    this.goToDetailState(record.id);
  }

  protected componentInit() {
    this.parseReturnedParams();
    super.componentInit();
  }

  protected addMoreBodyParams(body: any): any {
    body = super.addMoreBodyParams(body);

    body['merchantCode'] = this.params['merchantCode'];
    body['autoDebitId'] = this.params['autoDebitId'];
    body['cif'] = this.params['cif'];
    body['contractCode'] = this.params['contractCode'];
    body['fromDate'] = this.params['fromDate'];
    body['toDate'] = this.params['toDate'];

    if (this.params['status'] != null && this.params['status'] !== this.optionAllId) {
      body['status'] = this.params['status'];
    }

    return body;
  }

  private parseReturnedParams(): void {
    if (this.params['returnParams'] != null) {
      const returnedParams = this.params['returnParams'];
      delete this.params.returnParams;

      this.params = {
        ...this.params,
        ...returnedParams
      };

      // PAGINATION DATA
      this.paginationData = new PaginationData();
      if (this.params['pageSize'] != null && this.params['pageSize'] > 0) {
        this.paginationData.pageSize = this.params['pageSize'];
      }
      if (this.params['pageNumber'] != null && this.params['pageNumber'] > 0) {
        this.paginationData.pageNumber = this.params['pageNumber'];
      }
    }
  }

  private goToDetailState(id: any) {
    const currentParams = this.params;
    delete currentParams.returnParams;

    const params = {
      returnState: 'auto-debit-list',
      returnParams: currentParams,
      localStorage: 'auto-debit-list'
    };

    this.goTo('auto-debit-detail', id, params, true);
  }

}
