import {EventEmitter, Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {Observable} from 'rxjs';
import {UtilityService} from './utility.service';

@Injectable()
export class UnsavedDataGuardService implements CanDeactivate<any> {

  constructor(private utilityService: UtilityService) {

  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.utilityService.isHasUnsavedData()) {
      const returnEmitter = new EventEmitter<boolean>();

      this.utilityService.showConfirmModal('base.unsaved.data.warning.message',
        () => {
          this.utilityService.clearHasUnsavedData();
          returnEmitter.emit(true);
        },
        () => {
          returnEmitter.emit(false);
        });

      return returnEmitter;
    }

    return true;
  }

}
