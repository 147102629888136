<div class="form-group" [ngClass]="{ 'has-danger': isAddDangerClass() }" *ngIf="getField() != null">

  <label class="form-control-label">
    <span>{{ label | translate }}</span>
    <span *ngIf="getValidator().required">*</span>
  </label>

  <select class="custom-select form-control"
          [(ngModel)]="getField().value"
          (ngModelChange)="onValueChanged($event)"
          [disabled]="fieldDisabled || fieldReadonly"
          [hidden]="fieldHiden"
          *ngIf="(!isReadonly() || defaultDisplayValue == null) && useFirstSelect">

    <option *ngFor="let item of itemList" [value]="getItemKey(item)">
      {{ getItemDisplay(item) | translate }}
    </option>

  </select>

  <select class="custom-select form-control"
          [(ngModel)]="getField().value"
          (ngModelChange)="onValueChanged($event)"
          [disabled]="fieldDisabled || fieldReadonly"
          [hidden]="fieldHiden"
          *ngIf="(!isReadonly() || defaultDisplayValue == null) && !useFirstSelect">

    <option *ngFor="let item of itemList" [value]="getItemKey(item)">
      {{ getItemDisplay(item) | translate }}
    </option>

  </select>

  <div class="form-control" readonly *ngIf="isReadonly() && defaultDisplayValue != null">
    {{ defaultDisplayValue }}
  </div>

  <div class="form-control-feedback" *ngIf="isShowTextError()">

    <span *ngIf="useDefaultErrorMessage()">
      <span>{{ 'form.error.message.required' | translate:getValidator().params }}</span>
    </span>

    <span *ngIf="!useDefaultErrorMessage()">
      {{ errorMessage | translate:getValidator().params }}
    </span>

  </div>

</div>
