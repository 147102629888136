<div class="input-group search-field" [ngClass]="{ 'show-clear-btn': isShowClearBtn() }">
  <input class="form-control date-range-picker" bsDaterangepicker (keyup)="onEnter($event)" [isOpen]="isOpen"
    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }" (ngModelChange)="onValueChange($event)"
    [(ngModel)]="mySearchDate">
  <div class="input-group-append">
    <button class="btn btn-info" type="button" *ngIf="!isShowClearBtn()" (click)="onSearchBtnClick()">
      <i class="far fa-calendar-alt"></i>
    </button>
    <button class="btn btn-danger" type="button" *ngIf="isShowClearBtn()" (click)="onClearBtnClick()">
      <i class="fas fa-times"></i>
    </button>
  </div>
</div>
