import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RoleGuardService} from './core/utils/service/role.guard.service';
import {UnsavedDataGuardService} from './core/utils/service/unsaved-data.guard.service';

import {HomeComponent} from './core/components/route/home/home.component';
import {NotFoundRouteComponent} from './core/components/route/not-found-route/not-found-route.component';
import {UnknownErrorRouteComponent} from './core/components/route/unknown-error-route/unknown-error-route.component';
import {LoginComponent} from './core/components/route/before-logged-in/login/login.component';
import {ChangePasswordComponent} from './core/components/route/change-password/change-password.component';
import {SubAdminListComponent} from './business/components/route/sub-admin/sub-admin-list/sub-admin-list.component';
import {SubAdminDetailComponent} from './business/components/route/sub-admin/sub-admin-detail/sub-admin-detail.component';
import {BusinessConfigComponent} from './business/components/route/business-config/business-config.component';
import {MerchantListComponent} from './business/components/route/merchant/merchant-list/merchant-list.component';
import {MerchantDetailComponent} from './business/components/route/merchant/merchant-detail/merchant-detail.component';
import {PaymentTransactionTypeListComponent} from './business/components/route/payment-transaction-type/payment-transaction-type-list/payment-transaction-type-list.component';
import {PaymentTransactionTypeDetailComponent} from './business/components/route/payment-transaction-type/payment-transaction-type-detail/payment-transaction-type-detail.component';
import {PaymentTransactionListComponent} from './business/components/route/payment-transaction/payment-transaction-list/payment-transaction-list.component';
import {PaymentTransactionDetailComponent} from './business/components/route/payment-transaction/payment-transaction-detail/payment-transaction-detail.component';
import {PaymentTransactionLogExportComponent} from './business/components/route/payment-transaction-log/payment-transaction-log-export/payment-transaction-log-export.component';
import {PaymentTransactionSearchComponent} from './business/components/route/payment-transaction/payment-transaction-search/payment-transaction-search.component';
import {ReconciliationReportExportComponent} from './business/components/route/payment-transaction-log/reconciliation-report-export/reconciliation-report-export.component';
import {DashboardComponent} from './business/components/route/dashboard/dashboard.component';
import {SandboxApkListComponent} from './business/components/route/sandbox-apk/sandbox-apk-list/sandbox-apk-list.component';
import {SandboxApkDetailComponent} from './business/components/route/sandbox-apk/sandbox-apk-detail/sandbox-apk-detail.component';
import {SandboxApkCreateLinkComponent} from './business/components/route/sandbox-apk/sandbox-apk-create-link/sandbox-apk-create-link.component';
import { AutoDebitSearchComponent } from './business/components/route/auto-debit/auto-debit-search/auto-debit-search.component';
import { AutoDebitListComponent } from './business/components/route/auto-debit/auto-debit-list/auto-debit-list.component';
import { AutoDebitDetailComponent } from './business/components/route/auto-debit/auto-debit-detail/auto-debit-detail.component';
import { AutoDebitLogExportComponent } from './business/components/route/auto-debit-log/auto-debit-log-export/auto-debit-log-export.component';
import {IncomingRequestExportComponent} from './business/components/route/incoming-request-export/incoming-request-export.component';
import {MerchantExportComponent} from './business/components/route/merchant-export/merchant-export.component';

const routes: Routes = [
  // ********************************
  // BUSINESS************************
  // ********************************
  // DASHBOARD
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['DASHBOARD']
    }
  },
  // SUB ADMIN
  {
    path: 'sub-admin-list',
    component: SubAdminListComponent,
    canActivate: [RoleGuardService],
    data: {roles: ['ADMIN']}
  },

  // SUB ADMIN
  {
    path: 'merchant-export',
    component: MerchantExportComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN']
    }
  },
  {
    path: 'sub-admin-detail',
    component: SubAdminDetailComponent,
    canActivate: [RoleGuardService],
    canDeactivate: [UnsavedDataGuardService],
    data: {roles: ['ADMIN']}
  },

  // MERCHANT MANAGEMENT
  {
    path: 'merchant-list',
    component: MerchantListComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['MERCHANT_MANAGEMENT']
    }
  },
  {
    path: 'merchant-detail',
    component: MerchantDetailComponent,
    canActivate: [RoleGuardService],
    canDeactivate: [UnsavedDataGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['MERCHANT_MANAGEMENT']
    }
  },

  // PAYMENT_TRANSACTION_TYPE MANAGEMENT
  {
    path: 'payment-transaction-type-list',
    component: PaymentTransactionTypeListComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['MERCHANT_MANAGEMENT']
    }
  },
  {
    path: 'payment-transaction-type-detail',
    component: PaymentTransactionTypeDetailComponent,
    canActivate: [RoleGuardService],
    canDeactivate: [UnsavedDataGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['MERCHANT_MANAGEMENT']
    }
  },

  // PAYMENT_TRANSACTION MANAGEMENT
  {
    path: 'payment-transaction-search',
    component: PaymentTransactionSearchComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['PAYMENT_TRANSACTION_MANAGEMENT']
    }
  },
  {
    path: 'payment-transaction-list',
    component: PaymentTransactionListComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['PAYMENT_TRANSACTION_MANAGEMENT']
    }
  },
  {
    path: 'payment-transaction-detail',
    component: PaymentTransactionDetailComponent,
    canActivate: [RoleGuardService],
    canDeactivate: [UnsavedDataGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['PAYMENT_TRANSACTION_MANAGEMENT']
    }
  },

  // AUTO_DEBIT MANAGEMENT
  {
    path: 'auto-debit-search',
    component: AutoDebitSearchComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['AUTO_DEBIT_MANAGEMENT']
    }
  },
  {
    path: 'auto-debit-list',
    component: AutoDebitListComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['AUTO_DEBIT_MANAGEMENT']
    }
  },
  {
    path: 'auto-debit-detail',
    component: AutoDebitDetailComponent,
    canActivate: [RoleGuardService],
    canDeactivate: [UnsavedDataGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['AUTO_DEBIT_MANAGEMENT']
    }
  },

  // EXPORT PAYMENT TRANSACTION LOG
  {
    path: 'payment-transaction-log-export',
    component: PaymentTransactionLogExportComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['PAYMENT_TRANSACTION_LOG_EXPORT']
    }
  },

  // EXPORT RECONCILIATION REPORT
  {
    path: 'reconciliation-report-export',
    component: ReconciliationReportExportComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['RECONCILIATION_REPORT_EXPORT']
    }
  },

  // EXPORT INCOMING_REQUEST_EXPORT
  {
    path: 'incoming-request-export',
    component: IncomingRequestExportComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['INCOMING_REQUEST_EXPORT']
    }
  },

  // BUSINESS CONFIG
  {
    path: 'business-config',
    component: BusinessConfigComponent,
    canActivate: [RoleGuardService],
    canDeactivate: [UnsavedDataGuardService],
    data: {
      roles: ['ADMIN']
    }
  },

  // SANDBOX APK MANAGEMENT
  {
    path: 'sandbox-apk-list',
    component: SandboxApkListComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN']
    }
  },
  {
    path: 'sandbox-apk-detail',
    component: SandboxApkDetailComponent,
    canActivate: [RoleGuardService],
    canDeactivate: [UnsavedDataGuardService],
    data: {
      roles: ['ADMIN']
    }
  },
  {
    path: 'sandbox-apk-create-link',
    component: SandboxApkCreateLinkComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN']
    }
  },

  // EXPORT AUTO DEBIT LOG
  {
    path: 'auto-debit-log-export',
    component: AutoDebitLogExportComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: ['ADMIN', 'SUB_ADMIN'],
      features: ['AUTO_DEBIT_LOG_EXPORT']
    }
  },

  // ********************************
  // CORE****************************
  // ********************************
  {path: '', component: HomeComponent, canActivate: [RoleGuardService]},
  {path: 'change-password', component: ChangePasswordComponent, canActivate: [RoleGuardService]},
  {path: '404', component: NotFoundRouteComponent},
  {path: '500', component: UnknownErrorRouteComponent},
  {path: 'login', component: LoginComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
