<app-po-list [useStatusFilter]="false" [useTextFilter]="false" [vm]="vm">

  <div class="row" table-filter>
    <div class="col-12 col-md-4 table-filter-item">
      <label>{{ 'feature.payment-transaction-type.merchant' | translate }}</label>
      <app-form-group-select
        (valueChange)="onSearchMerchant($event)"
        [fieldKey]="'merchant'"
        [form]="vm.form"
        [itemDisplayField]="'displayValue'"
        [loadData]="loadMerchants"
        [noLabel]="true"
        [placeholder]="'base.option.all' | translate"
        [useTypeahead]="true"
      >
      </app-form-group-select>
    </div>

    <div class="col-12 col-md-4 table-filter-item">
      <label>{{ 'base.status' | translate }}</label>
      <select (ngModelChange)="vm.onSearch()"
              [(ngModel)]="vm.params['activeStatus']"
              class="custom-select"
              name="activeStatus">
        <option *ngFor="let activeStatusListItem of vm.activeStatusList"
                [value]="activeStatusListItem.id">
          {{ activeStatusListItem.name | translate }}
        </option>
      </select>
    </div>

    <div class="col-12 col-md-4 table-filter-item">
      <label class="d-none d-sm-block">&nbsp;</label>
      <app-search-field (searchPerform)="vm.onSearchText($event)"
                        [searchText]="vm.params['searchText']"></app-search-field>
    </div>
  </div>

  <table class="table" table-content>
    <thead>
    <tr>
      <th>{{ 'feature.payment-transaction-type.name' | translate }}</th>
      <th>{{ 'feature.payment-transaction-type.code' | translate }}</th>
      <th>{{ 'feature.payment-transaction-type.merchant-name' | translate }}</th>
      <th class="w-active-status text-center">{{ 'base.status' | translate }}</th>
      <th class="w-1x"></th>
    </tr>
    </thead>
    <tbody>
    <tr (click)="vm.editRecord(record)" *ngFor="let record of vm.records">
      <td style="overflow: hidden;text-overflow: ellipsis;max-width:0;">
        {{ record.name }}
      </td>
      <td>{{ record.code }}</td>
      <td>{{ record.merchant.name }}</td>
      <td class="text-center">
        <app-active-status-label [po]="record"></app-active-status-label>
      </td>
      <td class="arrow-forward">
        <i class="icon ion-ios-arrow-forward"></i>
      </td>
    </tr>
    </tbody>
  </table>

</app-po-list>

