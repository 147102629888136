<app-po-list [vm]="vm" [vmIsPoRole]="isPoRole">

  <table class="table" table-content>
    <thead>
    <tr>
      <th>{{ 'feature.merchant.name' | translate }}</th>
      <th class="w-sm">{{ 'feature.merchant.code' | translate }}</th>
      <th class="w-active-status text-center">{{ 'base.status' | translate }}</th>
      <th class="w-1x"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let record of vm.records" (click)="vm.editRecord(record)">
      <td style="overflow: hidden;text-overflow: ellipsis;max-width:0;">
        {{ record.name }}
      </td>
      <td>{{ record.code }}</td>
      <td class="text-center">
        <app-active-status-label [po]="record"></app-active-status-label>
      </td>
      <td class="arrow-forward">
        <i class="icon ion-ios-arrow-forward"></i>
      </td>
    </tr>
    </tbody>
  </table>

</app-po-list>

