import {ElementRef, Injector, ViewChild} from '@angular/core';
import {AbstractComponent} from './abstract.component';
import Utils from '../../utils/utils';
import {FileModel} from '../utility/input-file/input-file.component';

export abstract class ImportViewAbstractComponent extends AbstractComponent {

  steps: string[];
  excelFile = null;

  protected constructor(injector: Injector,
                        public title: string,
                        private poBackendPath: string,
                        steps?: string[]) {
    super(injector, title);

    if (steps != null) {
      this.steps = steps;
    } else {
      this.steps = [
        'base.import.step.upload.file',
        'base.import.step.confirm',
        'base.import.step.finish'
      ];
    }
  }

  protected componentInit() {
    this.excelFile = null;

    if (this.params['currentStep'] == null) {
      this.params['currentStep'] = 0;
    }

    if (this.params['enable'] == null) {
      this.params['enable'] = true;
    }

    if (this.params['importVerifyDto'] == null) {
      this.params['importVerifyDto'] = {};
    }
  }

  getFinishMessage() {
    return 'base.import.finish.message';
  }

  getTemplateLink() {
    return this.getApiUrl(this.poBackendPath + '/import/template?lang=' + this.utilityService.getCurrentLanguage());
  }

  isUseEnableButton() {
    return false;
  }

  isStep(stepIndex) {
    return stepIndex === this.params['currentStep'];
  }

  isDoneStep(stepIndex) {
    return stepIndex < this.params['currentStep'];
  }

  isFirstStep() {
    return this.params['currentStep'] <= 0;
  }

  isLastStep() {
    return this.params['currentStep'] >= this.steps.length - 1;
  }

  isCanBackStep() {
    return !this.isLastStep();
  }

  backStep() {
    if (this.isStep(0)) {
      this.back();
    } else {
      this.params['currentStep']--;
      this.reloadState();
    }
  }

  isCanNextStep() {
    if (this.isStep(1)) {
      if (this.params['importVerifyDto']['error']) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  nextStep() {
    if (this.isStep(1)) {
      const confirmMsg = this.params['enable'] ? 'base.import.confirm.msg.import.and.enable' : 'base.import.confirm.msg.import';
      this.utilityService.showConfirmModal(confirmMsg, () => {
        this.doImport();
      });
    }
  }

  // Excel Selected
  onExcelSelected(fileModel: FileModel) {
    this.excelFile = fileModel.file;
    this.verify();
  }

  // VERIFY
  protected getVerifyUrl() {
    return this.getApiUrl(this.poBackendPath + '/import/verify?lang=' + this.utilityService.getCurrentLanguage());
  }

  protected addMoreVerifyRequestParams(requestParams) {
    return requestParams;
  }

  protected addMoreVerifyRequestBody(requestBody) {
    return requestBody;
  }

  verify() {
    this.params['importVerifyDto'] = {};

    let requestParams = {};
    requestParams = this.addMoreVerifyRequestParams(requestParams);

    let requestBody = {
      file: Utils.dataURItoBlob(this.excelFile)
    };
    requestBody = this.addMoreVerifyRequestBody(requestBody);

    this.httpPostMultipartForm(this.getVerifyUrl(),
      requestParams,
      'verify',
      requestBody,
      (importVerifyDto) => {
        this.params['importVerifyDto'] = importVerifyDto;
        this.params['currentStep']++;
        this.reloadState();
      },
      null,
      true,
      'base.import.verify.successfully',
      'base.import.verify.unsuccessfully'
    );
  }

  // DO IMPORT
  protected getImportUrl() {
    return this.getApiUrl(this.poBackendPath + '/import/do-import');
  }

  protected addMoreImportRequestParams(requestParams) {
    return requestParams;
  }

  protected addMoreImportRequestBody(requestBody) {
    return requestBody;
  }

  doImport() {
    let requestParams = {
      importFilePath: this.params['importVerifyDto']['importFilePath'],
      enable: this.params['enable']
    };
    requestParams = this.addMoreImportRequestParams(requestParams);

    let requestBody = {};
    requestBody = this.addMoreImportRequestBody(requestBody);

    this.httpPostOrPut(this.getImportUrl(),
      requestParams,
      true,
      'do-import',
      requestBody,
      () => {
        this.params['currentStep']++;
        this.reloadState();
      },
      null,
      true,
      'base.import.import.successfully',
      'base.import.import.unsuccessfully'
    );
  }

}
