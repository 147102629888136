<app-po-detail [useSimplePOTemplateFieldName]="false" [useSimplePOTemplate]="true" [vm]="vm">
  <div *ngIf="form != null" class="row" simple-po-detail-field-container>

    <div class="col-12">
      <app-form-group-select (valueChange)="onFieldChanged($event)"
                             [fieldKey]="'merchant'"
                             [fieldReadonly]="!isDraft()"
                             [form]="vm.form"
                             [itemDisplayField]="'displayValue'"
                             [label]="'feature.payment-transaction-type.merchant'"
                             [loadData]="loadMerchants"
                             [useTypeahead]="false"></app-form-group-select>
    </div>

    <div class="col-12">
      <app-form-group-text (valueChange)="vm.onFieldChanged($event)"
                           [fieldKey]="'name'"
                           [fieldReadonly]="vm.isReadonly()"
                           [form]="form"
                           [label]="'base.name'"
                           [showError]="vm.showFieldError"></app-form-group-text>
    </div>

    <div class="col-12">
      <app-form-group-text (valueChange)="onFieldChanged($event)"
                           [fieldKey]="'code'"
                           [fieldReadonly]="!isDraft()"
                           [form]="form"
                           [label]="'feature.payment-transaction-type.code'"
                           [showError]="showFieldError"></app-form-group-text>
    </div>

    <div class="col-12">
      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                               [fieldKey]="'allowCard'"
                               [fieldReadonly]="vm.isReadonly()"
                               [form]="form"
                               [label]="'feature.payment-transaction-type.allow-card'"
                               [showError]="showFieldError"></app-form-group-checkbox>
    </div>
    <div class="col-12">
      <app-form-group-checkbox (valueChange)="onFieldChanged($event)"
                               [fieldKey]="'allowCard'"
                               [fieldReadonly]="vm.isReadonly()"
                               [form]="form"
                               [label]="'feature.payment-transaction-type.allow-bnpl'"
                               [showError]="showFieldError"></app-form-group-checkbox>
    </div>

  </div>
</app-po-detail>
