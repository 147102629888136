import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import * as _ from 'lodash';
import {UtilityService} from './utility.service';
import Utils from '../utils';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private router: Router, private utilityService: UtilityService, private httpClient: HttpClient) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.createRequestWithAuthorization(request);

    return next.handle(request).pipe(
      catchError(err => {
        // CHECK IF ERROR CODE IS NOT UNAUTHORIZED THEN THROW ERROR
        if (err == null || err.status !== 401) {
          return throwError(err);
        }

        if (_.endsWith(request.url, '/authentication/login')) {
          return throwError(err);
        }

        // IF WE DONT HAVE REFRESH TOKEN THEN DONT REFRESH
        const tokenInfo = this.utilityService.getTokenInfo();
        if (tokenInfo == null || Utils.isEmptyString(tokenInfo.refresh_token)) {
          this.processWhenRefreshTokenFail(err);
        }

        // REFRESH TOKEN IS IN PROGRESS
        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap((value: any) => {
              if (value != null && value !== false) {
                return this.processWhenRefreshTokenSuccess(request, next, value);
              }

              return throwError(false);
            })
          );
        }

        // TRY TO REFRESH TOKEN
        this.refreshTokenInProgress = true;
        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
        this.refreshTokenSubject.next(null);

        // Call auth.refreshAccessToken(this is an Observable that will be returned)
        return this.httpClient.post(
          this.utilityService.getApiUrl('/authentication/refresh-token'),
          {refreshToken: tokenInfo.refresh_token},
          {headers: {'Content-Type': 'application/json;charset=UTF-8'}}
        ).pipe(
          switchMap((_tokenInfo) => {
            // When the call to refreshToken completes we reset the refreshTokenInProgress to false
            // for the next time the token needs to be refreshed
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(_tokenInfo);

            return this.processWhenRefreshTokenSuccess(request, next, _tokenInfo);
          }),
          catchError(() => {
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(false);

            return this.processWhenRefreshTokenFail(err);
          })
        );
      })
    );
  }

  // PRIVATE
  private createRequestWithAuthorization(request: HttpRequest<any>): HttpRequest<any> {
    if (_.endsWith(request.url, '/authentication/refresh-token')
      || _.endsWith(request.url, '/authentication/login')
    ) {
      return request;
    }

    const tokenInfo = this.utilityService.getTokenInfo();

    if (tokenInfo != null && !Utils.isEmptyString(tokenInfo.access_token)) {
      return request.clone({
          setHeaders: {
            'Authorization': 'Bearer ' + tokenInfo.access_token
          }
        }
      );
    }

    return request;
  }

  private logoutAndGoToLoginPage(err: any): Observable<any> {
    this.utilityService.clearSessionData();
    this.utilityService.clearTokenInfo();

    const url = this.router.url;
    if (url == null || _.isEmpty(url) || url === '/') {
      this.router.navigate(['/login']);
    } else {
      const queryParams = {
        'ru': url
      };

      this.router.navigate(['/login'], {queryParams: queryParams});
    }

    return of(err);
  }

  private processWhenRefreshTokenFail(err: any): Observable<any> {
    // IF STATE LOGIN OR SIGNUP WE JUST RE-THROW ERROR
    if (this.utilityService.getCurrentRoute() === 'login' || this.utilityService.getCurrentRoute() === 'signup') {
      return throwError(err);
    }

    // IF USERINFO REQUEST WE JUST RE-THROW ERROR
    if (_.endsWith(err.url, '/authentication/userinfo')) {
      return throwError(err);
    }

    return this.logoutAndGoToLoginPage(err);
  }

  private processWhenRefreshTokenSuccess(request: HttpRequest<any>, next: HttpHandler, tokenInfo?: any): Observable<any> {
    // SAVE TOKEN INFO
    if (tokenInfo != null) {
      this.utilityService.setTokenInfo(tokenInfo);
    }

    // RETRY REQUEST
    return next.handle(this.createRequestWithAuthorization(request));
  }

}
