<div class="form-group" [ngClass]="{ 'has-danger': isAddDangerClass() }" *ngIf="getField() != null">

  <label class="form-control-label">
    {{ label | translate }}<span *ngIf="getValidator().required">*</span>
  </label>

  <div>
    <ui-switch [(ngModel)]="getField().value"
               (ngModelChange)="onValueChanged($event)"
               [disabled]="fieldDisabled || fieldReadonly"
               [color]="color"></ui-switch>
  </div>

  <div class="form-control-feedback" *ngIf="isShowTextError()">

    <span *ngIf="useDefaultErrorMessage()">
      {{ 'form.error.message.' + getValidator().getKey() | translate:getValidator().params }}
    </span>

    <span *ngIf="!useDefaultErrorMessage()">
      {{ errorMessage | translate:getValidator().params }}
    </span>

  </div>

</div>
