import {Pipe, PipeTransform} from '@angular/core';
import * as numeral from 'numeral';

@Pipe({name: 'myInteger'})
export class MyIntegerPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }

    return  numeral(value).format('0,0');
  }
}
