export class LoadingStatus {

  private error = false;
  private loading = {};

  public reset() {
    this.error = false;
    this.loading = {};
  }

  public isError(): boolean {
    return this.error;
  }

  public setError(error: boolean) {
    this.error = error;
  }

  public isLoading(): boolean {
    for (const key of Object.keys(this.loading)) {
      const loadingStatus = this.loading[key];
      if (loadingStatus === true) {
        return true;
      }
    }

    return false;
  }

  public setLoading(key: string, loadingStatus: boolean) {
    if (key != null) {
      this.loading[key] = loadingStatus;
    }
  }

}
