import {Component, Inject, Input, OnInit} from '@angular/core';
import {POListAbstractComponent} from '../../abstract/po-list.abstract.component';

@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html'
})
export class POListComponent implements OnInit {

  @Input() vm: POListAbstractComponent;
  @Input() useStatusFilter = true;
  @Input() useTextFilter = true;
  @Input() useSimplePOTemplate = false;
  @Input() noFilter = false;
  @Input() rowClickable = true;
  @Input() vmIsPoRole: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
