import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-form-group-combobox-group',
  templateUrl: './form-group-combobox-group.component.html'
})
export class FormGroupComboboxGroupComponent extends FormGroupAbstractComponent implements OnInit, OnDestroy {

  @Input() groupList: { groupName: string, itemList: any[] }[];
  @Input() itemKeyField = 'id';
  @Input() itemDisplayField = 'name';
  @Input() defaultDisplayValue = null;
  @Input() needTranslate = false;
  @Input() groupListChangeSubject: BehaviorSubject<{ groupName: string, itemList: any[] }[]>;
  private groupListChangeSubscription: any;

  useFirstSelect = false;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    if (this.groupListChangeSubject != null) {
      this.groupListChangeSubscription = this.groupListChangeSubject.subscribe(
        (newList: { groupName: string, itemList: any[] }[]) => {
          this.groupList = newList;
          this.reset();
        });
    }
  }

  ngOnDestroy() {
    if (this.groupListChangeSubscription != null) {
      this.groupListChangeSubscription.unsubscribe();
    }
  }

  onValueChanged(value): void {
    this.valueChange.emit({fieldKey: this.fieldKey, value: value});
  }

  getItemKey(item) {
    return item[this.itemKeyField];
  }

  getItemDisplay(item) {
    if (this.needTranslate) {
      return this.translate(item[this.itemDisplayField]);
    }

    return item[this.itemDisplayField];
  }

  reset() {
    this.useFirstSelect = !this.useFirstSelect;
  }

}
