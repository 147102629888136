import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {

  @Input() set searchText(value: string) {
    this.searchTextOriginal = value;
    this.mySearchText = value;
  }

  get searchText(): string {
    return this.mySearchText;
  }

  @Output() searchPerform = new EventEmitter<string>();

  searchTextOriginal: string;
  mySearchText: string;

  constructor() {
  }

  ngOnInit() {
  }

  isShowClearBtn() {
    return (this.mySearchText != null && this.mySearchText.length > 0) &&
      (this.searchTextOriginal != null && this.searchTextOriginal.length > 0) &&
      this.searchTextOriginal === this.mySearchText;
  }

  onSearchBtnClick() {
    this.searchPerform.emit(this.mySearchText);
  }

  onClearBtnClick() {
    this.mySearchText = '';
    this.searchPerform.emit(null);
  }

  onEnter(event) {
    if (event['key'] === 'Enter') {
      if (!this.isShowClearBtn()) {
        this.onSearchBtnClick();
      }
    }
  }

}
