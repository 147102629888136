import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import {FaskField, FaskForm, FaskValidator} from './fask.form';
import {AbstractSimpleComponent} from '../components/abstract/abstract.simple.component';

@Directive()
export abstract class FormGroupAbstractComponent extends AbstractSimpleComponent {

  @Input() form: FaskForm;
  @Input() fieldKey: string;
  @Input() noLabel = false;
  @Input() label: string;
  @Input() showError: boolean;
  @Input() hideTextError = false;
  @Input() autoAddDangerClass = false;
  @Input() fieldReadonly = false;
  @Input() fieldDisabled = false;
  @Input() fieldHiden = false;
  @Input() errorMessage = null;
  @Output() valueChange = new EventEmitter<{ fieldKey: string, value: any }>();

  protected constructor(protected injector: Injector) {
    super(injector);
  }

  getField(): FaskField {
    if (this.form == null) {
      return null;
    }

    return this.form.fields[this.fieldKey];
  }

  isInvalid() {
    const field = this.getField();
    if (field == null) {
      return true;
    }

    return field.isInvalid();
  }

  isAddDangerClass() {
    if (this.isInvalid()) {
      return this.autoAddDangerClass || this.showError;
    }

    return false;
  }

  isShowTextError() {
    if (this.isInvalid()) {
      return !this.hideTextError && this.showError;
    }

    return false;
  }

  getValidator(): FaskValidator {
    return this.getField().getValidator();
  }

  useDefaultErrorMessage(): boolean {
    return this.errorMessage == null;
  }

  isReadonly() {
    return this.fieldDisabled || this.fieldReadonly;
  }

  isHidenField(): boolean {
    return this.fieldHiden;
  }

}
