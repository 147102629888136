import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroupAbstractComponent} from '../form-group.abstract.component';
import Properties from '../../../business/properties';
import * as moment from 'moment';

@Component({
  selector: 'app-form-group-date',
  templateUrl: './form-group-date.component.html'
})
export class FormGroupDateComponent extends FormGroupAbstractComponent implements OnInit {

  @Input() minDate = null;
  @Input() maxDate = null;
  @Input() dateFormatPattern = Properties.DATE_FORMAT_PATTERN;
  @Input() placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onDateChanged(value): void {
    if (value != null && value.toString() === 'Invalid Date') {
      this.getField().value = null;
      this.valueChange.emit({fieldKey: this.fieldKey, value: null});
      return;
    }

    this.valueChange.emit({fieldKey: this.fieldKey, value: value});
  }

  getDateDisplay() {
    if (this.getField().value == null) {
      return '';
    }

    return moment(this.getField().value).format(this.dateFormatPattern);
  }

  hasMinMaxConstraint() {
    return this.getValidator().params != null && (this.getValidator().params.min != null || this.getValidator().params.max != null);
  }

  getMinMaxConstraintErrorMessage() {
    let errorMessage = '';

    if (this.getValidator().params.min != null) {
      let display: string = this.getValidator().params.min.format(this.dateFormatPattern);
      const isToday: boolean = moment().isSame(this.getValidator().params.min, 'day');
      if (isToday) {
        display = this.translate('base.today');
      }

      if (this.getValidator().params.excludeMin) {
        errorMessage = errorMessage + '> ' + display;
      } else {
        errorMessage = errorMessage + '>= ' + display;
      }
    }

    if (this.getValidator().params.max != null) {
      let display: string = this.getValidator().params.max.format(this.dateFormatPattern);
      const isToday: boolean = moment().isSame(this.getValidator().params.max, 'day');
      if (isToday) {
        display = this.translate('base.today');
      }

      if (this.getValidator().params.excludeMax) {
        errorMessage = errorMessage + '< ' + display;
      } else {
        errorMessage = errorMessage + '<= ' + display;
      }
    }

    return errorMessage;
  }

}
