import {Component, Injector} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractComponent} from '../../../../core/components/abstract/abstract.component';
import {FaskForm, FaskFormBuilder, FaskValidatorFactory} from '../../../../core/form/fask.form';
import Utils from '../../../../core/utils/utils';

@Component({
  selector: 'app-incoming-request-export',
  templateUrl: './incoming-request-export.component.html'
})
export class IncomingRequestExportComponent extends AbstractComponent {

  vm = this;
  form: FaskForm;
  showFieldError = false;

  hours: any[] = _.map([
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"
  ], (text) => {
    return {id: text, name: text}
  });
  minutes: any[] = _.map([
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
    "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39",
    "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"
  ], (text) => {
    return {id: text, name: text}
  });
  title = 'feature.incoming-request-export.title';

  constructor(injector: Injector) {
    super(injector);
  }

  loadMerchants = (text: string): Observable<any> => {
    const url = this.getApiUrl('/api/admin/merchant/search');
    return this.httpClient
      .post(url, {
        search: text
      })
      .pipe(
        map((result: any) => {
          _.forEach(result.list, (record) => {
            record.displayValue = record.name + ' (' + record.code + ')';
          });
          return result.list;
        })
      );
  };

  // noinspection JSUnusedLocalSymbols
  onFieldChanged(event: { fieldKey: string; value: any }) {
    // DO NOTHING
  }

  export() {
    if (!this.checkBeforeAction()) {
      return;
    }

    let toTime = '';
    if (this.form.fields['useCustomTime'].value) {
      toTime = Utils.createIsoDate(this.form.fields['toDate'].value)
      toTime = toTime + 'T' + this.form.fields['hour'].value + ':' + this.form.fields['minute'].value + ':00';
    }

    this.httpDownloadFile(
      this.getApiUrl('/api/admin/incoming-request-history/export'),
      {
        toTime: toTime,
        merchantCode: this.form.fields['merchant'].value != null ? this.form.fields['merchant'].value.code : null,
      },
      'export'
    );
  }

  protected componentInit() {
    this.showFieldError = false;
    this.createForm();
  }

  protected createForm() {
    const toDate = moment(new Date()).startOf('day').toDate();

    this.form = FaskFormBuilder.buildForm(
      {
        merchant: {value: null, validator: FaskValidatorFactory.noRequired()},
        useCustomTime: {value: false, validator: FaskValidatorFactory.required()},
        toDate: {
          value: toDate,
          validator: FaskValidatorFactory.required(false),
          requiredDependingKey: 'useCustomTime',
          noRequiredValue: false,
        },
        hour: {
          value: this.hours[0].id,
          validator: FaskValidatorFactory.required(false),
          requiredDependingKey: 'useCustomTime',
          noRequiredValue: false,
        },
        minute: {
          value: this.minutes[0].id,
          validator: FaskValidatorFactory.required(false),
          requiredDependingKey: 'useCustomTime',
          noRequiredValue: false,
        },
      }
    );
  }

  protected checkBeforeAction() {
    if (this.form != null && !this.form.isValid()) {
      this.showFieldError = true;
      return false;
    }

    return true;
  }
}
