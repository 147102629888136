import {Component, Injector} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AbstractSimpleComponent} from '../../abstract/abstract.simple.component';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'app-crop-image-modal',
  templateUrl: './crop-image-modal.component.html'
})
export class CropImageModalComponent extends AbstractSimpleComponent {

  imageBase64: string;
  aspectRatio: number;
  croppedImageBase64: string;
  onImageCropped: (imageBase64: string) => void;
  resizeToWidth: number = 0;
  resizeToHeight: number = 0;

  constructor(injector: Injector, private bsModalRef: BsModalRef) {
    super(injector);
  }

  protected componentInit() {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBase64 = event.base64;
  }

  save() {
    this.bsModalRef.hide();
    if (this.onImageCropped != null) {
      this.onImageCropped(this.croppedImageBase64);
    }
  }

  close() {
    this.bsModalRef.hide();
  }

}
